import { FormRow, FormRowSelect, AlternativeFormRowSelect } from ".";
import { useAppContext } from "../context/appContext";
import Wrapper from "../assets/wrappers/SearchContainer";
import FilteredProvider from "./provider/FilteredProvider";
import { useTranslation } from "react-i18next";

const SearchContainer = () => {
  const { t } = useTranslation();
  const {
    isLoading,
    search,
    handleChange,
    handleChangeProvider,
    searchProvider,
    providerOptions,
    itemCategoryOptions,
    searchCategory,
    itemTypeOptions,
    searchType,
    itemKitchenOptions,
    searchKitchen,
    itemLocationOptions,
    searchLocation,
    clearFilters,
    sort,
    sortOptions,
    providers,
    showAlternatives,
    isEditing,
    isAdding,
    includeAlternatives,
    changePage,
  } = useAppContext();

  const handleSearch = (e) => {
    if (isLoading) return;
    handleChange({ name: e.target.name, value: e.target.value });
    changePage(1);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    clearFilters();
  };

  const handleAlternativeChange = (e) => {
    if (isLoading) return;
    handleChange({ name: "includeAlternatives", value: !includeAlternatives });
  };

  const selectedProvider =
    searchProvider !== "all"
      ? providers.find((p) => p.providerName === searchProvider)
      : null;

  const translatedSortOptions = sortOptions.map((option) => ({
    value: option,
    label: t(option),
  }));
  return (
    <Wrapper>
      <form
        className="form"
        style={{ marginTop: "0.75rem", marginBottom: "0" }}
      >
        <div className="form-center">
          <div className="form-search">
            <FormRow
              type="text"
              name="search"
              value={search}
              handleChange={handleSearch}
              labelText={t("Search")}
            ></FormRow>
          </div>
          <FormRowSelect
            labelText={t("provider")}
            name="searchProvider"
            value={searchProvider}
            handleChange={handleSearch}
            list={["all", ...providerOptions]}
          ></FormRowSelect>
          <FormRowSelect
            labelText={t("category")}
            name="searchCategory"
            value={searchCategory}
            handleChange={handleSearch}
            list={["all", ...itemCategoryOptions]}
          ></FormRowSelect>
          <FormRowSelect
            labelText={t("location")}
            name="searchLocation"
            value={searchLocation}
            handleChange={handleSearch}
            list={["all", ...itemLocationOptions]}
          ></FormRowSelect>
          <FormRowSelect
            labelText={t("type")}
            name="searchType"
            value={searchType}
            handleChange={handleSearch}
            list={["all", ...itemTypeOptions]}
          ></FormRowSelect>
          <FormRowSelect
            labelText={t("Kitchen")}
            name="searchKitchen"
            value={searchKitchen}
            handleChange={handleSearch}
            list={["all", ...itemKitchenOptions]}
          ></FormRowSelect>
          <AlternativeFormRowSelect
            name="sort"
            value={sort}
            handleChange={handleSearch}
            list={translatedSortOptions}
            labelText={t("Sort by")}
          ></AlternativeFormRowSelect>
          <div
            style={{
              display: "flex",
              marginTop: "1.5rem",
              alignSelf: "center",
            }}
          >
            <input
              style={{ width: "1.5rem", marginRight: "0.25rem" }}
              type="checkbox"
              onChange={handleAlternativeChange}
              defaultChecked={includeAlternatives}
            />
            <p style={{ lineHeight: "1.25rem", margin: "0" }}>
              {t("include Alternatives")}
            </p>
          </div>
          <div style={{ alignSelf: "end" }}>
            <button
              className="btn btn-block btn-danger btn-filters"
              disabled={isLoading}
              onClick={handleSubmit}
            >
              {t("clear filters")}
            </button>
          </div>
        </div>
      </form>

      {searchProvider !== "all" ? (
        <FilteredProvider
          key={selectedProvider._id}
          provider={selectedProvider}
        />
      ) : (
        <></>
      )}
    </Wrapper>
  );
};

export default SearchContainer;
