import { useTranslation } from "react-i18next";
const Support = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <h1>{t("Need Help?")}</h1>
      <h5>{t("Reach out to us anytime")}</h5>
      <p style={{ marginBottom: "0.5rem" }}>
        <strong>{t("Phone")}:</strong> (438) 393-2937
      </p>
      <p style={{ margin: "0" }}>
        <strong>{t("Email")}:</strong> support@chumon.ca
      </p>
    </div>
  );
};

export default Support;
