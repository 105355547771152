import styled from "styled-components";

const Wrapper = styled.article`
  background: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border-bottom: 1px solid black;

  .active {
    background-color: var(--grey-100);
  }
  .condensed-container {
    /* display: flex;
    flex-direction: row;
    max-height: 2rem; */

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 2rem;
    width: 97vw;
  }
  .items-condensed {
    position: relative;
    display: flex;

    box-sizing: border-box;
    flex-grow: 1;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    border-right: 1px solid black;
    padding: 0.25rem;
  }

  .items-condensed:hover {
    overflow: visible;
  }
  .items-condensed[title]:hover::after {
    content: attr(title);
    position: absolute;
    background: black;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1000;
    display: block;
  }

  .items-condensed[title=""]:hover::after {
    display: none;
  }
`;

export default Wrapper;
