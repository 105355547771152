import {
  ProvidersContainer,
  AddProviderContainer,
  AddProvider,
} from "../../components";
// import Wrapper from "../../assets/wrappers/Provider";

const Provider = () => {
  return (
    <div>
      <AddProviderContainer />
      <AddProvider />
      <ProvidersContainer />
    </div>
  );
};

export default Provider;
