import styled from "styled-components";

const Wrapper = styled.div`
  h4,
  h5 {
    margin: 0;
    text-transform: none;
  }

  .orders-filter {
    border: 1px solid var(--grey-200);
    border-radius: 8px;
    box-shadow: var(--shadow-2);
    background: var(--white);
    width: 12rem;
    display: flex;
    justify-content: space-evenly;
    overflow: hidden;
  }

  .orders-filter button {
    border: none;
    background-color: var(--white);
    cursor: pointer;
    padding: 0.5rem;
    flex-grow: 1;
  }

  .orders-filter button.active {
    background-color: var(--primary-500);
    color: white;
  }

  /* Apply rounded corners to the first button on the left */
  .orders-filter button:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  /* Apply rounded corners to the last button on the right */
  .orders-filter button:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .orders-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0rem;
    margin-top: 0;
  }

  .orders-title {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
  }

  .orders-title h5 {
    margin-bottom: 0.5rem;
  }

  .order-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    padding-bottom: 0;
    transition: box-shadow 0.3s ease;
    cursor: pointer;
  }

  .order-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  .order-card-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .order-card-header h4 {
    margin: 0;
    font-size: 1.1rem;
    color: #333;
  }
  .order-name-container {
    display: flex;
    justify-content: space-between;
    width: 15rem;
  }
  .order-name-edit {
    width: auto;
  }

  .order-card-details {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.9rem;
    color: #666;
    width: auto;

    p {
      margin: 0;
      margin-left: 0.5rem;
    }
  }
  .delivery-day-icon {
    background-color: var(--primary-500);
    color: var(--white);
    font-weight: 800;

    width: 2rem;
    height: 2rem;
    border-radius: 8px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .order-card-items {
    display: none;
    padding-top: 0.5rem;
    border-top: 1px solid #eee;
  }

  .order-card.expanded .order-card-items {
    display: block;
  }

  .order-item-detail {
    background-color: #f9f9f9;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    margin-bottom: 0.5rem;
    padding: 0.5rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      letter-spacing: 0;
    }
    h4 {
      font-size: 1.25rem;
      color: var(--primary-500);
    }
    h2 {
      font-size: 1.25rem;

      font-weight: 900;
      margin-right: 0.5rem;
    }
    h3 {
      color: gray;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem;
      margin-right: 0.5rem;
    }
    h5 {
      font-weight: 500;
      font-size: 1.25rem;
      margin: 0rem 0.5rem;
    }
    h6 {
      font-size: 1rem;
      font-weight: 900;
      color: var(--primary-400);
      margin-right: 0.5rem;
    }
    .itemProportions {
      color: var(--grey-600);
      margin-right: 0.5rem;
      font-size: 1rem;
      font-weight: 700;
      margin-top: 0.25rem;
    }
    @media (min-width: 768px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .order-item-status {
    display: flex;
    flex-wrap: wrap;

    input {
      display: flex;

      margin-left: 0.5rem;
      margin-right: 0.25rem;
      margin-bottom: 0.1rem;
    }
  }
  .order-item-status-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .order-item-detail h5 {
    color: #333;
    margin-top: 0;
  }

  .order-item-detail p {
    margin: 0;
    color: #666;
    font-size: 0.9rem;
  }

  .order-edit-icon {
    cursor: pointer;
    font-size: 20px;
    color: #333;
    transition: color 0.3s;
  }

  .order-edit-icon:hover {
    color: #007bff;
  }

  @media screen and (max-width: 768px) {
    .order-card-items {
      padding: 0;
    }

    .order-item-detail {
      padding: 0.5rem;
    }
  }

  .chart-container {
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: var(--shadow-2);
    padding: 1rem;
    margin: 0.5rem;
  }

  .send-orders-container {
    margin-top: 0.75rem;

    border-radius: 10px;

    background: var(--white);
    box-shadow: var(--shadow-2);

    overflow: hidden;
    h5 {
      margin-top: 0.25rem;
    }
    h4 {
      font-weight: 600;
    }
  }

  .order-card-send {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 0rem 0.5rem;
    margin-top: 0.5rem;
    padding: 0.25rem;

    transition: box-shadow 0.3s ease;
    cursor: pointer;

    h6 {
      margin: 0;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      font-size: 1.25rem;
      max-width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .order-card-send:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  .close-send-orders {
    width: 2rem;
    height: 2rem;
    border-radius: 8px;
    padding: 0.5rem;

    color: var(--white);
    background: var(--red-light);
    @media (min-width: 576px) {
      margin-left: 0.75rem;
    }
    :hover {
      background: var(--red-dark);
    }
  }

  .send-provider-container {
    margin-top: 0.75rem;
  }
  .view-container {
    display: flex;
    margin-right: 1rem;
    margin-top: 0.5rem;

    h5 {
      margin-top: 0;
      margin-right: 0.5rem;
    }
  }
  .file-upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  .totalInventory {
    margin-top: 1rem;
  }
  .totalInventory span {
    font-weight: 700;
  }
`;
export default Wrapper;
