import React, { useEffect, useState } from "react";
import { useAppContext } from "../context/appContext";
import { RiWifiOffLine, RiWifiLine } from "react-icons/ri";

const InternetConnectionChecker = () => {
  const { connectionStatus, pendingChanges, updateConnectionStatus } =
    useAppContext();

  useEffect(() => {
    const handleOnline = () => {
      //console.log("Online");
      updateConnectionStatus("Online");
    };

    const handleOffline = () => {
      //console.log("Offline");
      updateConnectionStatus("Offline");
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [updateConnectionStatus]);

  if (connectionStatus === "Offline") {
    return (
      <div>
        <div className="connection-icon">
          <RiWifiOffLine size={24} />
        </div>
        <div className="changes-icon">{pendingChanges.length}</div>
      </div>
    );
  }

  return null;
};

export default InternetConnectionChecker;
