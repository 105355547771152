import { IoBarChartSharp } from "react-icons/io5";
import { MdQueryStats } from "react-icons/md";
import { FaWpforms, FaIdCard, FaListOl, FaTrashAlt } from "react-icons/fa";
import { ImProfile } from "react-icons/im";
import { TfiHelpAlt } from "react-icons/tfi";

const links = [
  { id: 1, text: "Dashboard", path: "/", icon: <IoBarChartSharp /> },
  { id: 2, text: "Orders", path: "/orders", icon: <FaListOl /> },
  // { id: 2, text: "inventory", path: "inventory", icon: <MdQueryStats /> },
  { id: 3, text: "Stock", path: "stock", icon: <MdQueryStats /> },
  //{ id: 4, text: "add item", path: "add-item", icon: <FaWpforms /> },
  { id: 4, text: "Providers", path: "provider", icon: <FaIdCard /> },
  { id: 5, text: "Menus", path: "menu", icon: <FaWpforms /> },
  { id: 6, text: "Trash", path: "trash", icon: <FaTrashAlt /> },
  { id: 7, text: "Settings", path: "profile", icon: <ImProfile /> },
  { id: 8, text: "Support", path: "support", icon: <TfiHelpAlt /> },
];

export default links;
