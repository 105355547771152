import styled from "styled-components";

const Wrapper = styled.aside`
  border-radius: 10px;

  background: var(--white);
  box-shadow: var(--shadow-2);

  overflow: hidden;
  margin-top: 0.5rem;

  h4 {
    margin: 0;
  }
  p {
    margin: 0;
    line-height: 1.25rem;
  }
  h5 {
    margin: 0;
    margin-left: 0.5rem;
  }

  .reset-quantities-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .reset-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .reset-options {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .reset-option {
    display: flex;
    flex-wrap: nowrap;
    margin: 0.25rem;

    @media (min-width: 768px) {
      margin: 0.75rem;
    }
  }
  .reset-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      min-height: 2rem;
      margin-left: 0.5rem;
    }
  }

  .auto-order-container {
    p {
      margin-top: 0.75rem;
      font-size: 1.25rem;
    }
    button {
      margin-top: 0.75rem;
    }
  }
`;
export default Wrapper;
