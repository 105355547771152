import styled from "styled-components";

const Wrapper = styled.aside`
  margin-top: 0.75rem;
  border-radius: 10px;

  background: var(--white);
  box-shadow: var(--shadow-2);

  h5 {
    margin: 0;
    margin-left: 0.5rem;
  }
  .edit-view-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    padding: 1rem 1rem 1rem;
  }
  .view-container {
    display: flex;
    margin-right: 1rem;
  }
  .reset-button {
    background-color: var(--grey-600);
    margin: 0.25rem 0.25rem 0.25rem auto;
  }
  .reset-button:hover {
    background: var(--black);
  }
`;
export default Wrapper;
