import { useState, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { useAppContext } from "../context/appContext";
import Wrapper from "../assets/wrappers/ErrorPercentage";
import FormRow from "./FormRow";
import { gsap } from "gsap";
import { useTranslation } from "react-i18next";

const EditErrorPercentage = () => {
  const { t } = useTranslation();
  const {
    isLoading,
    isEditingErrorPercentage,
    displayAlert,
    userSettings,
    handleChange,
    updateUserSettings,
    toggleEditErrorPercentage,
    errorPercentage,
  } = useAppContext();

  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isConfirmingUpdateAll, setIsConfirmingUpdateAll] = useState(false);
  const { id: menuId } = useParams();

  useEffect(() => {
    let timeout;
    if (isConfirmingUpdateAll) {
      timeout = setTimeout(() => {
        setIsConfirmingUpdateAll(false);
      }, 3000);
    }
    return () => clearTimeout(timeout);
  }, [isConfirmingUpdateAll]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (userSettings.errorPercentage === undefined) {
      displayAlert();
      return;
    }

    setShowConfirmation(true);
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({ name, value });
  };

  const handleUpdateAll = () => {
    if (isConfirmingUpdateAll) {
      updateUserSettings({ errorPercentage }, true, menuId);
      setShowConfirmation(false);
    } else {
      setIsConfirmingUpdateAll(true);
    }
  };

  const handleUpdateSettings = () => {
    updateUserSettings({ errorPercentage }, false);
    setShowConfirmation(false);
    toggleEditErrorPercentage();
  };

  //ANIMATIONS
  useLayoutEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight + 15);
    }

    const tl = gsap.timeline();

    if (isEditingErrorPercentage) {
      setIsVisible(true);
      tl.set(contentRef.current, {
        height: 0,
        padding: 0,
        autoAlpha: 0,
      }).to(contentRef.current, {
        height: contentHeight,
        padding: "1rem",
        autoAlpha: 1,
        duration: 0.5,
      });
    } else if (contentRef.current) {
      tl.to(contentRef.current, {
        height: 0,
        padding: 0,
        autoAlpha: 0,
        duration: 0.5,
      }).then(() => setIsVisible(false));
    }

    return () => tl.kill();
  }, [isEditingErrorPercentage, contentHeight]);

  if (!isVisible && !isEditingErrorPercentage) {
    return null;
  }

  return (
    <Wrapper>
      {showConfirmation && (
        <>
          <div className="backdrop"></div>
          <div className="delete-confirmation">
            <h3>{t("Update Error Percentage")}</h3>
            <p>
              {t(
                "Would you like to update all menu items with the new error percentage?"
              )}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <button
                style={{ maxWidth: "130px", margin: "0.25rem" }}
                className="btn"
                onClick={handleUpdateSettings}
              >
                {t("Update Settings Only")}
              </button>
              <button
                style={{ maxWidth: "130px", margin: "0.25rem" }}
                className="btn btn-danger"
                onClick={handleUpdateAll}
              >
                {isConfirmingUpdateAll
                  ? t("Confirm Update All")
                  : t("Update All")}
              </button>
            </div>
            <button
              style={{ marginTop: "0.5rem" }}
              className="btn alert-success"
              onClick={() => setShowConfirmation(false)}
            >
              {t("Cancel")}
            </button>
          </div>
        </>
      )}
      <form
        ref={contentRef}
        className="form_active"
        style={{ height: "auto", padding: "0.5rem" }}
      >
        <div className="form-center">
          <FormRow
            type="number"
            name="errorPercentage"
            value={errorPercentage}
            handleChange={handleInputChange}
            labelText={t("Error Percentage")}
            style={{ width: "10rem", marginRight: "1rem" }}
          />
          <div className="btn-container">
            <button
              type="submit"
              className="btn btn-block submit-btn"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {t("Save")}
            </button>
            <button
              type="button"
              className="btn btn-block delete-btn"
              onClick={toggleEditErrorPercentage}
              disabled={isLoading}
              style={{ marginLeft: "0.5rem" }}
            >
              {t("Cancel")}
            </button>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

export default EditErrorPercentage;
