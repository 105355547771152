import { useAppContext } from "../../context/appContext";
import { useEffect, useState, useMemo } from "react";
import Alert from "../Alert";
import Loading from "../Loading";
import DeletedItem from "./DeletedItem";
import DeletedOrder from "./DeletedOrder";
import Wrapper from "../../assets/wrappers/ProvidersContainer";
import FormRowSelect from "../FormRowSelect";
import TrashedPageBtnContainer from "./TrashedPageBtnContainer";
import FormRow from "../FormRow";
import { useTranslation } from "react-i18next";
import AlternativeFormRowSelect from "../AlternativeFormRowSelect";

import SearchWrapper from "../../assets/wrappers/SearchContainer";

const TrashedItemsContainer = () => {
  const { t } = useTranslation();
  const {
    getTrashedItems,
    trashedModels,
    isLoading,
    totalTrashedModels,
    trashedModelsPage,
    handleChange,
    selectedTrashModel,
    numOfTrashedPages,
    providerOptions,
    itemCategoryOptions,
    itemLocationOptions,
    providers,
    itemKitchenOptions,
    itemTypeOptions,
    getItems,
    items,
    itemsPerPage,
    itemLocations,
    itemCategories,
    itemTypes,
    itemKitchens,
  } = useAppContext();

  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [searchProvider, setSearchProvider] = useState("all");
  const [searchCategory, setSearchCategory] = useState("all");
  const [searchLocation, setSearchLocation] = useState("all");
  const [searchType, setSearchType] = useState("all");
  const [searchKitchen, setSearchKitchen] = useState("all");
  const [sort, setSort] = useState("deleted-desc");
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search);
    }, 800);
    return () => clearTimeout(timer);
  }, [search]);

  useEffect(() => {
    if (items.length < 1) {
      getItems();
    }
  }, []);

  useEffect(() => {
    getTrashedItems(selectedTrashModel);
  }, [selectedTrashModel]);

  const maps = useMemo(
    () => ({
      providerIdMap: new Map(
        providers.map((provider) => [provider.providerName, provider._id])
      ),
      locationIdMap: new Map(
        itemLocations.map((item) => [item.locationName, item._id])
      ),
      categoryIdMap: new Map(
        itemCategories.map((item) => [item.categoryName, item._id])
      ),
      typeIdMap: new Map(itemTypes.map((item) => [item.typeName, item._id])),
      kitchenIdMap: new Map(
        itemKitchens.map((item) => [item.kitchenName, item._id])
      ),
    }),
    [providers, itemLocations, itemCategories, itemTypes, itemKitchens]
  );

  const filteredItems = useMemo(() => {
    if (selectedTrashModel !== "item") return [];

    return trashedModels
      .filter((item) => {
        const searchMatch =
          debouncedSearch === "" ||
          item.itemName.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
          item.itemSupplier
            .toLowerCase()
            .includes(debouncedSearch.toLowerCase()) ||
          item.itemCode.toLowerCase().includes(debouncedSearch.toLowerCase());

        const providerMatch =
          searchProvider === "all" ||
          maps.providerIdMap.get(searchProvider) === item.provider;
        const categoryMatch =
          searchCategory === "all" ||
          maps.categoryIdMap.get(searchCategory) === item.itemCategory;
        const locationMatch =
          searchLocation === "all" ||
          maps.locationIdMap.get(searchLocation) === item.itemLocation;
        const typeMatch =
          searchType === "all" ||
          maps.typeIdMap.get(searchType) === item.itemType;
        const kitchenMatch =
          searchKitchen === "all" ||
          maps.kitchenIdMap.get(searchKitchen) === item.itemKitchen;

        return (
          searchMatch &&
          providerMatch &&
          categoryMatch &&
          locationMatch &&
          typeMatch &&
          kitchenMatch
        );
      })
      .sort((a, b) => {
        switch (sort) {
          case "a-z":
            return a.itemName.localeCompare(b.itemName);
          case "z-a":
            return b.itemName.localeCompare(a.itemName);
          case "price high-low":
            return b.price - a.price;
          case "price low-high":
            return a.price - b.price;
          case "deleted-asc":
            return new Date(a.deletedAt) - new Date(b.deletedAt);
          case "deleted-desc":
          default:
            return new Date(b.deletedAt) - new Date(a.deletedAt);
        }
      });
  }, [
    trashedModels,
    debouncedSearch,
    searchProvider,
    searchCategory,
    searchLocation,
    searchType,
    searchKitchen,
    sort,
    maps,
  ]);

  const numOfPages = useMemo(() => {
    const listToPaginate =
      selectedTrashModel === "item" ? filteredItems : trashedModels;
    return Math.ceil(listToPaginate.length / itemsPerPage);
  }, [filteredItems, trashedModels, selectedTrashModel, itemsPerPage]);

  const currentModels = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const listToSlice =
      selectedTrashModel === "item" ? filteredItems : trashedModels;
    return listToSlice.slice(startIndex, startIndex + itemsPerPage);
  }, [
    filteredItems,
    trashedModels,
    currentPage,
    itemsPerPage,
    selectedTrashModel,
  ]);
  const handleModelChange = (e) => {
    handleChange({ name: "selectedTrashModel", value: e.target.value });
    getTrashedItems(e.target.value);
  };

  const TrashedModelSelect = () => {
    return (
      <select
        value={selectedTrashModel}
        onChange={handleModelChange}
        className="model-select"
      >
        <option value="item">{t("Items")}</option>
        <option value="order">{t("Orders")}</option>
      </select>
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSearch("");
    setSearchProvider("all");
    setSearchCategory("all");
    setSearchLocation("all");
    setSearchType("all");
    setSearchKitchen("all");
    setSort("deleted-desc");
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "search":
        setSearch(value);
        break;
      case "searchProvider":
        setSearchProvider(value);
        break;
      case "searchCategory":
        setSearchCategory(value);
        break;
      case "searchLocation":
        setSearchLocation(value);
        break;
      case "searchType":
        setSearchType(value);
        break;
      case "searchKitchen":
        setSearchKitchen(value);
        break;
      case "sort":
        setSort(value);
        break;
      default:
        break;
    }
    setCurrentPage(1);
  };
  const sortOptions = [
    { value: "deleted-desc", label: t("Deleted: Newest First") },
    { value: "deleted-asc", label: t("Deleted: Oldest First") },
    { value: "a-z", label: t("Alphabetical: A to Z") },
    { value: "z-a", label: t("Alphabetical: Z to A") },
    { value: "price high-low", label: t("Price: High to Low") },
    { value: "price low-high", label: t("Price: Low to High") },
  ];
  const getPluralForm = (count, single, plural) => {
    return count === 1 ? single : plural;
  };

  if (!isLoading && trashedModels.length === 0) {
    const displayMessage = `No deleted ${selectedTrashModel}s to display`;
    return (
      <Wrapper>
        <div className="providers-title">
          <TrashedModelSelect />
        </div>
        <h2>{t(displayMessage)}</h2>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div
        className="providers-title"
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "1.5rem",
          marginBottom: "0.5rem",
          marginTop: "0.75rem",
        }}
      >
        <TrashedModelSelect />
      </div>
      {selectedTrashModel === "item" && (
        <SearchWrapper>
          <form
            className="form"
            style={{ marginBottom: "0.5rem", marginTop: "0" }}
          >
            <div className="form-center">
              {/* Search and Filter Form Rows */}
              <div className="form-search">
                <FormRow
                  type="text"
                  name="search"
                  value={search}
                  handleChange={handleSearchChange}
                  labelText={t("Search")}
                />
              </div>
              <FormRowSelect
                name="searchProvider"
                value={searchProvider}
                handleChange={handleSearchChange}
                list={["all", ...providerOptions]}
                labelText={t("provider")}
              />
              <FormRowSelect
                name="searchCategory"
                value={searchCategory}
                handleChange={handleSearchChange}
                list={["all", ...itemCategoryOptions]}
                labelText={t("category")}
              />
              <FormRowSelect
                name="searchLocation"
                value={searchLocation}
                handleChange={handleSearchChange}
                list={["all", ...itemLocationOptions]}
                labelText={t("location")}
              />
              <FormRowSelect
                name="searchType"
                value={searchType}
                handleChange={handleSearchChange}
                list={["all", ...itemTypeOptions]}
                labelText={t("type")}
              />
              <FormRowSelect
                name="searchKitchen"
                value={searchKitchen}
                handleChange={handleSearchChange}
                list={["all", ...itemKitchenOptions]}
                labelText={t("Kitchen")}
              />
              <AlternativeFormRowSelect
                name="sort"
                value={sort}
                handleChange={handleSearchChange}
                list={sortOptions}
                labelText={t("Sort by")}
              />
              <div style={{ alignSelf: "end" }}>
                <button
                  className="btn btn-block btn-danger btn-filters"
                  disabled={isLoading}
                  onClick={handleSubmit}
                  style={{ height: "auto" }}
                >
                  {t("clear filters")}
                </button>
              </div>
            </div>
          </form>
        </SearchWrapper>
      )}
      {selectedTrashModel === "item" ? (
        <h5 className="providers-title-h5">
          {filteredItems.length}{" "}
          {getPluralForm(
            filteredItems.length,
            t("deleted_item"),
            t("deleted_items")
          )}{" "}
          {getPluralForm(filteredItems.length, t("found"), t("found_plural"))}
        </h5>
      ) : (
        <h5 className="providers-title-h5">
          {trashedModels.length}{" "}
          {getPluralForm(
            trashedModels.length,
            t("deleted_order"),
            t("deleted_orders")
          )}{" "}
          {getPluralForm(trashedModels.length, t("found"), t("found_plural"))}
        </h5>
      )}

      <div className={`providers`}>
        {selectedTrashModel === "item"
          ? currentModels.map((item) => (
              <DeletedItem key={item._id} item={item} />
            ))
          : currentModels.map((order) => (
              <DeletedOrder key={order._id} order={order} />
            ))}
      </div>
      {numOfPages > 1 && (
        <TrashedPageBtnContainer
          numOfPages={numOfPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </Wrapper>
  );
};

export default TrashedItemsContainer;
