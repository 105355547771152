import { useAppContext } from "../../context/appContext";
import { useEffect } from "react";
import Alert from "../Alert";
import Loading from "../Loading";
import MenuItem from "./MenuItem";
import Wrapper from "../../assets/wrappers/ProvidersContainer";
import { useParams } from "react-router-dom";

import { BsQuestionCircle } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useTranslation } from "react-i18next";

const MenuItemsContainer = () => {
  const { t } = useTranslation();
  const { getItems, getMenuItems, menuItems, totalMenuItems, items } =
    useAppContext();
  const { id: menuId } = useParams();
  useEffect(() => {
    if (items.length < 1) {
      getItems();
    }
    getMenuItems(menuId);
  }, []);

  if (!menuItems || menuItems.length === 0) {
    return (
      <Wrapper>
        <h2>{t("No menu items to display...")}</h2>
      </Wrapper>
    );
  }
  const getPluralForm = (count, single, plural) => {
    return count === 1 ? single : plural;
  };
  return (
    <Wrapper>
      <div className="providers-title">
        <h5 className="providers-title-h5">
          {menuItems.length}{" "}
          {getPluralForm(
            menuItems.length,
            t("menu item found"),
            t("menu items found")
          )}
        </h5>
        <Tippy content={t("tooltipMenuItemPrice")}>
          <div>
            <BsQuestionCircle
              style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }}
              className="info-icon"
            />
          </div>
        </Tippy>
      </div>
      <div className={`providers`}>
        {menuItems.map((menuItemName, index) => {
          const menuItem = menuItems[index];
          return <MenuItem key={menuItem._id} menuItem={menuItem} />;
        })}
      </div>
    </Wrapper>
  );
};

export default MenuItemsContainer;
