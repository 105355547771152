import styled from "styled-components";

const Wrapper = styled.section`
  h2 {
    text-transform: none;
  }
  & > h5 {
    font-weight: 700;
  }
  .locations {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin-bottom: 0.75rem;
  }
  .items {
    display: flex;
    flex-direction: column;
    max-width: 97vw;
  }
  .items-condensed-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 97vw;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
  }
  .grid-titles {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 97vw;

    /* display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    width: 98vw; */
  }
  .grid-titles > div {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    /* background-color: var(--white);
    border: 1px solid black; */
    padding: 0.25rem;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .grid-button {
    border-radius: 20%;
    padding: 0.25rem;
    height: 2rem;
    margin-top: 0.75rem;
    cursor: pointer;
  }
  .items-title-h5 {
    margin-bottom: 0;
    margin-left: 1rem;
  }
  .items-title {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    max-width: 100vw;
    margin-top: 0.25rem;
  }
  .items-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem;
  }

  @media (min-width: 992px) {
    .jobs {
      display: flex;
      flex-direction: column;
    }
  }
`;
export default Wrapper;
