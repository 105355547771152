import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/Provider";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Provider = ({ provider }) => {
  const { t } = useTranslation();
  const { setEditProvider, deleteProvider, deliveryDaysOptions } =
    useAppContext();

  return (
    <Wrapper>
      <div>
        <div className="provider-title">
          <h2>{provider.providerName}</h2>
          <h4>
            {provider.number}
            {provider.website && (
              <a
                href={provider.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaExternalLinkAlt
                  style={{ width: "1rem", marginLeft: "1rem" }}
                />
              </a>
            )}
          </h4>
        </div>
        <div className="provider-content">
          <div className="provider-info">
            <div className="compact-day-selector">
              {deliveryDaysOptions.map((day) => (
                <span
                  key={day}
                  className={`compact-day ${
                    provider.deliveryDay.includes(day) ? "selected" : ""
                  }`}
                >
                  {t(day)}
                </span>
              ))}
            </div>
            {provider.deliveryTime && (
              <h5 style={{ color: "var(--primary-600)", whiteSpace: "nowrap" }}>
                {t("Delivery Time")}: {provider.deliveryTime}
              </h5>
            )}
            {provider.email && <p>{provider.email}</p>}
            {provider.providerId && (
              <p>
                {t("Id")}: {provider.providerId}
              </p>
            )}
            {provider.passwordInfo && (
              <p>
                {t("Password Info")}: {provider.passwordInfo}
              </p>
            )}
            {provider.minimumOrder > 0 && (
              <p>
                {t("Minimum Order")}: {provider.minimumOrder}
              </p>
            )}
            {provider.orderDate && (
              <p>
                {t("Order Date")}: {provider.orderDate}
              </p>
            )}
            {provider.representName && (
              <p>
                {t("Representative Name")}: {provider.representName}
              </p>
            )}
            {provider.holiday && (
              <p>
                {t("Closed on")}: {provider.holiday}
              </p>
            )}
            <p>
              {t("Notes")}: {provider.notes}
            </p>
          </div>
        </div>
        <div className="actions">
          <button
            type="button"
            className="btn edit-btn"
            onClick={() => setEditProvider(provider._id)}
          >
            {t("Edit")}
          </button>
          {/* <button
            type="button"
            className="btn delete-btn"
            onClick={() => deleteProvider(provider._id)}
          >
            {t("Delete")}
          </button> */}
        </div>
      </div>
    </Wrapper>
  );
};

export default Provider;
