import { useState, useEffect } from "react";
import { useAppContext } from "../context/appContext";
import { useTranslation } from "react-i18next";

const DeleteConfirmation = () => {
  const { t } = useTranslation();
  const {
    isDeleting,
    editItemId,
    toggleDeleteConfirmation,
    softDeleteItem,
    deleteItem,
  } = useAppContext();

  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  useEffect(() => {
    let timeout;
    if (isConfirmingDelete) {
      timeout = setTimeout(() => {
        setIsConfirmingDelete(false);
      }, 3000);
    }
    return () => clearTimeout(timeout);
  }, [isConfirmingDelete]);

  const handlePermanentDelete = () => {
    if (isConfirmingDelete) {
      deleteItem(editItemId);
      toggleDeleteConfirmation();
    } else {
      setIsConfirmingDelete(true);
    }
  };

  if (!isDeleting) {
    return null;
  }

  return (
    <>
      <div className="backdrop"></div>
      <div className="delete-confirmation">
        <h3>{t("moveToTrashOrDelete")}</h3>
        <p>{t("pleaseConfirmAction")}</p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <button
            style={{ maxWidth: "150px", margin: "0.25rem" }}
            className="btn"
            onClick={() => softDeleteItem("item", editItemId)}
          >
            {t("moveToTrash")}
          </button>
          <button
            style={{ maxWidth: "150px", margin: "0.25rem" }}
            className="btn btn-danger"
            onClick={handlePermanentDelete}
          >
            {isConfirmingDelete ? t("confirmDelete") : t("deletePermanently")}
          </button>
        </div>

        <button
          style={{ marginTop: "0.5rem" }}
          className="btn alert-success"
          onClick={toggleDeleteConfirmation}
        >
          {t("cancel")}
        </button>
      </div>
    </>
  );
};

export default DeleteConfirmation;
