import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/ItemsContainer";
import ItemType from "./ItemType";

const TypeContainer = () => {
  const { itemTypes } = useAppContext();

  return (
    <Wrapper>
      <div className="locations">
        {itemTypes.map((itemType) => {
          return <ItemType key={itemType._id} itemType={itemType} />;
        })}
      </div>
    </Wrapper>
  );
};

export default TypeContainer;
