import React, { useReducer, useContext } from "react";
import reducer from "./reducer";
import axios from "axios";
import i18n from "../i18n";

import {
  DISPLAY_ALERT,
  CLEAR_ALERT,
  SETUP_USER_BEGIN,
  SETUP_USER_SUCCESS,
  SETUP_USER_ERROR,
  TOGGLE_SIDEBAR,
  LOGOUT_USER,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  HANDLE_CHANGE,
  CLEAR_VALUES,
  CREATE_ITEM_BEGIN,
  CREATE_ITEM_SUCCESS,
  CREATE_ITEM_ERROR,
  GET_ITEMS_BEGIN,
  GET_ITEMS_SUCCESS,
  SET_ADD_ITEM,
  SET_EDIT_ITEM,
  TOGGLE_DELETE_CONFIRMATION,
  DELETE_ITEM_BEGIN,
  DELETE_ITEM_SUCCESS,
  EDIT_ITEM_BEGIN,
  EDIT_ITEM_SUCCESS,
  EDIT_ITEM_ERROR,
  ADD_CUSTOM_PROPORTION,
  HANDLE_CUSTOM_PROPORTION_CHANGE,
  REMOVE_CUSTOM_PROPORTION,
  CHANGE_PROPORTIONS_BEGIN,
  CHANGE_PROPORTIONS_SUCCESS,
  CHANGE_PROPORTIONS_ERROR,
  CREATE_PROVIDER_BEGIN,
  CREATE_PROVIDER_SUCCESS,
  CREATE_PROVIDER_ERROR,
  GET_PROVIDERS_BEGIN,
  GET_PROVIDERS_SUCCESS,
  SET_ADD_PROVIDER,
  SET_EDIT_PROVIDER,
  SET_DELIVERY_DAY,
  DELETE_PROVIDER_BEGIN,
  EDIT_PROVIDER_BEGIN,
  EDIT_PROVIDER_SUCCESS,
  EDIT_PROVIDER_ERROR,
  CLEAR_FILTERS,
  UPDATE_ITEM_QUANTITY_BEGIN,
  UPDATE_ITEM_QUANTITY_SUCCESS,
  UPDATE_ITEM_QUANTITY_ERROR,
  UPDATE_ITEM_INVENTORY_QUANTITY_BEGIN,
  UPDATE_ITEM_INVENTORY_QUANTITY_SUCCESS,
  UPDATE_ITEM_INVENTORY_QUANTITY_ERROR,
  TOGGLE_RESET_QUANTITIES,
  TOGGLE_AUTO_ORDER,
  //Location
  CREATE_ITEM_LOCATION_BEGIN,
  CREATE_ITEM_LOCATION_ERROR,
  CREATE_ITEM_LOCATION_SUCCESS,
  SET_ADD_ITEM_LOCATION,
  GET_ITEM_LOCATIONS_BEGIN,
  GET_ITEM_LOCATIONS_SUCCESS,
  DELETE_ITEM_LOCATION_BEGIN,
  SET_EDIT_ITEM_LOCATION,
  EDIT_ITEM_LOCATION_BEGIN,
  EDIT_ITEM_LOCATION_SUCCESS,
  EDIT_ITEM_LOCATION_ERROR,
  //Category
  CREATE_ITEM_CATEGORY_BEGIN,
  CREATE_ITEM_CATEGORY_SUCCESS,
  CREATE_ITEM_CATEGORY_ERROR,
  SET_ADD_ITEM_CATEGORY,
  GET_ITEM_CATEGORIES_BEGIN,
  GET_ITEM_CATEGORIES_SUCCESS,
  DELETE_ITEM_CATEGORY_BEGIN,
  SET_EDIT_ITEM_CATEGORY,
  EDIT_ITEM_CATEGORY_BEGIN,
  EDIT_ITEM_CATEGORY_SUCCESS,
  EDIT_ITEM_CATEGORY_ERROR,
  //Type
  CREATE_ITEM_TYPE_BEGIN,
  CREATE_ITEM_TYPE_SUCCESS,
  CREATE_ITEM_TYPE_ERROR,
  SET_ADD_ITEM_TYPE,
  GET_ITEM_TYPES_BEGIN,
  GET_ITEM_TYPES_SUCCESS,
  DELETE_ITEM_TYPE_BEGIN,
  SET_EDIT_ITEM_TYPE,
  EDIT_ITEM_TYPE_BEGIN,
  EDIT_ITEM_TYPE_SUCCESS,
  EDIT_ITEM_TYPE_ERROR,
  //Kitchen
  CREATE_ITEM_KITCHEN_BEGIN,
  CREATE_ITEM_KITCHEN_SUCCESS,
  CREATE_ITEM_KITCHEN_ERROR,
  SET_ADD_ITEM_KITCHEN,
  GET_ITEM_KITCHENS_BEGIN,
  GET_ITEM_KITCHENS_SUCCESS,
  DELETE_ITEM_KITCHEN_BEGIN,
  SET_EDIT_ITEM_KITCHEN,
  EDIT_ITEM_KITCHEN_BEGIN,
  EDIT_ITEM_KITCHEN_SUCCESS,
  EDIT_ITEM_KITCHEN_ERROR,
  //ALTERNATIVES
  TOGGLE_ALTERNATIVES,
  GET_ITEM_ALTERNATIVES_BEGIN,
  GET_ITEM_ALTERNATIVES_SUCCESS,
  ADD_ITEM_ALTERNATIVE_BEGIN,
  REMOVE_ITEM_ALTERNATIVE,
  //UI
  TOGGLE_CONDENSED,
  CHANGE_PAGE,
  CHANGE_TRASHED_PAGE,
  //Menus
  CREATE_MENU_BEGIN,
  CREATE_MENU_SUCCESS,
  CREATE_MENU_ERROR,
  GET_MENUS_BEGIN,
  GET_MENUS_SUCCESS,
  SET_ADD_MENU,
  SET_EDIT_MENU,
  DELETE_MENU_BEGIN,
  EDIT_MENU_BEGIN,
  EDIT_MENU_SUCCESS,
  EDIT_MENU_ERROR,
  SET_MENU_ITEMS,
  //MenuItems
  CREATE_MENU_ITEM_BEGIN,
  CREATE_MENU_ITEM_SUCCESS,
  CREATE_MENU_ITEM_ERROR,
  GET_MENU_ITEMS_BEGIN,
  GET_MENU_ITEMS_SUCCESS,
  SET_ADD_MENU_ITEM,
  SET_EDIT_MENU_ITEM,
  DELETE_MENU_ITEM_BEGIN,
  EDIT_MENU_ITEM_BEGIN,
  EDIT_MENU_ITEM_SUCCESS,
  EDIT_MENU_ITEM_ERROR,
  ADD_INGREDIENT_FIELD,
  REMOVE_INGREDIENT_FIELD,
  UPDATE_INGREDIENT_FIELD,
  SEARCH_INGREDIENT_BEGIN,
  SEARCH_INGREDIENT_SUCCESS,
  CLEAR_INGREDIENT_SEARCH_RESULTS,
  SET_EDIT_VIEW,
  EDIT_VIEW,
  RESET_VIEW,
  RESET_ITEMS_QUANTITY_BEGIN,
  RESET_ITEMS_QUANTITY_SUCCESS,
  RESET_ITEMS_QUANTITY_ERROR,
  GET_ITEM_BACKUP_BEGIN,
  GET_ITEM_BACKUP_SUCCESS,
  RESTORE_ITEM_QUANTITIES_BEGIN,
  RESTORE_ITEM_QUANTITIES_SUCCESS,
  RESTORE_ITEM_QUANTITIES_ERROR,
  TOGGLE_EXPORT_DATA,
  //trash
  SOFT_DELETE_ITEM_BEGIN,
  SOFT_DELETE_ITEM_SUCCESS,
  RESTORE_ITEM_BEGIN,
  RESTORE_ITEM_SUCCESS,
  GET_TRASHED_ITEMS_BEGIN,
  GET_TRASHED_ITEMS_SUCCESS,
  //orders
  CREATE_ORDER_BEGIN,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_ERROR,
  GET_ORDERS_BEGIN,
  GET_ORDERS_SUCCESS,
  DELETE_ORDER_BEGIN,
  SET_EDIT_ORDER,
  UPDATE_ORDER_ITEM_STATUS,
  UPDATE_MISSING_NUMBER,
  EDIT_ORDER_BEGIN,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_ERROR,
  TOGGLE_CREATE_ORDER,
  TOGGLE_SEND_ORDERS,
  SEND_ORDERS_BEGIN,
  SEND_ORDERS_SUCCESS,
  SEND_ORDERS_ERROR,
  //GMAIL
  SET_OAUTH_TOKEN,
  FETCH_REPORTS_BEGIN,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORTS_ERROR,
  TOGGLE_SUBITEM_DETAILS,
  //STRIPE
  GET_SUBSCRIPTION_BEGIN,
  GET_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_SUCCESS,
  SUBSCRIPTION_ERROR,
  VERIFY_SESSION_BEGIN,
  VERIFY_SESSION_SUCCESS,
  UPDATE_STRIPE_INFO_BEGIN,
  UPDATE_STRIPE_INFO_SUCCESS,
  UPDATE_STRIPE_INFO_ERROR,
  CHECK_SUBSCRIPTION_SUCCESS,
  CHECK_SUBSCRIPTION_FAILURE,
  //Invoices
  TOGGLE_SCAN_ORDER_INVOICE,
  SCAN_INVOICE_BEGIN,
  SCAN_INVOICE_SUCCESS,
  SCAN_INVOICE_ERROR,
  COMPARE_INVOICE_BEGIN,
  COMPARE_INVOICE_SUCCESS,
  COMPARE_INVOICE_ERROR,
  UPDATE_PRICES_BEGIN,
  UPDATE_PRICES_SUCCESS,
  UPDATE_PRICES_ERROR,
  //UserSettings
  CREATE_USER_SETTINGS_BEGIN,
  CREATE_USER_SETTINGS_SUCCESS,
  CREATE_USER_SETTINGS_ERROR,
  GET_USER_SETTINGS_BEGIN,
  GET_USER_SETTINGS_SUCCESS,
  UPDATE_USER_SETTINGS_BEGIN,
  UPDATE_USER_SETTINGS_SUCCESS,
  UPDATE_USER_SETTINGS_ERROR,
  TOGGLE_EDIT_ERROR_PERCENTAGE,
  //connection
  ADD_PENDING_CHANGE,
  CLEAR_PENDING_CHANGES,
  SET_CONNECTION_STATUS,
} from "./actions";

const token = localStorage.getItem("token");
const user = localStorage.getItem("user");
const subscriptionStatus = localStorage.getItem("subscriptionStatus");
const planId = localStorage.getItem("planId");

const initialState = {
  isLoading: false,
  showAlert: false,
  alertText: "",
  alertType: "",
  user: user ? JSON.parse(user) : null,
  token: token,
  subscription: subscriptionStatus || "inactive",
  planId: planId ? JSON.parse(planId) : null,
  oauthToken: "",
  pendingChanges: [],
  connectionStatus: navigator.onLine ? "Online" : "Offline",
  showSidebar: false,
  showAlternatives: false,
  isCondensed: false,
  isExportingData: false,
  exportContext: "",
  isAdding: false,
  isEditing: false,
  isDeleting: false,
  editItemId: "",
  isResettingQuantities: false,
  isAddingProvider: false,
  isEditingProvider: false,
  editProviderId: "",
  isAddingLocation: false,
  isEditingLocation: false,
  editItemLocationId: "",
  isAddingCategory: false,
  isEditingCategory: false,
  editItemCategoryId: "",
  isAddingType: false,
  isEditingType: false,
  editItemTypeId: "",
  isAddingKitchen: false,
  isEditingKitchen: false,
  editItemKitchenId: "",
  isAddingMenu: false,
  isEditingMenu: false,
  editMenuId: "",
  menuItemsId: "",
  isAddingMenuItem: false,
  isEditingMenuItem: false,
  isEditingView: false,
  editMenuItemId: "",
  isCreatingOrder: false,
  isEditingOrder: false,
  editOrderId: "",
  isSendingOrders: false,
  isAutoOrdering: false,
  isScanningOrderInvoice: false,
  scanningOrderId: "",
  isScanningLoading: false,
  invoiceItems: [],
  linkedItems: [],
  isComparingLoading: false,
  exportingOrderId: "",
  isEditingErrorPercentage: false,
  userSettings: {},
  errorPercentage: 0,
  itemName: "",
  itemProportions: "",
  itemProportionsType: "Default",
  itemProportionsAmount: 0,
  itemProportionsAmountType: "Other",
  isShowingProportions: false,
  price: 0,
  itemCode: "",
  providerOptions: [],
  provider: "Default",
  itemSupplier: "",
  itemAmount: 0,
  itemAmountType: "Other",
  itemAmountTypeOptions: ["Other", "ml", "L", "g", "Kg", "Oz", "lb", "unit"],
  itemLocationOptions: [],
  itemLocation: "Default",
  itemCategoryOptions: [],
  itemCategory: "Default",
  itemTypeOptions: [],
  itemType: "Default",
  itemKitchenOptions: [],
  itemKitchen: "Default",
  itemQuantity: 0,
  itemInventoryQuantity: 0,
  itemNeeded: "",
  itemNeededType: "Default",
  itemLeftToOrder: "",
  itemLeftToOrderType: "Default",
  itemCustomProportions: [],
  itemBackup: {},
  isReceived: false,
  isBackordered: false,
  isMissing: false,
  //isAlternative: false,
  isUnity: false,
  providerName: "",
  number: "",
  notes: "",
  deliveryDays: [],
  deliveryDaysOptions: ["Su", "M", "T", "W", "Th", "F", "Sa"],
  website: "",
  deliveryTime: "",
  holiday: "",
  email: "",
  providerId: "",
  passwordInfo: "",
  minimumOrder: 0,
  orderDate: "",
  representName: "",
  items: [],
  trashedModels: [],
  itemAlternatives: [],
  selectedAlternatives: [],
  alternativePairs: [],
  totalItems: 0,
  numOfPages: 1,
  itemsPerPage: 40,
  totalTrashedModels: 0,
  numOfTrashedPages: 1,
  page: 1,
  trashedModelsPage: 1,
  selectedTrashModel: "item",
  selectedPos: "Cluster",
  providers: [],
  totalProviders: 0,
  locationName: "",
  itemLocations: [],
  totalLocations: 0,
  categoryName: "",
  itemCategories: [],
  totalCategories: 0,
  typeName: "",
  itemTypes: [],
  totalTypes: 0,
  kitchenName: "",
  itemKitchens: [],
  totalKitchens: 0,
  menuName: "",
  menuDescription: "",
  menus: [],
  totalMenus: 0,
  menuItemName: "",
  menuItemPrice: 0,
  menuItemNotes: "",
  menuItemImage: "",
  menuItemOrders: 0,
  menuItemError: 0,
  menuItemIngredients: [
    {
      item: "",
      ingredientQuantity: 0,
      ingredientQType: "Other",
      wastePercentage: 0,
    },
  ],
  menuItems: [],
  totalMenuItems: 0,
  menuIngredients: [],
  orders: [],
  totalOrders: 0,
  orderName: "",
  orderNotes: "",
  orderDeliveryDay: "",
  salesReports: [],
  timeFrame: "week",
  search: "",
  searchAlternatives: "",
  searchIngredients: "",
  searchProvider: "all",
  filteredProvider: "",
  searchLocation: "all",
  searchCategory: "all",
  searchType: "all",
  searchKitchen: "all",
  sort: "a-z",
  includeAlternatives: false,
  sortOptions: [
    "a-z",
    "z-a",
    "Order Quantity high-low",
    "Order Quantity low-high",
    "Inventory Quantity high-low",
    "Inventory Quantity low-high",
    "price high-low",
    "price low-high",
  ],
  viewItemName: true,
  viewItemProportions: true,
  viewItemPrice: true,
  viewItemNeeded: true,
  viewItemSupplier: true,
  viewItemAmount: true,
  viewItemCode: true,
  viewItemProvider: true,
  viewItemLocation: true,
  viewItemCategory: true,
  viewItemType: true,
  viewItemInventoryQuantity: true,
  viewItemKitchen: true,
  viewItemTotal: true,
};
const AppContext = React.createContext();
const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  //axios
  const authFetch = axios.create({
    baseURL: "/api/v1/",
  });

  //request
  authFetch.interceptors.request.use(
    (config) => {
      config.headers.common["Authorization"] = `Bearer ${state.token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  //response
  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log(error.response);
      if (error.response.status === 401 && !error.config.preventAutoLogout) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const displayAlert = () => {
    dispatch({ type: DISPLAY_ALERT });
    clearAlert();
  };

  const clearAlert = () => {
    setTimeout(() => {
      dispatch({
        type: CLEAR_ALERT,
      });
    }, 3000);
  };

  const addUserToLocalStorage = ({
    user,
    token,
    subscriptionStatus,
    planId,
  }) => {
    if (user) {
      const {
        stripeCustomerId,
        stripeSubscriptionId,
        stripeSessionId,
        ...safeUserData
      } = user;

      localStorage.setItem("user", JSON.stringify(safeUserData));
    }
    if (token) {
      localStorage.setItem("token", token);
    }
    if (subscriptionStatus) {
      localStorage.setItem("subscriptionStatus", subscriptionStatus);
    }
    if (planId) {
      localStorage.setItem("planId", JSON.stringify(planId));
    }
  };

  const removeUserFromLocalStorage = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("user");
    localStorage.clear();
  };

  const setupUser = async ({ currentUser, endPoint, alertText }) => {
    dispatch({ type: SETUP_USER_BEGIN });
    try {
      const { data } = await axios.post(
        `/api/v1/auth/${endPoint}`,
        currentUser
      );

      const { user, token } = data;
      dispatch({
        type: SETUP_USER_SUCCESS,
        payload: { user, token, alertText },
      });
      addUserToLocalStorage({ user, token, alertText });
      localStorage.setItem("i18nextLng", user.language);
      i18n.changeLanguage(user.language);

      checkSubscriptionOnLogin(user.stripeCustomerId, token);
    } catch (error) {
      dispatch({
        type: SETUP_USER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const toggleSidebar = () => {
    dispatch({ type: TOGGLE_SIDEBAR });
  };

  const logoutUser = () => {
    const languageSetting = localStorage.getItem("i18nextLng");
    dispatch({ type: LOGOUT_USER });
    removeUserFromLocalStorage();
    if (languageSetting) {
      localStorage.setItem("i18nextLng", languageSetting);
    }
    i18n.changeLanguage(languageSetting || "en");
    //   localStorage.removeItem("googleAuthToken");
  };

  const updateUser = async (currentUser) => {
    dispatch({ type: UPDATE_USER_BEGIN });
    try {
      const { data } = await authFetch.patch("/auth/updateUser", currentUser);
      const { user, token } = data;

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: { user, token },
      });
      addUserToLocalStorage({ user, token });
      localStorage.setItem("i18nextLng", user.language);
      //    i18n.changeLanguage(user.language);
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({
          type: UPDATE_USER_ERROR,
          payload: { msg: error.response.data.msg },
        });
      }
    }
    clearAlert();
  };

  const checkSubscriptionOnLogin = async (customerId, token) => {
    if (!customerId) {
      dispatch({
        type: CHECK_SUBSCRIPTION_SUCCESS,
        payload: { subscriptionStatus: "inactive", planId: null },
      });
      return;
    }

    try {
      const response = await axios.post(
        `api/v1/stripe/check-subscription`,
        { customerId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      addUserToLocalStorage({
        subscriptionStatus: response.data.status,
        planId: response.data.planId,
      });

      dispatch({
        type: CHECK_SUBSCRIPTION_SUCCESS,
        payload: {
          subscriptionStatus: response.data.status,
          planId: response.data.planId,
        },
      });
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.log(
          "Bad request, check required parameters:",
          error.response.data
        );
        dispatch({
          type: CHECK_SUBSCRIPTION_FAILURE,
          payload: {
            error: "Subscription check failed: Missing required information",
          },
        });
      } else {
        console.error("Error checking subscription:", error);
        dispatch({
          type: SETUP_USER_ERROR,
          payload: {
            msg: error.response
              ? error.response.data.msg
              : "Error during setup",
          },
        });
      }
    }
  };

  const checkSubscription = async () => {
    try {
      const response = await authFetch.post("/stripe/check-subscription", {});
      addUserToLocalStorage({
        subscriptionStatus: response.data.status,
        planId: response.data.planId,
      });
      dispatch({
        type: CHECK_SUBSCRIPTION_SUCCESS,
        payload: {
          subscriptionStatus: response.data.status,
          planId: response.data.planId,
        },
      });
    } catch (error) {
      console.error("Error checking subscription:", error);
      dispatch({
        type: CHECK_SUBSCRIPTION_FAILURE,
        payload: {
          msg: error.response ? error.response.data.msg : "Error during setup",
        },
      });
    }
  };

  const verifyStripeSession = async (sessionId) => {
    dispatch({ type: VERIFY_SESSION_BEGIN });
    try {
      const { data } = await authFetch.post("/stripe/verify-session", {
        sessionId,
      });
      if (data) {
        await updateStripeInfo({
          customerId: data.customerId,
          subscriptionId: data.subscriptionId,
        });
        addUserToLocalStorage({
          subscriptionStatus: data.status,
          planId: data.planId,
        });
        dispatch({
          type: VERIFY_SESSION_SUCCESS,
          payload: {
            status: data.status,
            planId: data.planId,
          },
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({
          type: SUBSCRIPTION_ERROR,
          payload: { msg: error.response.data.msg },
        });
      } else {
        console.error("Failed to verify session", error);
        dispatch({
          type: SUBSCRIPTION_ERROR,
          payload: { msg: "Network or server error" },
        });
      }
    }
  };

  const updateStripeInfo = async (stripeDetails) => {
    dispatch({ type: UPDATE_STRIPE_INFO_BEGIN });
    try {
      const { data } = await authFetch.patch(
        "/stripe/update-stripe-info",
        stripeDetails
      );
      dispatch({
        type: UPDATE_STRIPE_INFO_SUCCESS,
        payload: {
          stripeCustomerId: data.user.stripeCustomerId,
          stripeSubscriptionId: data.user.stripeSubscriptionId,
        },
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({
          type: UPDATE_STRIPE_INFO_ERROR,
          payload: { msg: error.response.data.msg },
        });
      } else {
        console.error("Failed to update Stripe information", error);
        dispatch({
          type: UPDATE_STRIPE_INFO_ERROR,
          payload: { msg: "Network or server error" },
        });
      }
    }
    clearAlert();
  };

  const createCheckoutSession = async (planId) => {
    dispatch({ type: GET_SUBSCRIPTION_BEGIN });
    try {
      const { data } = await authFetch.post("/stripe/create-checkout-session", {
        lookup_key: planId,
      });
      window.location.href = data.url;
      dispatch({
        type: UPDATE_SUBSCRIPTION_SUCCESS,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({
          type: SUBSCRIPTION_ERROR,
          payload: { msg: error.response.data.msg },
        });
      } else {
        console.error("Failed to update subscription", error);
      }
    }
  };

  const accessStripePortal = async () => {
    dispatch({ type: GET_SUBSCRIPTION_BEGIN });
    try {
      const { data } = await authFetch.post("/stripe/create-portal-session");
      window.location.href = data.url;
      dispatch({ type: GET_SUBSCRIPTION_SUCCESS });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({
          type: SUBSCRIPTION_ERROR,
          payload: { msg: error.response.data.msg },
        });
      } else {
        console.error("Failed to access Stripe portal", error);
      }
    }
  };

  const handleChange = ({ name, value }) => {
    dispatch({ type: HANDLE_CHANGE, payload: { name, value } });
  };

  const clearValues = () => {
    dispatch({ type: CLEAR_VALUES });
  };
  const checkCached = (key, expiresInMinutes = 60) => {
    const cached = localStorage.getItem(key);
    if (!cached) return null;

    const { data, timestamp } = JSON.parse(cached);
    const isExpired =
      (new Date().getTime() - timestamp) / 60000 > expiresInMinutes;

    if (isExpired) return null;

    return data;
  };

  const filterDefaultProportions = (proportions, itemTypes) => {
    const defaultValues = {
      price: 0,
      itemProportions: "",
      itemProportionsAmount: 0,
      itemProportionsType: "",
      itemAmount: 0,
    };

    return proportions
      .filter((proportion) => {
        let parsedItemAmount = parseInt(proportion.itemAmount, 10) || 0;
        let parsedPrice = parseFloat(proportion.price) || 0;
        let itemProportionsTypeId =
          itemTypes.find(
            (type) => type.typeName === proportion.itemProportionsType
          )?._id || "";

        return !(
          parsedPrice === defaultValues.price &&
          proportion.itemProportions === defaultValues.itemProportions &&
          proportion.itemProportionsAmount ===
            defaultValues.itemProportionsAmount &&
          itemProportionsTypeId === defaultValues.itemProportionsType &&
          parsedItemAmount === defaultValues.itemAmount
        );
      })
      .map((proportion) => {
        const parsedItemAmount = parseInt(proportion.itemAmount, 10) || 0;
        const parsedPrice = parseFloat(proportion.price) || 0;
        const itemTypeObject = itemTypes.find(
          (type) => type.typeName === proportion.itemType
        );
        const itemProportionsTypeObject = itemTypes.find(
          (type) => type.typeName === proportion.itemProportionsType
        );

        return {
          ...proportion,
          itemType: itemTypeObject ? itemTypeObject._id : undefined,
          itemProportionsType: itemProportionsTypeObject
            ? itemProportionsTypeObject._id
            : undefined,
          itemAmount: parsedItemAmount,
          price: parsedPrice,
        };
      });
  };

  const createItem = async () => {
    dispatch({ type: CREATE_ITEM_BEGIN });
    try {
      const {
        itemName,
        itemProportions,
        itemProportionsType,
        itemProportionsAmount,
        itemProportionsAmountType,
        price,
        itemCode,
        itemNeeded,
        itemNeededType,
        itemLeftToOrder,
        itemLeftToOrderType,
        itemAmount,
        itemAmountType,
        provider,
        itemSupplier,
        itemCategory,
        itemLocation,
        itemType,
        itemKitchen,
        itemLocations,
        itemCategories,
        itemTypes,
        itemKitchens,
        providers,
        itemCustomProportions,
      } = state;

      const providerId = providers.find(
        (p) => p.providerName === provider
      )?._id;
      const locationId = itemLocations.find(
        (loc) => loc.locationName === itemLocation
      )?._id;
      const categoryId = itemCategories.find(
        (cat) => cat.categoryName === itemCategory
      )?._id;
      const typeId = itemTypes.find((type) => type.typeName === itemType)?._id;
      const kitchenId = itemKitchens.find(
        (kitchen) => kitchen.kitchenName === itemKitchen
      )?._id;
      const itemProportionsTypeId = itemTypes.find(
        (type) => type.typeName === itemProportionsType
      )?._id;
      const itemNeededTypeId = itemTypes.find(
        (type) => type.typeName === itemNeededType
      )?._id;
      const itemLeftToOrderTypeId = itemTypes.find(
        (type) => type.typeName === itemLeftToOrderType
      )?._id;

      const normalizedItemAmount = itemAmount === "" ? 0 : itemAmount;

      const filteredCustomProportions = filterDefaultProportions(
        itemCustomProportions,
        itemTypes
      );

      await authFetch.post("/items", {
        itemName,
        itemProportions,
        itemProportionsType: itemProportionsTypeId,
        itemProportionsAmount,
        itemProportionsAmountType,
        price,
        itemCode,
        itemNeeded,
        itemNeededType: itemNeededTypeId,
        itemLeftToOrder,
        itemLeftToOrderType: itemLeftToOrderTypeId,
        itemAmount: normalizedItemAmount,
        itemAmountType,
        itemSupplier,
        provider: providerId,
        itemLocation: locationId,
        itemCategory: categoryId,
        itemType: typeId,
        itemKitchen: kitchenId,
        customProportions: filteredCustomProportions,
      });
      dispatch({
        type: CREATE_ITEM_SUCCESS,
      });
      getItems();

      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_ITEM_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getItems = async () => {
    getProviders();
    getItemLocations();
    getItemCategories();
    getItemTypes();
    getItemKitchens();

    dispatch({ type: GET_ITEMS_BEGIN });
    try {
      const { data } = await authFetch("/items");
      const { items } = data;
      dispatch({
        type: GET_ITEMS_SUCCESS,
        payload: { items },
      });
    } catch (error) {
      console.log(error.response);
      logoutUser();
    }
    clearAlert();
  };

  // const getItemAlternatives = async () => {
  //   const { searchAlternatives } = state;

  //   let url = `/items/alternative?search=${searchAlternatives}`;
  //   dispatch({ type: GET_ITEM_ALTERNATIVES_BEGIN });

  //   try {
  //     const { data } = await authFetch(url);
  //     const { items } = data;

  //     dispatch({
  //       type: GET_ITEM_ALTERNATIVES_SUCCESS,
  //       payload: { items },
  //     });
  //   } catch (error) {
  //     console.log(error.response);
  //     logoutUser();
  //   }
  //   clearAlert();
  // };

  const addCustomProportions = () => {
    dispatch({ type: ADD_CUSTOM_PROPORTION });
  };

  const handleChangeCustomProportion = (index, name, value) => {
    dispatch({
      type: HANDLE_CUSTOM_PROPORTION_CHANGE,
      payload: { index, name, value },
    });
  };
  const removeCustomProportion = (index) => {
    dispatch({
      type: REMOVE_CUSTOM_PROPORTION,
      payload: index,
    });
  };

  const changeItemProportions = async (itemId) => {
    dispatch({ type: CHANGE_PROPORTIONS_BEGIN });
    try {
      await authFetch.patch(`/items/${itemId}/customProportion`);
      getItems();

      dispatch({ type: CHANGE_PROPORTIONS_SUCCESS });
    } catch (error) {
      console.error("Error changing proportions:", error);
      dispatch({
        type: CHANGE_PROPORTIONS_ERROR,
        payload: {
          msg: error.response
            ? error.response.data.msg
            : "Failed to change item proportions",
        },
      });
    } finally {
      clearAlert();
    }
  };

  const toggleAlternatives = (itemId) => {
    //  getItemAlternatives();
    //  window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch({ type: TOGGLE_ALTERNATIVES, payload: itemId });
  };

  const addItemAlternative = (item) => {
    //Added this code to fix a weird case where when adding an alternative, it would add it both in the selectedAlternatives and in the item.selectedAlternative
    const itemCopy = { ...item };
    dispatch({
      type: ADD_ITEM_ALTERNATIVE_BEGIN,
      payload: { item: itemCopy },
    });
  };

  const removeItemAlternative = (item) => {
    dispatch({
      type: REMOVE_ITEM_ALTERNATIVE,
      payload: { item },
    });
    //  getItemAlternatives();
  };

  const setAddItem = () => {
    dispatch({ type: SET_ADD_ITEM });
  };

  const setEditItem = (id) => {
    dispatch({ type: SET_EDIT_ITEM, payload: { id } });
  };

  const toggleSubItemDetails = () => {
    dispatch({ type: TOGGLE_SUBITEM_DETAILS });
  };

  const editItem = async () => {
    dispatch({ type: EDIT_ITEM_BEGIN });
    try {
      const {
        itemName,
        itemProportions,
        itemProportionsType,
        itemProportionsAmount,
        itemProportionsAmountType,
        price,
        itemCode,
        itemNeeded,
        itemNeededType,
        itemLeftToOrder,
        itemLeftToOrderType,
        itemAmount,
        itemAmountType,
        provider,
        itemSupplier,
        itemCategory,
        itemLocation,
        itemType,
        itemKitchen,
        editItemId,
        selectedAlternatives,
        itemLocations,
        itemCategories,
        itemTypes,
        itemKitchens,
        providers,
        itemCustomProportions,
      } = state;

      const providerId = providers.find(
        (p) => p.providerName === provider
      )?._id;
      const locationId = itemLocations.find(
        (loc) => loc.locationName === itemLocation
      )?._id;
      const categoryId = itemCategories.find(
        (cat) => cat.categoryName === itemCategory
      )?._id;
      const typeId = itemTypes.find((type) => type.typeName === itemType)?._id;
      const kitchenId = itemKitchens.find(
        (kitchen) => kitchen.kitchenName === itemKitchen
      )?._id;

      const itemNeededTypeId = itemTypes.find(
        (type) => type.typeName === itemNeededType
      )?._id;
      const itemLeftToOrderTypeId = itemTypes.find(
        (type) => type.typeName === itemLeftToOrderType
      )?._id;

      const itemProportionsTypeId = itemTypes.find(
        (type) => type.typeName === itemProportionsType
      )?._id;

      const filteredCustomProportions = filterDefaultProportions(
        itemCustomProportions,
        itemTypes
      );

      await authFetch.patch(`/items/${editItemId}`, {
        itemName,
        itemProportions,
        itemProportionsType: itemProportionsTypeId,
        itemProportionsAmount,
        itemProportionsAmountType,
        price,
        itemCode,
        itemNeeded,
        itemNeededType: itemNeededTypeId,
        itemLeftToOrder,
        itemLeftToOrderType: itemLeftToOrderTypeId,
        itemAmount,
        itemAmountType,
        itemSupplier,
        provider: providerId,
        itemLocation: locationId,
        itemCategory: categoryId,
        itemType: typeId,
        itemKitchen: kitchenId,
        customProportions: filteredCustomProportions,
        selectedAlternatives,
      });
      getItems();
      dispatch({
        type: EDIT_ITEM_SUCCESS,
      });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: EDIT_ITEM_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const deleteItem = async (itemId) => {
    dispatch({ type: DELETE_ITEM_BEGIN });
    try {
      await authFetch.delete(`/items/${itemId}`);
      dispatch({ type: DELETE_ITEM_SUCCESS });
      getTrashedItems("item");
      getItems();
      clearValues();
    } catch (error) {
      logoutUser();
    }
  };

  const softDeleteItem = async (model, itemId) => {
    dispatch({ type: SOFT_DELETE_ITEM_BEGIN });
    try {
      await authFetch.patch(`/trash/${model}/${itemId}/soft-delete`);
      if (model === "item") {
        getItems();
      } else if (model === "order") {
        getOrders();
      }

      dispatch({ type: CLEAR_VALUES });
      dispatch({ type: SOFT_DELETE_ITEM_SUCCESS });
    } catch (error) {
      console.log(error.response);
    }
  };

  const restoreItem = async (model, itemId) => {
    dispatch({ type: RESTORE_ITEM_BEGIN });
    try {
      await authFetch.patch(`/trash/${model}/${itemId}/restore`);
      getTrashedItems(model);
      if (model === "order") {
        getOrders();
      } else if (model === "item") {
        getItems();
      }
      dispatch({ type: RESTORE_ITEM_SUCCESS });
    } catch (error) {
      console.log(error.response);
      logoutUser();
    }
    clearAlert();
  };

  const getTrashedItems = async (model) => {
    dispatch({ type: GET_TRASHED_ITEMS_BEGIN });
    try {
      const { data } = await authFetch(`/trash/${model}/trashed`);
      const { trashedModels } = data;
      dispatch({
        type: GET_TRASHED_ITEMS_SUCCESS,
        payload: {
          trashedModels,
          model,
        },
      });
    } catch (error) {
      console.log(error.response);
      //logoutUser();
    }
  };

  const toggleResetQuantities = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch({ type: TOGGLE_RESET_QUANTITIES });
  };

  const toggleAutoOrder = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch({ type: TOGGLE_AUTO_ORDER });
  };

  const updateItemQuantity = async (item, newQuantity, forceOnline = false) => {
    dispatch({
      type: UPDATE_ITEM_QUANTITY_BEGIN,
      payload: { id: item._id },
    });

    const newItemQuantity = parseInt(newQuantity, 10);

    const newItems = [...state.items];
    const updatedItem = newItems.find((i) => i._id === item._id);
    const originalQuantity = updatedItem.itemQuantity;
    updatedItem.itemQuantity = newItemQuantity;

    dispatch({
      type: UPDATE_ITEM_QUANTITY_SUCCESS,
      payload: { id: item._id, newItemQuantity },
    });

    const isOnline = forceOnline || state.connectionStatus === "Online";

    if (isOnline) {
      try {
        await authFetch.patch(`/items/${item._id}/quantities`, {
          itemQuantity: newItemQuantity,
        });
      } catch (error) {
        if (error.response && error.response.status === 401) return;
        dispatch({
          type: UPDATE_ITEM_QUANTITY_ERROR,
          payload: { msg: error.response?.data.msg },
        });

        updatedItem.itemQuantity = originalQuantity;
        dispatch({
          type: UPDATE_ITEM_QUANTITY_SUCCESS,
          payload: { id: item._id, newItemQuantity: originalQuantity },
        });
      }
    } else {
      const pendingChanges =
        JSON.parse(localStorage.getItem("pendingChanges")) || [];
      const existingChangeIndex = pendingChanges.findIndex(
        (change) =>
          change.item._id === item._id && change.type === "itemQuantity"
      );
      if (existingChangeIndex !== -1) {
        pendingChanges[existingChangeIndex] = {
          item,
          newQuantity,
          type: "itemQuantity",
        };
      } else {
        pendingChanges.push({
          item,
          newQuantity,
          type: "itemQuantity",
        });
      }
      localStorage.setItem("pendingChanges", JSON.stringify(pendingChanges));
      dispatch({
        type: ADD_PENDING_CHANGE,
        payload: { item, newQuantity, type: "itemQuantity" },
      });
    }

    clearAlert();
    dispatch({ type: CLEAR_VALUES });
  };

  const updateItemInventoryQuantity = async (
    item,
    newQuantity,
    forceOnline = false
  ) => {
    dispatch({
      type: UPDATE_ITEM_INVENTORY_QUANTITY_BEGIN,
      payload: { id: item._id },
    });

    const newItemInventoryQuantity =
      Math.round(parseFloat(newQuantity) * 100) / 100;

    const newItems = [...state.items];
    const updatedItem = newItems.find((i) => i._id === item._id);
    const originalQuantity = updatedItem.itemInventoryQuantity;
    updatedItem.itemInventoryQuantity = newItemInventoryQuantity;

    dispatch({
      type: UPDATE_ITEM_INVENTORY_QUANTITY_SUCCESS,
      payload: { id: item._id, newItemInventoryQuantity },
    });

    const isOnline = forceOnline || state.connectionStatus === "Online";

    if (isOnline) {
      try {
        await authFetch.patch(`/items/${item._id}/quantities`, {
          itemInventoryQuantity: newItemInventoryQuantity,
        });
      } catch (error) {
        if (error.response && error.response.status === 401) return;
        dispatch({
          type: UPDATE_ITEM_INVENTORY_QUANTITY_ERROR,
          payload: { msg: error.response?.data.msg },
        });

        updatedItem.itemInventoryQuantity = originalQuantity;
        dispatch({
          type: UPDATE_ITEM_INVENTORY_QUANTITY_SUCCESS,
          payload: { id: item._id, newItemInventoryQuantity: originalQuantity },
        });
      }
    } else {
      const pendingChanges =
        JSON.parse(localStorage.getItem("pendingChanges")) || [];
      const existingChangeIndex = pendingChanges.findIndex(
        (change) =>
          change.item._id === item._id &&
          change.type === "itemInventoryQuantity"
      );
      if (existingChangeIndex !== -1) {
        pendingChanges[existingChangeIndex] = {
          item,
          newQuantity,
          type: "itemInventoryQuantity",
        };
      } else {
        pendingChanges.push({
          item,
          newQuantity,
          type: "itemInventoryQuantity",
        });
      }
      localStorage.setItem("pendingChanges", JSON.stringify(pendingChanges));
      dispatch({
        type: ADD_PENDING_CHANGE,
        payload: { item, newQuantity, type: "itemInventoryQuantity" },
      });
    }

    clearAlert();
    dispatch({ type: CLEAR_VALUES });
  };

  const syncData = async () => {
    const pendingChanges =
      JSON.parse(localStorage.getItem("pendingChanges")) || [];

    for (const change of pendingChanges) {
      try {
        if (change.type === "itemQuantity") {
          await updateItemQuantity(change.item, change.newQuantity, true);
        } else if (change.type === "itemInventoryQuantity") {
          await updateItemInventoryQuantity(
            change.item,
            change.newQuantity,
            true
          );
        }
      } catch (error) {
        console.error("Failed to sync data:", error);
      }
    }

    localStorage.removeItem("pendingChanges");
    dispatch({ type: CLEAR_PENDING_CHANGES });
  };

  const updateConnectionStatus = (status) => {
    dispatch({ type: SET_CONNECTION_STATUS, payload: { status } });
    if (status === "Online") {
      syncData();
    }
  };

  const updateItemStatus = async (item, status) => {
    try {
      const { isReceived, isBackordered, isMissing, isUnity } = item;
      if (status === "received") {
        await authFetch.patch(`/items/${item._id}`, {
          isReceived: !isReceived,
        });
      } else if (status === "backordered") {
        await authFetch.patch(`/items/${item._id}`, {
          isBackordered: !isBackordered,
        });
      } else if (status === "missing") {
        await authFetch.patch(`/items/${item._id}`, {
          isMissing: !isMissing,
        });
      } else if (status === "unity") {
        await authFetch.patch(`/items/${item._id}`, {
          isUnity: !isUnity,
        });
      }
      //  else if (status === "alternative") {
      //   await authFetch.patch(`/items/${item._id}`, {
      //     isAlternative: !isAlternative,
      //   });
      // }
      getItems();
    } catch (error) {
      console.log(error);
    }
  };

  const updateItemPrices = async (itemsToUpdate) => {
    dispatch({ type: UPDATE_PRICES_BEGIN });

    try {
      await authFetch.patch("/items/price-update", {
        items: itemsToUpdate,
      });

      getItems();
      dispatch({ type: UPDATE_PRICES_SUCCESS });
    } catch (error) {
      if (error.response && error.response.status === 401) return;
      dispatch({
        type: UPDATE_PRICES_ERROR,
        payload: {
          msg: error.response ? error.response.data.msg : error.message,
        },
      });
    }
    clearAlert();
  };

  const toggleInventoryType = async (editItemId) => {
    dispatch({ type: EDIT_ITEM_BEGIN });

    try {
      await authFetch.patch(`/items/${editItemId}/inventory-type`);

      getItems();
      dispatch({ type: EDIT_ITEM_SUCCESS });
    } catch (error) {
      if (error.response && error.response.status === 401) return;
      dispatch({
        type: EDIT_ITEM_ERROR,
        payload: {
          msg: error.response ? error.response.data.msg : error.message,
        },
      });
    }
    clearAlert();
  };

  const createProvider = async () => {
    dispatch({ type: CREATE_PROVIDER_BEGIN });
    try {
      const {
        providerName,
        number,
        notes,
        website,
        deliveryDays,
        deliveryTime,
        holiday,
        email,
        providerId,
        passwordInfo,
        minimumOrder,
        orderDate,
        representName,
      } = state;

      await authFetch.post("/providers", {
        providerName,
        number,
        notes,
        website,
        deliveryDay: deliveryDays,
        deliveryTime,
        holiday,
        email,
        providerId,
        passwordInfo,
        minimumOrder,
        orderDate,
        representName,
      });
      getProviders(true);
      dispatch({
        type: CREATE_PROVIDER_SUCCESS,
      });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_PROVIDER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getProviders = async (forceUpdate = false) => {
    dispatch({ type: GET_PROVIDERS_BEGIN });

    const cachedData = forceUpdate ? null : checkCached("providers");

    if (!cachedData) {
      try {
        const { data } = await authFetch("/providers");
        const { providers, totalProviders } = data;
        const dataToCache = {
          data: { providers, totalProviders },
          timestamp: new Date().getTime(),
        };
        localStorage.setItem("providers", JSON.stringify(dataToCache));
        dispatch({
          type: GET_PROVIDERS_SUCCESS,
          payload: { providers, totalProviders },
        });
      } catch (error) {
        logoutUser();
      }
    } else {
      dispatch({
        type: GET_PROVIDERS_SUCCESS,
        payload: {
          providers: cachedData.providers,
          totalProviders: cachedData.totalProviders,
        },
      });
    }

    clearAlert();
  };

  const setAddProvider = () => {
    dispatch({ type: SET_ADD_PROVIDER });
  };

  const setEditProvider = (id) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch({ type: SET_EDIT_PROVIDER, payload: { id } });
  };

  const setDeliveryDay = (day) => {
    dispatch({ type: SET_DELIVERY_DAY, payload: day });
  };

  const editProvider = async () => {
    dispatch({ type: EDIT_PROVIDER_BEGIN });
    try {
      const {
        providerName,
        number,
        notes,
        editProviderId,
        website,
        deliveryDays,
        deliveryTime,
        holiday,
        email,
        providerId,
        passwordInfo,
        minimumOrder,
        orderDate,
        representName,
      } = state;
      await authFetch.patch(`/providers/${editProviderId}`, {
        providerName,
        number,
        notes,
        website,
        deliveryDay: deliveryDays,
        deliveryTime,
        holiday,
        email,
        providerId,
        passwordInfo,
        minimumOrder,
        orderDate,
        representName,
      });
      getProviders(true);
      dispatch({
        type: EDIT_PROVIDER_SUCCESS,
      });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: EDIT_PROVIDER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const deleteProvider = async (providerId) => {
    dispatch({ type: DELETE_PROVIDER_BEGIN });
    try {
      await authFetch.delete(`/providers/${providerId}`);
      getProviders(true);
      clearValues();
    } catch (error) {
      logoutUser();
    }
  };

  const createItemLocation = async () => {
    dispatch({ type: CREATE_ITEM_LOCATION_BEGIN });
    try {
      const { locationName } = state;

      await authFetch.post("/item-location", {
        locationName,
      });
      getItemLocations(true);
      dispatch({
        type: CREATE_ITEM_LOCATION_SUCCESS,
      });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_ITEM_LOCATION_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getItemLocations = async (forceUpdate = false) => {
    dispatch({ type: GET_ITEM_LOCATIONS_BEGIN });

    const cachedData = forceUpdate ? null : checkCached("itemLocations");

    if (!cachedData) {
      try {
        const { data } = await authFetch("/item-location");
        const { itemLocations, totalLocations } = data;
        const dataToCache = {
          data: { itemLocations, totalLocations },
          timestamp: new Date().getTime(),
        };
        localStorage.setItem("itemLocations", JSON.stringify(dataToCache));
        dispatch({
          type: GET_ITEM_LOCATIONS_SUCCESS,
          payload: { itemLocations, totalLocations },
        });
      } catch (error) {
        logoutUser();
      }
    } else {
      dispatch({
        type: GET_ITEM_LOCATIONS_SUCCESS,
        payload: {
          itemLocations: cachedData.itemLocations,
          totalLocations: cachedData.totalLocations,
        },
      });
    }

    clearAlert();
  };

  const deleteItemLocation = async (itemLocationId) => {
    dispatch({ type: DELETE_ITEM_LOCATION_BEGIN });
    try {
      await authFetch.delete(`/item-location/${itemLocationId}`);
      getItemLocations(true);
      clearValues();
    } catch (error) {
      logoutUser();
    }
  };

  const setAddItemLocation = () => {
    dispatch({ type: SET_ADD_ITEM_LOCATION });
  };

  const setEditItemLocation = (id) => {
    dispatch({ type: SET_EDIT_ITEM_LOCATION, payload: { id } });
  };

  const editItemLocation = async () => {
    dispatch({ type: EDIT_ITEM_LOCATION_BEGIN });
    try {
      const { locationName, editItemLocationId } = state;
      await authFetch.patch(`/item-location/${editItemLocationId}`, {
        locationName,
      });
      getItemLocations(true);
      dispatch({
        type: EDIT_ITEM_LOCATION_SUCCESS,
      });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: EDIT_ITEM_LOCATION_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const createItemCategory = async () => {
    dispatch({ type: CREATE_ITEM_CATEGORY_BEGIN });
    try {
      const { categoryName } = state;

      await authFetch.post("/item-category", {
        categoryName,
      });
      getItemCategories(true);
      dispatch({
        type: CREATE_ITEM_CATEGORY_SUCCESS,
      });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_ITEM_CATEGORY_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getItemCategories = async (forceUpdate = false) => {
    dispatch({ type: GET_ITEM_CATEGORIES_BEGIN });

    const cachedData = forceUpdate ? null : checkCached("itemCategories");

    if (!cachedData) {
      try {
        const { data } = await authFetch("/item-category");
        const { itemCategories, totalCategories } = data;
        const dataToCache = {
          data: { itemCategories, totalCategories },
          timestamp: new Date().getTime(),
        };
        localStorage.setItem("itemCategories", JSON.stringify(dataToCache));
        dispatch({
          type: GET_ITEM_CATEGORIES_SUCCESS,
          payload: { itemCategories, totalCategories },
        });
      } catch (error) {
        logoutUser();
      }
    } else {
      dispatch({
        type: GET_ITEM_CATEGORIES_SUCCESS,
        payload: {
          itemCategories: cachedData.itemCategories,
          totalCategories: cachedData.totalCategories,
        },
      });
    }

    clearAlert();
  };

  const deleteItemCategory = async (itemCategoryId) => {
    dispatch({ type: DELETE_ITEM_CATEGORY_BEGIN });
    try {
      await authFetch.delete(`/item-category/${itemCategoryId}`);
      getItemCategories(true);
      clearValues();
    } catch (error) {
      logoutUser();
    }
  };

  const setAddItemCategory = () => {
    dispatch({ type: SET_ADD_ITEM_CATEGORY });
  };

  const setEditItemCategory = (id) => {
    dispatch({ type: SET_EDIT_ITEM_CATEGORY, payload: { id } });
  };

  const editItemCategory = async () => {
    dispatch({ type: EDIT_ITEM_CATEGORY_BEGIN });
    try {
      const { categoryName, editItemCategoryId } = state;
      await authFetch.patch(`/item-category/${editItemCategoryId}`, {
        categoryName,
      });
      getItemCategories(true);
      dispatch({
        type: EDIT_ITEM_CATEGORY_SUCCESS,
      });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: EDIT_ITEM_CATEGORY_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const createItemType = async () => {
    dispatch({ type: CREATE_ITEM_TYPE_BEGIN });
    try {
      const { typeName } = state;

      await authFetch.post("/item-type", {
        typeName,
      });
      getItemTypes(true);
      dispatch({
        type: CREATE_ITEM_TYPE_SUCCESS,
      });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_ITEM_TYPE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getItemTypes = async (forceUpdate = false) => {
    dispatch({ type: GET_ITEM_TYPES_BEGIN });

    const cachedData = forceUpdate ? null : checkCached("itemTypes");

    if (!cachedData) {
      try {
        const { data } = await authFetch("/item-type");
        const { itemTypes, totalTypes } = data;
        const dataToCache = {
          data: { itemTypes, totalTypes },
          timestamp: new Date().getTime(),
        };
        localStorage.setItem("itemTypes", JSON.stringify(dataToCache));
        dispatch({
          type: GET_ITEM_TYPES_SUCCESS,
          payload: { itemTypes, totalTypes },
        });
      } catch (error) {
        logoutUser();
      }
    } else {
      dispatch({
        type: GET_ITEM_TYPES_SUCCESS,
        payload: {
          itemTypes: cachedData.itemTypes,
          totalTypes: cachedData.totalTypes,
        },
      });
    }

    clearAlert();
  };

  const deleteItemType = async (itemTypeId) => {
    dispatch({ type: DELETE_ITEM_TYPE_BEGIN });
    try {
      await authFetch.delete(`/item-type/${itemTypeId}`);
      getItemTypes(true);
      clearValues();
    } catch (error) {
      logoutUser();
    }
  };

  const setAddItemType = () => {
    dispatch({ type: SET_ADD_ITEM_TYPE });
  };

  const setEditItemType = (id) => {
    dispatch({ type: SET_EDIT_ITEM_TYPE, payload: { id } });
  };

  const editItemType = async () => {
    dispatch({ type: EDIT_ITEM_TYPE_BEGIN });
    try {
      const { typeName, editItemTypeId } = state;
      await authFetch.patch(`/item-type/${editItemTypeId}`, {
        typeName,
      });
      getItemTypes(true);
      dispatch({
        type: EDIT_ITEM_TYPE_SUCCESS,
      });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: EDIT_ITEM_TYPE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const createItemKitchen = async () => {
    dispatch({ type: CREATE_ITEM_KITCHEN_BEGIN });
    try {
      const { kitchenName } = state;

      await authFetch.post("/item-kitchen", {
        kitchenName,
      });
      getItemKitchens(true);
      dispatch({
        type: CREATE_ITEM_KITCHEN_SUCCESS,
      });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_ITEM_KITCHEN_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getItemKitchens = async (forceUpdate = false) => {
    dispatch({ type: GET_ITEM_KITCHENS_BEGIN });

    const cachedData = forceUpdate ? null : checkCached("itemKitchens");

    if (!cachedData) {
      try {
        const { data } = await authFetch("/item-kitchen");
        const { itemKitchens, totalKitchens } = data;
        const dataToCache = {
          data: { itemKitchens, totalKitchens },
          timestamp: new Date().getTime(),
        };
        localStorage.setItem("itemKitchens", JSON.stringify(dataToCache));
        dispatch({
          type: GET_ITEM_KITCHENS_SUCCESS,
          payload: { itemKitchens, totalKitchens },
        });
      } catch (error) {
        logoutUser();
      }
    } else {
      dispatch({
        type: GET_ITEM_KITCHENS_SUCCESS,
        payload: {
          itemKitchens: cachedData.itemKitchens,
          totalKitchens: cachedData.totalKitchens,
        },
      });
    }

    clearAlert();
  };

  const deleteItemKitchen = async (itemKitchenId) => {
    dispatch({ type: DELETE_ITEM_KITCHEN_BEGIN });
    try {
      await authFetch.delete(`/item-kitchen/${itemKitchenId}`);
      getItemKitchens(true);
      clearValues();
    } catch (error) {
      logoutUser();
    }
  };

  const setAddItemKitchen = () => {
    dispatch({ type: SET_ADD_ITEM_KITCHEN });
  };

  const setEditItemKitchen = (id) => {
    dispatch({ type: SET_EDIT_ITEM_KITCHEN, payload: { id } });
  };

  const editItemKitchen = async () => {
    dispatch({ type: EDIT_ITEM_KITCHEN_BEGIN });
    try {
      const { kitchenName, editItemKitchenId } = state;
      await authFetch.patch(`/item-kitchen/${editItemKitchenId}`, {
        kitchenName,
      });
      getItemKitchens(true);
      dispatch({
        type: EDIT_ITEM_KITCHEN_SUCCESS,
      });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: EDIT_ITEM_KITCHEN_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const toggleCondensed = () => {
    dispatch({ type: TOGGLE_CONDENSED });
  };

  const changePage = (page) => {
    dispatch({ type: CHANGE_PAGE, payload: { page } });
  };
  const changeTrashedPage = (page) => {
    dispatch({ type: CHANGE_TRASHED_PAGE, payload: { page } });
  };

  const createMenu = async () => {
    dispatch({ type: CREATE_MENU_BEGIN });
    try {
      const { menuName, menuDescription } = state;

      await authFetch.post("/menus", {
        menuName,
        menuDescription,
      });
      getMenus();
      dispatch({
        type: CREATE_MENU_SUCCESS,
      });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_MENU_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getMenus = async () => {
    dispatch({ type: GET_MENUS_BEGIN });
    try {
      const { data } = await authFetch("/menus");
      const { menus, totalMenus } = data;
      dispatch({
        type: GET_MENUS_SUCCESS,
        payload: { menus, totalMenus },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const setAddMenu = () => {
    dispatch({ type: SET_ADD_MENU });
  };
  const setEditMenu = (id) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch({ type: SET_EDIT_MENU, payload: { id } });
  };

  const editMenu = async () => {
    dispatch({ type: EDIT_MENU_BEGIN });
    try {
      const { menuName, menuDescription, editMenuId } = state;
      await authFetch.patch(`/menus/${editMenuId}`, {
        menuName,
        menuDescription,
      });
      getMenus();
      dispatch({
        type: EDIT_MENU_SUCCESS,
      });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: EDIT_MENU_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const deleteMenu = async (menuId) => {
    dispatch({ type: DELETE_MENU_BEGIN });
    try {
      await authFetch.delete(`/menus/${menuId}`);
      getMenus();
    } catch (error) {
      logoutUser();
    }
  };
  const setMenuItems = (menuId) => {
    dispatch({ type: SET_MENU_ITEMS, payload: { menuId } });
  };

  const createMenuItem = async (menuId) => {
    dispatch({ type: CREATE_MENU_ITEM_BEGIN });
    try {
      const {
        menuItemName,
        menuItemPrice,
        menuItemNotes,
        menuItemImage,
        menuItemOrders,
        menuItemError,
        menuItemIngredients,
      } = state;

      await authFetch.post("/menus/menu-item", {
        menuItemName,
        menuItemPrice,
        menuItemNotes,
        menuItemImage,
        menuItemOrders,
        menuItemError,
        menuItemIngredients,
        menu: menuId,
      });
      getMenuItems(menuId);
      dispatch({
        type: CREATE_MENU_ITEM_SUCCESS,
      });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_MENU_ITEM_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getMenuItems = async (menuId) => {
    dispatch({ type: GET_MENU_ITEMS_BEGIN });
    try {
      const { data } = await authFetch(
        `/menus/menu-item${menuId ? `?menuId=${menuId}` : ""}`
      );
      const { menuItems, totalMenuItems } = data;
      dispatch({
        type: GET_MENU_ITEMS_SUCCESS,
        payload: { menuItems, totalMenuItems },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const setAddMenuItem = () => {
    dispatch({ type: SET_ADD_MENU_ITEM });
  };
  const setEditMenuItem = (id) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch({ type: SET_EDIT_MENU_ITEM, payload: { id } });
  };

  const editMenuItem = async (menuId) => {
    dispatch({ type: EDIT_MENU_ITEM_BEGIN });
    try {
      const {
        menuItemName,
        menuItemPrice,
        menuItemNotes,
        menuItemImage,
        menuItemOrders,
        menuItemError,
        menuItemIngredients,
        editMenuItemId,
      } = state;
      await authFetch.patch(`/menus/menu-item/${editMenuItemId}`, {
        menuItemName,
        menuItemPrice,
        menuItemNotes,
        menuItemImage,
        menuItemOrders,
        menuItemError,
        menuItemIngredients,
      });
      getMenuItems(menuId);
      dispatch({
        type: EDIT_MENU_ITEM_SUCCESS,
      });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: EDIT_MENU_ITEM_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const deleteMenuItem = async (menuItemId, menuId) => {
    dispatch({ type: DELETE_MENU_ITEM_BEGIN });
    try {
      await authFetch.delete(`/menus/menu-item/${menuItemId}`);
      clearValues();
      getMenuItems(menuId);
    } catch (error) {
      logoutUser();
    }
  };

  const addIngredientField = () => {
    dispatch({ type: ADD_INGREDIENT_FIELD });
  };

  const removeIngredientField = (index) => {
    dispatch({ type: REMOVE_INGREDIENT_FIELD, payload: { index } });
  };

  const updateIngredientField = (index, updatedField) => {
    dispatch({
      type: UPDATE_INGREDIENT_FIELD,
      payload: { index, updatedField },
    });
  };

  const searchMenuIngredients = async () => {
    const { searchIngredients } = state;

    if (!searchIngredients.trim()) {
      dispatch({
        type: CLEAR_INGREDIENT_SEARCH_RESULTS,
      });
      return;
    }

    let url = `/items/alternative?search=${searchIngredients}`;

    dispatch({ type: SEARCH_INGREDIENT_BEGIN });
    try {
      const { data } = await authFetch(url);
      const { items, totalItems, numOfPages } = data;
      dispatch({
        type: SEARCH_INGREDIENT_SUCCESS,
        payload: { items, totalItems, numOfPages },
      });
    } catch (error) {
      console.log(error.response);
      logoutUser();
    }

    clearAlert();
  };

  const setEditView = () => {
    dispatch({ type: SET_EDIT_VIEW });
  };

  const editView = (status) => {
    dispatch({ type: EDIT_VIEW, payload: status });
  };
  const resetView = () => {
    dispatch({ type: RESET_VIEW });
  };

  const resetItemQuantities = async (
    filteredItems,
    resetItemQuantity,
    resetInventoryQuantity
  ) => {
    dispatch({ type: RESET_ITEMS_QUANTITY_BEGIN });
    try {
      const itemIds = filteredItems.map((item) => item._id);
      await authFetch.patch("/items/reset-quantities", {
        itemIds,
        resetItemQuantity,
        resetInventoryQuantity,
      });
      dispatch({ type: RESET_ITEMS_QUANTITY_SUCCESS });
      getItems();
      getItemBackup();
      clearValues();
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: RESET_ITEMS_QUANTITY_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getItemBackup = async () => {
    dispatch({ type: GET_ITEM_BACKUP_BEGIN });
    try {
      const { data } = await authFetch("/items/backup");
      dispatch({
        type: GET_ITEM_BACKUP_SUCCESS,
        payload: { backup: data },
      });
    } catch (error) {
      console.log(error.response);

      clearAlert();
    }
  };

  const restoreItemQuantities = async () => {
    dispatch({ type: RESTORE_ITEM_QUANTITIES_BEGIN });
    try {
      await authFetch.post("/items/restore-quantities");
      dispatch({
        type: RESTORE_ITEM_QUANTITIES_SUCCESS,
      });
      getItems();
    } catch (error) {
      console.error("Error during item quantity restoration:", error);
      dispatch({
        type: RESTORE_ITEM_QUANTITIES_ERROR,
        payload: {
          error: error.message || "Failed to restore item quantities",
        },
      });
      //logoutUser();
      clearAlert();
    }
  };

  const createOrder = async (
    totalCost,
    items,
    providerId,
    providerName,
    orderName,
    notes,
    selectedDeliveryDay = ""
  ) => {
    dispatch({ type: CREATE_ORDER_BEGIN });
    try {
      const { itemCategories, itemTypes } = state;

      const filteredItems = items.map((item) => {
        const category = itemCategories.find(
          (c) => c._id === item.itemCategory
        );
        const type = itemTypes.find((t) => t._id === item.itemType);
        const itemProportionsType = itemTypes.find(
          (t) => t._id === item.itemProportionsType
        );

        const categoryName = category ? category.categoryName : "";
        const typeName = type ? type.typeName : "";
        const proportionsTypeName = itemProportionsType
          ? itemProportionsType.typeName
          : "";
        return {
          ...item,
          itemCategory: categoryName,
          itemType: typeName,
          itemProportionsType: proportionsTypeName,
          totalItemCost: parseFloat(item.price) * item.itemQuantity,
        };
      });
      const finalSelectedDeliveryDay = selectedDeliveryDay || "None";
      await authFetch.post("/orders", {
        orderName,
        totalCost,
        items: filteredItems,
        providerId: providerId,
        providerName,
        notes,
        orderDeliveryDay: finalSelectedDeliveryDay,
      });

      dispatch({ type: CREATE_ORDER_SUCCESS });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_ORDER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getOrders = async () => {
    const { timeFrame } = state;
    dispatch({ type: GET_ORDERS_BEGIN });
    try {
      const { data } = await authFetch(`/orders?timeFrame=${timeFrame}`);
      const { orders, totalOrders } = data;
      dispatch({
        type: GET_ORDERS_SUCCESS,
        payload: { orders, totalOrders },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const deleteOrder = async (orderId) => {
    dispatch({ type: DELETE_ORDER_BEGIN });
    try {
      await authFetch.delete(`/orders/${orderId}`);
      getTrashedItems("order");
    } catch (error) {
      logoutUser();
    }
  };

  const setEditOrder = (id) => {
    dispatch({ type: SET_EDIT_ORDER, payload: { id } });
  };

  const updateOrderItemStatus = (orderId, itemId, newStatus) => {
    dispatch({
      type: UPDATE_ORDER_ITEM_STATUS,
      payload: { orderId, itemId, newStatus },
    });
  };

  const updateMissingNumber = (orderId, itemId, number) => {
    dispatch({
      type: UPDATE_MISSING_NUMBER,
      payload: { orderId, itemId, number },
    });
  };

  const editOrder = async (orderId) => {
    const { orderName, orders, orderNotes, orderDeliveryDay } = state;
    const orderToUpdate = orders.find((order) => order._id === orderId);

    if (!orderToUpdate) {
      console.error("Order not found in the state.");
      return;
    }

    const isAllChecked = orderToUpdate.items.every(
      (item) =>
        item.status === "received" ||
        item.status === "backordered" ||
        item.status === "missing"
    );

    if (orderName !== "") {
      orderToUpdate.orderName = orderName;
    }
    if (orderNotes !== "") {
      orderToUpdate.notes = orderNotes;
    }
    if (orderDeliveryDay) {
      orderToUpdate.orderDeliveryDay = orderDeliveryDay;
    }
    orderToUpdate.status = isAllChecked ? "Completed" : "Pending";
    dispatch({ type: EDIT_ORDER_BEGIN });
    try {
      await authFetch.patch(`/orders/${orderId}`, orderToUpdate);
      getOrders();
      dispatch({ type: EDIT_ORDER_SUCCESS });
    } catch (error) {
      if (error.response && error.response.status === 401) return;
      dispatch({
        type: EDIT_ORDER_ERROR,
        payload: {
          msg: error.response
            ? error.response.data.msg
            : "Error updating order",
        },
      });
    }
    clearAlert();
  };

  const sendOrdersByEmail = async (emailContent) => {
    dispatch({ type: SEND_ORDERS_BEGIN });
    try {
      await authFetch.post("/orders/send-email", emailContent);
      dispatch({
        type: SEND_ORDERS_SUCCESS,
        payload: {
          msg: "Email sent successfully!",
          orderId: emailContent.orderId,
          emailSentAt: new Date(),
        },
      });
    } catch (error) {
      const errMsg =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Unknown error occurred";
      dispatch({
        type: SEND_ORDERS_ERROR,
        payload: { msg: errMsg },
      });
    }
    clearAlert();
  };

  const toggleDeleteConfirmation = () => {
    dispatch({ type: TOGGLE_DELETE_CONFIRMATION });
  };

  const toggleCreateOrder = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch({ type: TOGGLE_CREATE_ORDER });
  };

  const toggleSendOrders = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch({ type: TOGGLE_SEND_ORDERS });
  };

  const toggleExportData = (exportContext, data = null) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch({
      type: TOGGLE_EXPORT_DATA,
      payload: { exportContext, data },
    });
  };
  const toggleScanOrderInvoice = (orderId) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch({
      type: TOGGLE_SCAN_ORDER_INVOICE,
      payload: { orderId },
    });
  };
  const toggleEditErrorPercentage = () => {
    dispatch({ type: TOGGLE_EDIT_ERROR_PERCENTAGE });
  };

  const createUserSettings = async () => {
    dispatch({ type: CREATE_USER_SETTINGS_BEGIN });
    try {
      const { errorPercentage } = state;

      await authFetch.post("/user-settings", { errorPercentage });
      getUserSettings();
      dispatch({
        type: CREATE_USER_SETTINGS_SUCCESS,
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_USER_SETTINGS_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getUserSettings = async () => {
    dispatch({ type: GET_USER_SETTINGS_BEGIN });
    try {
      const { data } = await authFetch("/user-settings");
      dispatch({
        type: GET_USER_SETTINGS_SUCCESS,
        payload: { settings: data.settings },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };
  const updateUserSettings = async (settings, updateAll = false, menuId) => {
    dispatch({ type: UPDATE_USER_SETTINGS_BEGIN });
    try {
      await authFetch.patch("/user-settings", { ...settings, updateAll });
      getUserSettings();
      dispatch({
        type: UPDATE_USER_SETTINGS_SUCCESS,
      });
      if (updateAll) {
        getMenuItems(menuId);
      }
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: UPDATE_USER_SETTINGS_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const initiateOAuth = async () => {
    try {
      const response = await authFetch("/auth/google");
      const data = await response.data.url;
      return data;
    } catch (error) {
      console.error("Error initiating OAuth flow:", error);
    }
  };

  const handleOAuthCallback = async (code) => {
    try {
      const response = await authFetch(`/auth/google/callback?code=${code}`);

      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error handling OAuth callback:", error);
      throw error;
    }
  };

  const setOAuthToken = (token) => {
    const oneHour = 3600000;
    const expiryTime = new Date().getTime() + oneHour;
    localStorage.setItem("googleAuthToken", token);
    localStorage.setItem("googleAuthExpiry", expiryTime.toString());
    dispatch({ type: SET_OAUTH_TOKEN, payload: token });
  };

  const fetchEmails = async () => {
    const { oauthToken, selectedPos, salesReports } = state;
    const storedToken = localStorage.getItem("googleAuthToken");
    const storedExpiry = localStorage.getItem("googleAuthExpiry");
    const currentTime = new Date().getTime();

    if (
      salesReports.length === 0 &&
      (oauthToken || (storedToken && currentTime < storedExpiry))
    ) {
      dispatch({ type: FETCH_REPORTS_BEGIN });
      try {
        let url = `/emails?selectedPos=${selectedPos}`;
        const authToken = oauthToken || storedToken;

        const { data } = await authFetch(url, {
          preventAutoLogout: true,
          headers: { Authorization: `Bearer ${authToken}` },
        });
        // console.log(data);
        dispatch({ type: FETCH_REPORTS_SUCCESS, payload: data });
      } catch (error) {
        console.log("Login with Google Account before Fetching");
        dispatch({
          type: FETCH_REPORTS_ERROR,
        });
      }
    }
  };

  const uploadOcrImage = async (formData) => {
    dispatch({ type: SCAN_INVOICE_BEGIN });
    try {
      const response = await authFetch.post("ocr/upload", formData);
      const items = response.data.itemsJson.items || response.data.itemsJson;
      // console.log("Retrieved Items:", items);
      dispatch({
        type: SCAN_INVOICE_SUCCESS,
        payload: { items },
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      dispatch({
        type: SCAN_INVOICE_ERROR,
        payload: { msg: "Failed to upload file. Please try again." },
      });
    }
  };

  const compareInvoiceToOrderItems = async (invoiceItems, orderItems) => {
    dispatch({ type: COMPARE_INVOICE_BEGIN });
    try {
      const response = await authFetch.post("/ocr/compare-items", {
        invoiceItems,
        orderItems,
      });
      const linkedItems =
        response.data.linkedItems.linkedItems || response.data.linkedItems;
      // console.log("Retrieved Items:", linkedItems);
      dispatch({
        type: COMPARE_INVOICE_SUCCESS,
        payload: { linkedItems },
      });
    } catch (error) {
      console.error("Error comparing items:", error);
      dispatch({
        type: COMPARE_INVOICE_ERROR,
        payload: { msg: "Failed to compare items. Please try again." },
      });
    }
  };

  const clearFilters = () => {
    dispatch({ type: CLEAR_FILTERS });
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        displayAlert,
        setupUser,
        toggleSidebar,
        logoutUser,
        updateUser,
        handleChange,
        clearValues,
        createItem,
        getItems,
        setAddItem,
        setEditItem,
        editItem,
        toggleSubItemDetails,
        addCustomProportions,
        handleChangeCustomProportion,
        removeCustomProportion,
        changeItemProportions,
        deleteItem,
        softDeleteItem,
        restoreItem,
        getTrashedItems,
        updateItemQuantity,
        updateItemInventoryQuantity,
        updateItemStatus,
        toggleInventoryType,
        createProvider,
        getProviders,
        setAddProvider,
        setEditProvider,
        editProvider,
        deleteProvider,
        clearFilters,
        createItemLocation,
        getItemLocations,
        setAddItemLocation,
        deleteItemLocation,
        setEditItemLocation,
        editItemLocation,
        createItemCategory,
        getItemCategories,
        setAddItemCategory,
        deleteItemCategory,
        setEditItemCategory,
        editItemCategory,
        createItemType,
        getItemTypes,
        setAddItemType,
        deleteItemType,
        setEditItemType,
        editItemType,
        createItemKitchen,
        getItemKitchens,
        setAddItemKitchen,
        deleteItemKitchen,
        setEditItemKitchen,
        editItemKitchen,
        toggleAlternatives,
        //getItemAlternatives,
        addItemAlternative,
        removeItemAlternative,
        toggleCondensed,
        changePage,
        changeTrashedPage,
        createMenu,
        getMenus,
        setAddMenu,
        setEditMenu,
        editMenu,
        deleteMenu,
        setMenuItems,
        createMenuItem,
        getMenuItems,
        setAddMenuItem,
        setEditMenuItem,
        editMenuItem,
        deleteMenuItem,
        addIngredientField,
        removeIngredientField,
        updateIngredientField,
        searchMenuIngredients,
        setEditView,
        editView,
        toggleResetQuantities,
        resetItemQuantities,
        getItemBackup,
        restoreItemQuantities,
        toggleAutoOrder,
        toggleExportData,
        resetView,
        createOrder,
        getOrders,
        deleteOrder,
        setEditOrder,
        updateOrderItemStatus,
        updateMissingNumber,
        editOrder,
        sendOrdersByEmail,
        toggleScanOrderInvoice,
        toggleCreateOrder,
        toggleSendOrders,
        toggleDeleteConfirmation,
        initiateOAuth,
        handleOAuthCallback,
        setOAuthToken,
        fetchEmails,
        setDeliveryDay,
        createCheckoutSession,
        accessStripePortal,
        verifyStripeSession,
        checkSubscription,
        uploadOcrImage,
        compareInvoiceToOrderItems,
        updateItemPrices,
        updateConnectionStatus,
        toggleEditErrorPercentage,
        createUserSettings,
        getUserSettings,
        updateUserSettings,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, useAppContext, initialState };
