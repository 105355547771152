import links from "../utils/links";
import { NavLink } from "react-router-dom";
import { useAppContext } from "../context/appContext";
import { useTranslation } from "react-i18next";

const NavLinks = ({ toggleSidebar }) => {
  const { clearValues } = useAppContext();
  const { t } = useTranslation();

  const handleNavLinkClick = () => {
    toggleSidebar();
    clearValues();
  };

  return (
    <div className="nav-links">
      {links.map((link) => {
        const { text, path, id, icon } = link;

        return (
          <NavLink
            to={path}
            key={id}
            onClick={handleNavLinkClick}
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            end
          >
            <span className="icon">{icon}</span>
            {t(link.text)}
          </NavLink>
        );
      })}
    </div>
  );
};

export default NavLinks;
