import logo from "../assets/images/newLogo.png";

const Logo = () => {
  return (
    <img
      src={logo}
      alt="chumon"
      className="logo"
      style={{ maxWidth: "10rem" }}
    />
  );
};

export default Logo;
