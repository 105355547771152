import Wrapper from "../../assets/wrappers/Orders";

const InvoiceItemDetail = ({ item }) => {
  return (
    <Wrapper>
      <div
        style={{
          flexDirection: "row",
          alignItems: "flex-end",
          flexWrap: "wrap",
        }}
        className="order-item-detail"
      >
        <div style={{ display: "flex" }}>
          <h4 style={{ marginRight: "10px" }}>{item.quantity}</h4>
          <h2>{item.item_name}</h2>
        </div>
        <div style={{ display: "flex" }}>
          <h5 style={{ marginTop: "0.1rem" }} className="itemProportions">
            {item.format}
          </h5>
          <h3>{item.unit_price}$</h3>
        </div>
      </div>
    </Wrapper>
  );
};

export default InvoiceItemDetail;
