export const DISPLAY_ALERT = "SHOW_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";

export const SETUP_USER_BEGIN = "SETUP_USER_BEGIN";
export const SETUP_USER_SUCCESS = "SETUP_USER_SUCCESS";
export const SETUP_USER_ERROR = "SETUP_USER_ERROR";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const TOGGLE_ALTERNATIVES = "TOGGLE_ALTERNATIVES";
export const LOGOUT_USER = "LOGOUT_USER";

export const UPDATE_USER_BEGIN = "UPDATE_USER_BEGIN";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const HANDLE_CHANGE = "HANDLE_CHANGE";
export const CLEAR_VALUES = "CLEAR_VALUES";

//item
export const CREATE_ITEM_BEGIN = "CREATE_ITEM_BEGIN";
export const CREATE_ITEM_SUCCESS = "CREATE_ITEM_SUCCESS";
export const CREATE_ITEM_ERROR = "CREATE_ITEM_ERROR";

export const GET_ITEMS_BEGIN = "GET_ITEMS_BEGIN";
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";

export const ADD_CUSTOM_PROPORTION = "ADD_CUSTOM_PROPORTION";
export const HANDLE_CUSTOM_PROPORTION_CHANGE =
  "HANDLE_CUSTOM_PROPORTION_CHANGE";
export const REMOVE_CUSTOM_PROPORTION = "REMOVE_CUSTOM_PROPORTION";
export const CHANGE_PROPORTIONS_BEGIN = "CHANGE_PROPORTIONS_BEGIN";
export const CHANGE_PROPORTIONS_SUCCESS = "CHANGE_PROPORTIONS_SUCCESS";
export const CHANGE_PROPORTIONS_ERROR = "CHANGE_PROPORTIONS_ERROR";

export const GET_ITEM_ALTERNATIVES_BEGIN = "GET_ITEM_ALTERNATIVES_BEGIN";
export const GET_ITEM_ALTERNATIVES_SUCCESS = "GET_ITEM_ALTERNATIVES_SUCCESS";

export const SET_ADD_ITEM = "SET_ADD_ITEM";
export const SET_EDIT_ITEM = "SET_EDIT_ITEM";
export const TOGGLE_SUBITEM_DETAILS = "TOGGLE_SUBITEM_DETAILS";

export const TOGGLE_DELETE_CONFIRMATION = "TOGGLE_DELETE_CONFIRMATION";
export const DELETE_ITEM_BEGIN = "DELETE_ITEM_BEGIN";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";

export const EDIT_ITEM_BEGIN = "EDIT_ITEM_BEGIN";
export const EDIT_ITEM_SUCCESS = "EDIT_ITEM_SUCCESS";
export const EDIT_ITEM_ERROR = "EDIT_ITEM_ERROR";

export const RESET_ITEMS_QUANTITY_BEGIN = "RESET_ITEMS_QUANTITY_BEGIN";
export const RESET_ITEMS_QUANTITY_SUCCESS = "RESET_ITEMS_QUANTITY_SUCCESS";
export const RESET_ITEMS_QUANTITY_ERROR = "RESET_ITEMS_QUANTITY_ERROR";

export const GET_ITEM_BACKUP_BEGIN = "GET_ITEM_BACKUPS_BEGIN";
export const GET_ITEM_BACKUP_SUCCESS = "GET_ITEM_BACKUPS_SUCCESS";

export const RESTORE_ITEM_QUANTITIES_BEGIN = "RESTORE_ITEM_QUANTITIES_BEGIN";
export const RESTORE_ITEM_QUANTITIES_SUCCESS =
  "RESTORE_ITEM_QUANTITIES_SUCCESS";
export const RESTORE_ITEM_QUANTITIES_ERROR = "RESTORE_ITEM_QUANTITIES_ERROR";

//provider
export const CREATE_PROVIDER_BEGIN = "CREATE_PROVIDER_BEGIN";
export const CREATE_PROVIDER_SUCCESS = "CREATE_PROVIDER_SUCCESS";
export const CREATE_PROVIDER_ERROR = "CREATE_PROVIDER_ERROR";

export const GET_PROVIDERS_BEGIN = "GET_PROVIDERS_BEGIN";
export const GET_PROVIDERS_SUCCESS = "GET_PROVIDERS_SUCCESS";

export const SET_ADD_PROVIDER = "SET_ADD_PROVIDER";
export const SET_EDIT_PROVIDER = "SET_EDIT_PROVIDER";

export const DELETE_PROVIDER_BEGIN = "DELETE_PROVIDER_BEGIN";
export const DELETE_PROVIDER_SUCCESS = "DELETE_PROVIDER_SUCCESS";

export const EDIT_PROVIDER_BEGIN = "EDIT_PROVIDER_BEGIN";
export const EDIT_PROVIDER_SUCCESS = "EDIT_PROVIDER_SUCCESS";
export const EDIT_PROVIDER_ERROR = "EDIT_PROVIDER_ERROR";

export const SET_DELIVERY_DAY = "SET_DELIVERY_DAY";

//ClearFilters
export const CLEAR_FILTERS = "CLEAR_FILTERS";

//itemQuantity
export const UPDATE_ITEM_QUANTITY_BEGIN = "UPDATE_ITEM_QUANTITY_BEGIN";
export const UPDATE_ITEM_QUANTITY_SUCCESS = "UPDATE_ITEM_QUANTITY_SUCCESS";
export const UPDATE_ITEM_QUANTITY_ERROR = "UPDATE_ITEM_QUANTITY_ERROR";

//itemInventoryQuantity
export const UPDATE_ITEM_INVENTORY_QUANTITY_BEGIN =
  "UPDATE_ITEM_INVENTORY_QUANTITY_BEGIN";
export const UPDATE_ITEM_INVENTORY_QUANTITY_SUCCESS =
  "UPDATE_ITEM_INVENTORY_QUANTITY_SUCCESS";
export const UPDATE_ITEM_INVENTORY_QUANTITY_ERROR =
  "UPDATE_ITEM_INVENTORY_QUANTITY_ERROR";

export const TOGGLE_RESET_QUANTITIES = "TOGGLE_RESET_QUANTITIES";
export const TOGGLE_AUTO_ORDER = "TOGGLE_AUTO_ORDER";

//itemLocation
export const CREATE_ITEM_LOCATION_BEGIN = "CREATE_ITEM_LOCATION_BEGIN";
export const CREATE_ITEM_LOCATION_SUCCESS = "CREATE_ITEM_LOCATION_SUCCESS";
export const CREATE_ITEM_LOCATION_ERROR = "CREATE_ITEM_LOCATION_ERROR";

export const GET_ITEM_LOCATIONS_BEGIN = "GET_ITEM_LOCATIONS_BEGIN";
export const GET_ITEM_LOCATIONS_SUCCESS = "GET_ITEM_LOCATIONS_SUCCESS";

export const SET_ADD_ITEM_LOCATION = "SET_ADD_ITEM_LOCATION";
export const SET_EDIT_ITEM_LOCATION = "SET_EDIT_ITEM_LOCATION";
export const DELETE_ITEM_LOCATION_BEGIN = "DELETE_ITEM_LOCATION_BEGIN";

export const EDIT_ITEM_LOCATION_BEGIN = "EDIT_ITEM_LOCATION_BEGIN";
export const EDIT_ITEM_LOCATION_SUCCESS = "EDIT_ITEM_LOCATION_SUCCESS";
export const EDIT_ITEM_LOCATION_ERROR = "EDIT_ITEM_LOCATION_ERROR";

//ItemCategory
export const CREATE_ITEM_CATEGORY_BEGIN = "CREATE_ITEM_CATEGORY_BEGIN";
export const CREATE_ITEM_CATEGORY_SUCCESS = "CREATE_ITEM_CATEGORY_SUCCESS";
export const CREATE_ITEM_CATEGORY_ERROR = "CREATE_ITEM_CATEGORY_ERROR";

export const GET_ITEM_CATEGORIES_BEGIN = "GET_ITEM_CATEGORIES_BEGIN";
export const GET_ITEM_CATEGORIES_SUCCESS = "GET_ITEM_CATEGORIES_SUCCESS";

export const SET_ADD_ITEM_CATEGORY = "SET_ADD_ITEM_CATEGORY";
export const SET_EDIT_ITEM_CATEGORY = "SET_EDIT_ITEM_CATEGORY";
export const DELETE_ITEM_CATEGORY_BEGIN = "DELETE_ITEM_CATEGORY_BEGIN";

export const EDIT_ITEM_CATEGORY_BEGIN = "EDIT_ITEM_CATEGORY_BEGIN";
export const EDIT_ITEM_CATEGORY_SUCCESS = "EDIT_ITEM_CATEGORY_SUCCESS";
export const EDIT_ITEM_CATEGORY_ERROR = "EDIT_ITEM_CATEGORY_ERROR";

//ItemType
export const CREATE_ITEM_TYPE_BEGIN = "CREATE_ITEM_TYPE_BEGIN";
export const CREATE_ITEM_TYPE_SUCCESS = "CREATE_ITEM_TYPE_SUCCESS";
export const CREATE_ITEM_TYPE_ERROR = "CREATE_ITEM_TYPE_ERROR";

export const GET_ITEM_TYPES_BEGIN = "GET_ITEM_TYPES_BEGIN";
export const GET_ITEM_TYPES_SUCCESS = "GET_ITEM_TYPES_SUCCESS";

export const SET_ADD_ITEM_TYPE = "SET_ADD_ITEM_TYPE";
export const SET_EDIT_ITEM_TYPE = "SET_EDIT_ITEM_TYPE";
export const DELETE_ITEM_TYPE_BEGIN = "DELETE_ITEM_TYPE_BEGIN";

export const EDIT_ITEM_TYPE_BEGIN = "EDIT_ITEM_TYPE_BEGIN";
export const EDIT_ITEM_TYPE_SUCCESS = "EDIT_ITEM_TYPE_SUCCESS";
export const EDIT_ITEM_TYPE_ERROR = "EDIT_ITEM_TYPE_ERROR";

//Kitchens
export const CREATE_ITEM_KITCHEN_BEGIN = "CREATE_ITEM_KITCHEN_BEGIN";
export const CREATE_ITEM_KITCHEN_SUCCESS = "CREATE_ITEM_KITCHEN_SUCCESS";
export const CREATE_ITEM_KITCHEN_ERROR = "CREATE_ITEM_KITCHEN_ERROR";

export const GET_ITEM_KITCHENS_BEGIN = "GET_ITEM_KITCHENS_BEGIN";
export const GET_ITEM_KITCHENS_SUCCESS = "GET_ITEM_KITCHENS_SUCCESS";

export const SET_ADD_ITEM_KITCHEN = "SET_ADD_ITEM_KITCHEN";
export const SET_EDIT_ITEM_KITCHEN = "SET_EDIT_ITEM_KITCHEN";
export const DELETE_ITEM_KITCHEN_BEGIN = "DELETE_ITEM_KITCHEN_BEGIN";

export const EDIT_ITEM_KITCHEN_BEGIN = "EDIT_ITEM_KITCHEN_BEGIN";
export const EDIT_ITEM_KITCHEN_SUCCESS = "EDIT_ITEM_KITCHEN_SUCCESS";
export const EDIT_ITEM_KITCHEN_ERROR = "EDIT_ITEM_KITCHEN_ERROR";

//ItemAlternative
export const ADD_ITEM_ALTERNATIVE_BEGIN = "ADD_ITEM_ALTERNATIVE_BEGIN";
export const REMOVE_ITEM_ALTERNATIVE = "REMOVE_ITEM_ALTERNATIVE";

export const CHANGE_PAGE = "CHANGE_PAGE";
export const CHANGE_TRASHED_PAGE = "CHANGE_TRASHED_PAGE";

export const TOGGLE_CONDENSED = "TOGGLE_CONDENSED";

//Menus
export const CREATE_MENU_BEGIN = "CREATE_MENU_BEGIN";
export const CREATE_MENU_SUCCESS = "CREATE_MENU_SUCCESS";
export const CREATE_MENU_ERROR = "CREATE_MENU_ERROR";

export const GET_MENUS_BEGIN = "GET_MENUS_BEGIN";
export const GET_MENUS_SUCCESS = "GET_MENUS_SUCCESS";

export const SET_ADD_MENU = "SET_ADD_MENU";
export const SET_EDIT_MENU = "SET_EDIT_MENU";

export const DELETE_MENU_BEGIN = "DELETE_MENU_BEGIN";

export const EDIT_MENU_BEGIN = "EDIT_MENU_BEGIN";
export const EDIT_MENU_SUCCESS = "EDIT_MENU_SUCCESS";
export const EDIT_MENU_ERROR = "EDIT_MENU_ERROR";

export const SET_MENU_ITEMS = "SET_MENU_ITEMS";

//MenuItem
export const CREATE_MENU_ITEM_BEGIN = "CREATE_MENU_ITEM_BEGIN";
export const CREATE_MENU_ITEM_SUCCESS = "CREATE_MENU_ITEM_SUCCESS";
export const CREATE_MENU_ITEM_ERROR = "CREATE_MENU_ITEM_ERROR";

export const GET_MENU_ITEMS_BEGIN = "GET_MENU_ITEMS_BEGIN";
export const GET_MENU_ITEMS_SUCCESS = "GET_MENU_ITEMS_SUCCESS";

export const SET_ADD_MENU_ITEM = "SET_ADD_MENU_ITEM";
export const SET_EDIT_MENU_ITEM = "SET_EDIT_MENU_ITEM";

export const DELETE_MENU_ITEM_BEGIN = "DELETE_MENU_ITEM_BEGIN";

export const EDIT_MENU_ITEM_BEGIN = "EDIT_MENU_ITEM_BEGIN";
export const EDIT_MENU_ITEM_SUCCESS = "EDIT_MENU_ITEM_SUCCESS";
export const EDIT_MENU_ITEM_ERROR = "EDIT_MENU_ITEM_ERROR";

export const ADD_INGREDIENT_FIELD = "ADD_INGREDIENT_FIELD";
export const REMOVE_INGREDIENT_FIELD = "REMOVE_INGREDIENT_FIELD";
export const UPDATE_INGREDIENT_FIELD = "UPDATE_INGREDIENT_FIELD";

export const SEARCH_INGREDIENT_BEGIN = "SEARCH_INGREDIENT_BEGIN";
export const SEARCH_INGREDIENT_SUCCESS = "SEARCH_INGREDIENT_SUCCESS";
export const CLEAR_INGREDIENT_SEARCH_RESULTS =
  "CLEAR_INGREDIENT_SEARCH_RESULTS";

//EditView
export const SET_EDIT_VIEW = "SET_EDIT_VIEW";
export const EDIT_VIEW = "EDIT_VIEW";
export const RESET_VIEW = "RESET_VIEW";

export const TOGGLE_EXPORT_DATA = "TOGGLE_EXPORT_DATA";

//Trash
export const SOFT_DELETE_ITEM_BEGIN = "SOFT_DELETE_ITEM_BEGIN";
export const SOFT_DELETE_ITEM_SUCCESS = "SOFT_DELETE_ITEM_SUCCESS";
export const RESTORE_ITEM_BEGIN = "RESTORE_ITEM_BEGIN";
export const RESTORE_ITEM_SUCCESS = "RESTORE_ITEM_SUCCESS";
export const GET_TRASHED_ITEMS_BEGIN = "GET_TRASHED_ITEMS_BEGIN";
export const GET_TRASHED_ITEMS_SUCCESS = "GET_TRASHED_ITEMS_SUCCESS";

//orders
export const CREATE_ORDER_BEGIN = "CREATE_ORDER_BEGIN";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_ERROR = "CREATE_ORDER_ERROR";

export const GET_ORDERS_BEGIN = "GET_ORDERS_BEGIN";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";

export const SET_ADD_ORDER = "SET_ADD_ORDER";
export const SET_EDIT_ORDER = "SET_EDIT_ORDER";
export const DELETE_ORDER_BEGIN = "DELETE_ORDER_BEGIN";

export const UPDATE_ORDER_ITEM_STATUS = "UPDATE_ORDER_ITEM_STATUS";
export const UPDATE_MISSING_NUMBER = "UPDATE_MISSING_NUMBER";

export const EDIT_ORDER_BEGIN = "EDIT_ORDER_BEGIN";
export const EDIT_ORDER_SUCCESS = "EDIT_ORDER_SUCCESS";
export const EDIT_ORDER_ERROR = "EDIT_ORDER_ERROR";

export const TOGGLE_CREATE_ORDER = "TOGGLE_CREATE_ORDER";
export const TOGGLE_SEND_ORDERS = "TOGGLE_SEND_ORDERS";

export const SEND_ORDERS_BEGIN = "SEND_ORDERS_BEGIN";
export const SEND_ORDERS_SUCCESS = "SEND_ORDERS_SUCCESS";
export const SEND_ORDERS_ERROR = "SEND_ORDERS_ERROR";

//google gmail API
export const SET_OAUTH_TOKEN = "SET_OAUTH_TOKEN";
export const FETCH_REPORTS_BEGIN = "FETCH_REPORTS_BEGIN";
export const FETCH_REPORTS_SUCCESS = "FETCH_REPORTS_SUCCESS";
export const FETCH_REPORTS_ERROR = "FETCH_REPORTS_ERROR";

//Stripe functions
export const GET_SUBSCRIPTION_BEGIN = "GET_SUBSCRIPTION_BEGIN";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";

export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";

export const CHECK_SUBSCRIPTION_SUCCESS = "CHECK_SUBSCRIPTION_SUCCESS";
export const CHECK_SUBSCRIPTION_FAILURE = "CHECK_SUBSCRIPTION_FAILURE";

export const VERIFY_SESSION_BEGIN = "VERIFY_SESSION_BEGIN";
export const VERIFY_SESSION_SUCCESS = "VERIFY_SESSION_SUCCESS";

export const UPDATE_STRIPE_INFO_BEGIN = "UPDATE_STRIPE_INFO_BEGIN";
export const UPDATE_STRIPE_INFO_SUCCESS = "UPDATE_STRIPE_INFO_SUCCESS";
export const UPDATE_STRIPE_INFO_ERROR = "UPDATE_STRIPE_INFO_ERROR";

export const SUBSCRIPTION_ERROR = "SUBSCRIPTION_ERROR";

//Invoices
export const TOGGLE_SCAN_ORDER_INVOICE = "TOGGLE_SCAN_ORDER_INVOICE";

export const SCAN_INVOICE_BEGIN = "SCAN_INVOICE_BEGIN";
export const SCAN_INVOICE_SUCCESS = "SCAN_INVOICE_SUCCESS";
export const SCAN_INVOICE_ERROR = "SCAN_INVOICE_ERROR";

export const COMPARE_INVOICE_BEGIN = "COMPARE_INVOICE_BEGIN";
export const COMPARE_INVOICE_SUCCESS = "COMPARE_INVOICE_SUCCESS";
export const COMPARE_INVOICE_ERROR = "COMPARE_INVOICE_ERROR";

export const UPDATE_PRICES_BEGIN = "UPDATE_PRICES_BEGIN";
export const UPDATE_PRICES_SUCCESS = "UPDATE_PRICES_SUCCESS";
export const UPDATE_PRICES_ERROR = "UPDATE_PRICES_ERROR";

//User Settings
export const CREATE_USER_SETTINGS_BEGIN = "CREATE_USER_SETTINGS_BEGIN";
export const CREATE_USER_SETTINGS_SUCCESS = "CREATE_USER_SETTINGS_SUCCESS";
export const CREATE_USER_SETTINGS_ERROR = "CREATE_USER_SETTINGS_ERROR";

export const GET_USER_SETTINGS_BEGIN = "GET_USER_SETTINGS_BEGIN";
export const GET_USER_SETTINGS_SUCCESS = "GET_USER_SETTINGS_SUCCESS";

export const UPDATE_USER_SETTINGS_BEGIN = "UPDATE_USER_SETTINGS_BEGIN";
export const UPDATE_USER_SETTINGS_SUCCESS = "UPDATE_USER_SETTINGS_SUCCESS";
export const UPDATE_USER_SETTINGS_ERROR = "UPDATE_USER_SETTINGS_ERROR";

export const TOGGLE_EDIT_ERROR_PERCENTAGE = "TOGGLE_EDIT_ERROR_PERCENTAGE";

//Connection
export const ADD_PENDING_CHANGE = "ADD_PENDING_CHANGE";
export const CLEAR_PENDING_CHANGES = "CLEAR_PENDING_CHANGES";
export const SET_CONNECTION_STATUS = "SET_CONNECTION_STATUS";

export const SET_LANGUAGE = "SET_LANGUAGE";
