import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/EditView";
import { useTranslation } from "react-i18next";

const EditView = () => {
  const { t } = useTranslation();
  const {
    isEditingView,
    editView,
    isCondensed,
    viewItemPrice,
    viewItemNeeded,
    viewItemSupplier,
    viewItemAmount,
    viewItemCode,
    viewItemProvider,
    viewItemLocation,
    viewItemCategory,
    viewItemType,
    viewItemInventoryQuantity,
    viewItemKitchen,
    viewItemTotal,
    resetView,
  } = useAppContext();

  const viewOptions = [
    { label: "Type", viewKey: "viewItemType" },
    { label: "Supplier", viewKey: "viewItemSupplier" },
    { label: "Provider", viewKey: "viewItemProvider" },
    { label: "Code", viewKey: "viewItemCode" },
    { label: "Price", viewKey: "viewItemPrice" },
    { label: "Location", viewKey: "viewItemLocation" },
    { label: "Category", viewKey: "viewItemCategory" },
    { label: "Amount", viewKey: "viewItemAmount" },
    { label: "Inventory", viewKey: "viewItemInventoryQuantity" },
    { label: "Needed", viewKey: "viewItemNeeded" },
    { label: "Total", viewKey: "viewItemTotal" },
  ];
  const getStateValue = (viewKey) => {
    const stateValues = {
      viewItemType,
      viewItemSupplier,
      viewItemProvider,
      viewItemCode,
      viewItemPrice,
      viewItemLocation,
      viewItemCategory,
      viewItemAmount,
      viewItemInventoryQuantity,
      viewItemNeeded,
      viewItemTotal,
    };
    return stateValues[viewKey];
  };

  if (isEditingView && isCondensed) {
    return (
      <Wrapper>
        <div className="edit-view-container">
          {viewOptions.map(({ label, viewKey }) => (
            <div
              className="view-container"
              key={viewKey}
              onClick={() => editView(viewKey)}
            >
              <input type="checkbox" checked={getStateValue(viewKey)} />
              <h5>{t(label)}</h5>
            </div>
          ))}
          <button className="btn reset-button" onClick={resetView}>
            {t("Reset View")}
          </button>
        </div>
      </Wrapper>
    );
  }
};

export default EditView;
