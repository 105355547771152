import styled from "styled-components";

const Wrapper = styled.section`
  h2 {
    text-transform: none;
  }
  & > h5 {
    font-weight: 700;
  }
  .providers {
    display: flex;
    flex-direction: column;
  }
  .providers-title-h5 {
    margin-bottom: 0;
    margin-left: 1rem;
    margin-right: 0;
    font-weight: 300;
  }

  .providers-title {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 3rem;
    max-width: 100vw;
  }

  .providers-title-alert {
    margin-left: 1rem;
    margin-right: 0.5rem;
    margin-bottom: 5rem;

    max-width: 10rem;
    height: 1.5rem;

    padding: 0;
  }

  .model-select {
    padding: 0.25em 0em 0.25rem 0.25rem;
    margin-bottom: 0.5;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: var(--shadow-2);
    cursor: pointer;
    outline: none;
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }

  .model-select:hover {
    border-color: #bcbcbc;
  }

  .model-select::-ms-expand {
    display: none;
  }
  @media (min-width: 992px) {
    .jobs {
      display: flex;
      flex-direction: column;
    }
    .providers-title-alert {
      margin-bottom: 2rem;
      margin-left: auto;

      max-width: 20rem;
    }
  }
`;
export default Wrapper;
