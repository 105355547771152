import styled from "styled-components";

const Wrapper = styled.aside`
  margin-top: 0.75rem;
  border-radius: 10px;

  background: var(--white);
  box-shadow: var(--shadow-2);

  overflow: hidden;

  h3 {
    margin-top: 0;
  }

  .form {
    display: none;
    margin: 0;
    border-radius: 0;

    box-shadow: none;

    max-width: 100%;
    width: 100%;
  }
  .form_active {
    display: block;
    padding: 1.5rem 1.5rem 1.5rem;
  }
  .form_order_active {
    display: block;

    h5 {
      margin: 1.5rem 0 0 0;
    }
  }
  .form_fixed {
    position: fixed;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    z-index: 100;
    min-width: 95%;
    max-width: 98%;
    border: 1px solid var(--grey-200);
    border-radius: 8px;
    box-shadow: var(--shadow-2);
    background: var(--white);
    padding: 1rem 1.5rem;

    max-height: 90vh;
    overflow-y: auto;
  }

  .form-row {
    margin-bottom: 0;
  }
  .form-center {
    display: grid;
    row-gap: 0.5rem;
  }
  .form-order-center {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .form-center button {
    align-self: end;
    height: 35px;
    margin-top: 1rem;
  }
  .order-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    padding: 0.5rem;
    margin-bottom: 0.5rem;

    //border: 1px solid var(--grey-400);
    border-radius: 10px;
    background-color: var(--white);
  }
  .sub-item-details {
    background-color: var(--grey-100);
    padding: 0.5rem;
    margin: -0.25rem;
    border-radius: 10px;
  }
  .addItem-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
  }
  .addItem-group-custom {
    display: flex;
    justify-content: space-between;
    width: auto;
  }
  .custom-proportions-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    background: var(--grey-100);
    border-radius: 10px;
    margin-top: 0.5rem;
    padding: 0.5rem;
  }
  .remove-proportion {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem 0.5rem;
    align-self: flex-end;
    margin-bottom: 0.25rem;
    margin-top: 0.5rem;
  }

  .order-details {
    display: flex;
    align-items: flex-end;
  }
  .order-details h5 {
    margin: 0;
  }
  .btn-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    align-self: flex-end;
    margin-top: 1rem;
    button {
      height: auto;
    }
  }

  .clear-btn {
    background: var(--grey-500);
  }
  .clear-btn:hover {
    background: var(--black);
  }
  .edit-btn,
  .delete-btn {
    letter-spacing: var(--letterSpacing);
    cursor: pointer;
    height: 30px;
  }
  .edit-btn {
    color: var(--white);
    background: var(--green-light);
    margin-bottom: 0.75rem;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
    :hover {
      background: var(--green-dark);
    }
  }
  .delete-btn {
    color: var(--white);
    background: var(--red-light);
    @media (min-width: 576px) {
      margin-left: 0.75rem;
    }
    :hover {
      background: var(--red-dark);
    }
  }

  .edit-item-alternatives {
    p {
      margin: 0;
    }
  }
  .itemName {
    font-weight: 800;
    font-size: 1.25rem;
  }

  .itemSupplier {
    font-weight: 900;
    color: var(--primary-400);
  }

  .itemProvider {
    color: var(--green-light);
    font-weight: 700;
  }

  .ingredient-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 0.5rem;

    .item-details {
      font-size: 0.9rem;
      display: flex;
      align-self: flex-end;
      width: 6rem;
      p {
        margin-bottom: 0;
      }
    }
  }
  .search-container {
    position: relative;
    width: 15rem;
    @media (min-width: 992px) {
      width: 20rem;
    }
  }

  .menuIngredients-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    .supplier {
      color: var(--primary-400);
    }

    .provider {
      color: var(--green-light);
    }
  }

  .search-item {
    background-color: white;
    padding: 8px 12px;
    cursor: pointer;

    width: 100%;
  }
  .ingredient-quantity-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: auto;
    gap: 0.25rem;
  }
  .ingredient-formRow {
    width: 5rem;
    @media (min-width: 992px) {
      width: 8rem;
    }
  }

  .search-item:hover {
    box-shadow: inset 0 0 0 2px black;
  }

  .valid {
    background-color: #a8d5ba;
  }
  .invalid {
    background-color: #d5a8a8;
  }
  @media (min-width: 992px) {
    .form-center {
      grid-template-columns: 1fr 1fr;
      align-items: center;
      column-gap: 1rem;
    }
  }
  @media (min-width: 1120px) {
    .form-center {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .form-center button {
      margin-top: 0;
    }
  }
  .edit-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 99;
  }
`;
export default Wrapper;
