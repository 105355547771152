import { useAppContext } from "../../context/appContext";
import { useState } from "react";
import Wrapper from "../../assets/wrappers/ItemCondensed";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { RiLoopRightLine } from "react-icons/ri";

const ItemCondensedO = ({ item }) => {
  const {
    setEditItem,
    deleteItem,
    updateItemQuantity,
    updateItemInventoryQuantity,
    updateItemStatus,
    itemLocations,
    itemCategories,
    itemTypes,
    itemKitchens,
    viewItemName,
    viewItemProportions,
    viewItemPrice,
    viewItemNeeded,
    viewItemSupplier,
    viewItemAmount,
    viewItemCode,
    viewItemProvider,
    viewItemLocation,
    viewItemCategory,
    viewItemType,
    viewItemKitchen,
    viewItemInventoryQuantity,
    viewItemTotal,
    alternativePairs,
    includeAlternatives,
    providers,
    toggleInventoryType,
  } = useAppContext();

  const [isEditingInventoryQuantity, setIsEditingInventoryQuantity] =
    useState(false);
  const [editableInventoryQuantity, setEditableInventoryQuantity] = useState(
    item.itemInventoryQuantity
  );

  const handleInventoryQuantityClick = () => {
    setIsEditingInventoryQuantity(true);
  };

  const handleInventoryQuantityChange = (e) => {
    const value = e.target.validity.valid
      ? e.target.value
      : editableInventoryQuantity;
    setEditableInventoryQuantity(value);
  };

  const handleInventoryQuantitySubmit = () => {
    updateItemInventoryQuantity(item, editableInventoryQuantity);
    setIsEditingInventoryQuantity(false);
  };

  const handleInventoryIncrementDecrement = (change) => {
    const currentQuantity = parseFloat(item.itemInventoryQuantity) || 0;
    const updatedQuantity = Math.max(
      0,
      Math.round((currentQuantity + change) * 100) / 100
    );
    setEditableInventoryQuantity(updatedQuantity);
    updateItemInventoryQuantity(item, updatedQuantity);
  };

  const [isEditingItemQuantity, setIsEditingItemQuantity] = useState(false);
  const [editableItemQuantity, setEditableItemQuantity] = useState(
    item.itemQuantity
  );

  const handleItemQuantityClick = () => {
    setIsEditingItemQuantity(true);
  };

  const handleItemQuantityChange = (e) => {
    setEditableItemQuantity(parseInt(e.target.value, 10) || 0);
  };

  const handleItemQuantitySubmit = () => {
    updateItemQuantity(item, editableItemQuantity);
    setIsEditingItemQuantity(false);
  };

  const handleItemIncrementDecrement = (change) => {
    const currentQuantity = parseInt(item.itemQuantity, 10) || 0;
    const updatedQuantity = Math.max(0, currentQuantity + change);
    setEditableItemQuantity(updatedQuantity);
    updateItemQuantity(item, updatedQuantity);
  };

  const selectedProvider = providers.find(
    (provider) => provider._id === item.provider
  );

  const selectedLocation = itemLocations.find(
    (location) => location._id === item.itemLocation
  );
  const selectedCategory = itemCategories.find(
    (category) => category._id === item.itemCategory
  );
  const selectedType = itemTypes.find((type) => type._id === item.itemType);
  const selectedKitchen = itemKitchens.find(
    (kitchen) => kitchen._id === item.itemKitchen
  );
  const selectedNeededType = itemTypes.find(
    (type) => type._id === item.itemNeededType
  );
  const selectedLeftToOrderType = itemTypes.find(
    (type) => type._id === item.itemLeftToOrderType
  );
  const selectedInventoryType = itemTypes.find(
    (type) => type._id === item.selectedInventoryType
  );
  const selectedProportionsType = itemTypes.find(
    (type) => type._id === item.itemProportionsType
  );

  const providerName = selectedProvider ? selectedProvider.providerName : "";
  const locationName = selectedLocation ? selectedLocation.locationName : "";
  const categoryName = selectedCategory ? selectedCategory.categoryName : "";
  const typeName = selectedType ? selectedType.typeName : "";
  const kitchenName = selectedKitchen ? selectedKitchen.kitchenName : "";
  const itemNeededName = selectedNeededType ? selectedNeededType.typeName : "";
  const itemLeftToOrderName = selectedLeftToOrderType
    ? selectedLeftToOrderType.typeName
    : "";

  const itemSelectedInventoryType = selectedInventoryType
    ? selectedInventoryType.typeName
    : "";
  const proportionsTypeName = selectedProportionsType
    ? selectedProportionsType.typeName
    : "";

  const isAlternative =
    includeAlternatives &&
    alternativePairs.some((pair) => pair.alternative._id === item._id);

  let backgroundColor = "";
  if (includeAlternatives && item.selectedAlternatives.length > 0) {
    backgroundColor = "#A6C8FD";
  } else if (isAlternative) {
    backgroundColor = "#FAB1A0";
  } else if (item.itemQuantity > 0) {
    backgroundColor = "#A8D5BA";
  }
  const isDefaultOrOther = (type) => {
    return ["Default", "Other"].includes(type);
  };

  return (
    <Wrapper>
      <div
        style={{
          background: backgroundColor,
        }}
        className={`${item.isBackordered ? "active" : ""}`}
      >
        <div className="condensed-container">
          <div
            className="items-condensed"
            style={{ width: "3.5%" }}
            title={item.itemQuantity}
          >
            {item.itemQuantity}
          </div>
          {viewItemType && (
            <div
              className="items-condensed"
              style={{ width: "6%" }}
              title={typeName}
            >
              {typeName}
            </div>
          )}
          {viewItemName && (
            <div
              className="items-condensed"
              style={{ width: "15.5%" }}
              title={item.itemName}
            >
              {item.itemName}
            </div>
          )}
          {viewItemProportions && (
            <div
              className="items-condensed"
              style={{ width: "9%" }}
              title={item.itemProportions}
            >
              {item.itemProportions &&
                `${item.itemProportions}${
                  item.itemProportionsAmount !== 0
                    ? `x${item.itemProportionsAmount}`
                    : ""
                }${
                  item.itemProportionsAmountType !== "Other"
                    ? item.itemProportionsAmountType
                    : ""
                }`}
            </div>
          )}
          {viewItemSupplier && (
            <div
              className="items-condensed"
              style={{ width: "11%" }}
              title={item.itemSupplier}
            >
              {item.itemSupplier}
            </div>
          )}
          {viewItemProvider && (
            <div
              className="items-condensed"
              style={{ width: "9%" }}
              title={providerName}
            >
              {providerName}
            </div>
          )}
          {viewItemCode && (
            <div
              className="items-condensed"
              style={{ width: "9%" }}
              title={item.itemCode}
            >
              {item.itemCode}
            </div>
          )}
          {viewItemPrice && (
            <div
              className="items-condensed"
              style={{ width: "5%" }}
              title={item.price}
            >
              {item.price}
            </div>
          )}
          {viewItemLocation && (
            <div
              className="items-condensed"
              style={{ width: "10%" }}
              title={locationName}
            >
              {locationName}
            </div>
          )}
          {viewItemCategory && (
            <div
              className="items-condensed"
              style={{ width: "11%" }}
              title={categoryName}
            >
              {categoryName}
            </div>
          )}
          {viewItemAmount && (
            <div
              className="items-condensed"
              style={{ width: "6%" }}
              title={item.itemAmount + item.itemAmountType}
            >
              {item.itemAmount}
              {item.itemAmountType}
            </div>
          )}
          {viewItemInventoryQuantity && (
            <>
              {/* <div
                className="items-condensed"
                style={{ width: "2.5%", paddingLeft: "0.1rem" }}
              >
                <input
                  type="checkbox"
                  onClick={() => updateItemStatus(item, "unity")}
                  defaultChecked={item.isUnity}
                  style={{
                    width: "1rem",
                    height: "1rem",
                    marginTop: ".25rem",
                    padding: "auto",
                  }}
                />
              </div> */}
              <div
                className="items-condensed"
                style={{
                  width: "10%",
                  padding: "0",
                }}
              >
                <button
                  type="button"
                  onClick={() => handleInventoryIncrementDecrement(-1)}
                  style={{
                    width: "1.25rem",
                    height: "1.25rem",
                    marginTop: ".4rem",
                    marginLeft: ".25rem",
                    padding: "0",
                  }}
                  disabled={item.itemInventoryQuantity === 0}
                >
                  -
                </button>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    maxWidth: "5rem",
                    minWidth: "1rem",
                    paddingTop: "0.15rem",

                    justifyItems: "center",
                  }}
                >
                  {isEditingInventoryQuantity ? (
                    <>
                      <input
                        type="text"
                        pattern="^\d*(\.\d{0,2})?$"
                        min="0"
                        value={editableInventoryQuantity}
                        onChange={handleInventoryQuantityChange}
                        onBlur={handleInventoryQuantitySubmit}
                        autoFocus
                        style={{ maxWidth: "2rem" }}
                      />
                    </>
                  ) : (
                    <div
                      style={{ width: "auto", display: "flex" }}
                      onClick={handleInventoryQuantityClick}
                    >
                      {item.itemInventoryQuantity}
                      <p
                        style={{ margin: "0" }}
                      >{`(${itemSelectedInventoryType})`}</p>
                      {!isDefaultOrOther(proportionsTypeName) && (
                        <button
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleInventoryType(item._id);
                          }}
                          className="no-btn"
                        >
                          <RiLoopRightLine
                            style={{
                              width: "1rem",
                              height: "1rem",
                            }}
                          />
                        </button>
                      )}
                    </div>
                  )}
                </div>
                <button
                  type="button"
                  onClick={() => handleInventoryIncrementDecrement(1)}
                  style={{
                    width: "1.25rem",
                    height: "1.25rem",
                    marginTop: ".4rem",
                    marginRight: ".25rem",
                    padding: "0",
                  }}
                >
                  +
                </button>
              </div>
            </>
          )}
          {viewItemNeeded && (
            <div
              className="items-condensed"
              style={{ width: "11.5%" }}
              title={`${item.itemNeeded}${itemNeededName} | ${item.itemLeftToOrder}${itemLeftToOrderName}`}
            >
              {`${item.itemNeeded}${itemNeededName} |${
                item.itemLeftToOrder
                  ? `${item.itemLeftToOrder}${itemLeftToOrderName}`
                  : ""
              }`}
            </div>
          )}
          {viewItemTotal && (
            <div
              className="items-condensed"
              style={{ width: "5.5%" }}
              title={Math.round(item.price * item.itemQuantity * 100) / 100}
            >
              Σ{Math.round(item.price * item.itemQuantity * 100) / 100}
            </div>
          )}

          <div
            className="items-condensed"
            style={{
              width: "6.5%",
              padding: "0",
              justifyContent: "space-between",
            }}
          >
            <button
              type="button"
              onClick={() => handleItemIncrementDecrement(-1)}
              style={{
                width: "1.25rem",
                height: "1.25rem",
                marginTop: ".4rem",
                marginLeft: ".25rem",
                padding: "0",
              }}
              disabled={item.itemQuantity === 0}
            >
              -
            </button>
            <div
              style={{
                display: "flex",
                minWidth: "1rem",
                maxWidth: "3rem",
                paddingTop: "0.15rem",
                marginLeft: "1%",
                justifyItems: "center",
              }}
            >
              {isEditingItemQuantity ? (
                <input
                  type="number"
                  min="0"
                  value={editableItemQuantity}
                  onChange={handleItemQuantityChange}
                  onBlur={handleItemQuantitySubmit}
                  autoFocus
                  style={{ maxWidth: "2rem" }}
                />
              ) : (
                <div onClick={handleItemQuantityClick}>{item.itemQuantity}</div>
              )}
            </div>
            <button
              type="button"
              onClick={() => handleItemIncrementDecrement(1)}
              style={{
                width: "1.25rem",
                height: "1.25rem",
                marginTop: ".4rem",
                marginRight: "0.25rem",
                padding: "0",
              }}
            >
              +
            </button>
          </div>

          <button
            type="button"
            style={{
              width: "2.5%",
              marginTop: ".25%",
            }}
            onClick={() => setEditItem(item._id)}
            className="no-btn"
          >
            <AiFillEdit />
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default ItemCondensedO;
