import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/ItemsContainer";
import ItemKitchen from "./ItemKitchen";

const KitchenContainer = () => {
  const { itemKitchens } = useAppContext();

  return (
    <Wrapper>
      <div className="locations">
        {itemKitchens.map((itemKitchen) => {
          return (
            <ItemKitchen key={itemKitchen._id} itemKitchen={itemKitchen} />
          );
        })}
      </div>
    </Wrapper>
  );
};

export default KitchenContainer;
