import React, { useEffect, useState } from "react";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/Orders";
import OrderCostsOverTimeChart from "./Orders/Line";
import MostOrderedItemsChart from "./Orders/Bar";
import ProviderDistributionChart from "./Orders/Pie";
import BackorderedChart from "./Orders/BackorderedChart";

import { useTranslation } from "react-i18next";

const OrderChartsContainer = () => {
  const { orders, getOrders, items, getItems, timeFrame, handleChange } =
    useAppContext();
  const [orderCostsOverTimeData, setOrderCostsOverTimeData] = useState({});
  const [mostOrderedItemsData, setMostOrderedItemsData] = useState({});
  const [providerDistributionData, setProviderDistributionData] = useState({});
  const [
    providerItemStatusDistributionData,
    setProviderItemStatusDistributionData,
  ] = useState({});
  const [inventoryValue, setInventoryValue] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!orders.length) {
      getOrders();
    } else if (orders) {
      getOrders();
    }
  }, [timeFrame]);

  useEffect(() => {
    if (items.length < 1) {
      getItems();
    }
  }, []);

  useEffect(() => {
    processOrderCostsOverTime();
    processMostOrderedItems();
    processProviderDistribution();
    processProviderItemStatusDistribution();
    calculateInventoryValue();
  }, [orders, timeFrame, items]);

  const processOrderCostsOverTime = () => {
    const getWeekNumber = (date) => {
      const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
      const pastDaysOfYear =
        (date -
          firstDayOfYear +
          (firstDayOfYear.getTimezoneOffset() - date.getTimezoneOffset()) *
            60000) /
        86400000;
      return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    };

    const costsByWeek = orders.reduce((acc, order) => {
      const date = new Date(order.createdAt);
      const weekNumber = getWeekNumber(date);
      const year = date.getFullYear();
      const weekYearKey = `${year}-W${weekNumber}`;

      if (!acc[weekYearKey]) {
        acc[weekYearKey] = 0;
      }
      acc[weekYearKey] += order.totalCost;
      return acc;
    }, {});

    const sortedWeeks = Object.keys(costsByWeek).sort();
    const sortedCosts = sortedWeeks.map((week) => costsByWeek[week]);

    setOrderCostsOverTimeData({
      labels: sortedWeeks,
      datasets: [
        {
          label: t("Total Order Costs Over Time (Weekly)"),
          data: sortedCosts,
          fill: false,
          backgroundColor: "rgba(0, 85, 170, 1)",
          borderColor: "rgba(0, 85, 170, 0.2)",
          tension: 0.1,
        },
      ],
    });
  };

  const processMostOrderedItems = () => {
    const itemCosts = orders
      .flatMap((order) => order.items)
      .reduce((acc, item) => {
        if (!acc[item.itemName]) {
          acc[item.itemName] = 0;
        }
        // Directly add the totalItemCost for each item
        acc[item.itemName] += item.totalItemCost;
        return acc;
      }, {});

    const sortedItems = Object.entries(itemCosts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10);

    setMostOrderedItemsData({
      labels: sortedItems.map((item) => item[0]),
      datasets: [
        {
          label: t("Most Ordered Items by Cost"),
          data: sortedItems.map((item) => item[1]),
          backgroundColor: [
            "rgba(0, 85, 170, 0.2)",
            "rgba(109, 16, 122, 0.2)",
            "rgba(253, 140, 150, 0.2)",
            "rgba(50, 136, 27, 0.2)",
            "rgba(214, 16, 79, 0.2)",
            "rgba(232, 230, 93, 0.2)",
            "rgba(79, 204, 240, 0.2)",
            "rgba(249, 128, 30, 0.2)",
            "rgba(149, 254, 172, 0.2)",
            "rgba(54, 106, 118, 0.2)",
          ],
          borderColor: [
            "rgba(0, 85, 170, 1)",
            "rgba(109, 16, 122, 1)",
            "rgba(253, 140, 150, 1)",
            "rgba(50, 136, 27, 1)",
            "rgba(214, 16, 79, 1)",
            "rgba(232, 230, 93, 1)",
            "rgba(79, 204, 240, 1)",
            "rgba(249, 128, 30, 1)",
            "rgba(149, 254, 172, 1)",
            "rgba(54, 106, 118, 1)",
          ],
          borderWidth: 1,
        },
      ],
    });
  };

  const processProviderDistribution = () => {
    const providerCosts = orders.reduce((acc, order) => {
      const providerName = order.providerName;
      if (!acc[providerName]) {
        acc[providerName] = 0;
      }
      acc[providerName] += order.totalCost;
      return acc;
    }, {});

    // Convert to an array, sort by total cost, and take the top 10
    const sortedProviders = Object.entries(providerCosts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10);

    // Extract the labels and data from the sorted array
    const labels = sortedProviders.map(([providerName]) => providerName);
    const data = sortedProviders.map(([, totalCost]) => totalCost);

    setProviderDistributionData({
      labels,
      datasets: [
        {
          data,
          backgroundColor: [
            "rgba(0, 85, 170, 0.2)",
            "rgba(109, 16, 122, 0.2)",
            "rgba(253, 140, 150, 0.2)",
            "rgba(50, 136, 27, 0.2)",
            "rgba(214, 16, 79, 0.2)",
            "rgba(232, 230, 93, 0.2)",
            "rgba(79, 204, 240, 0.2)",
            "rgba(249, 128, 30, 0.2)",
            "rgba(149, 254, 172, 0.2)",
            "rgba(54, 106, 118, 0.2)",
          ],
          borderColor: [
            "rgba(0, 85, 170, 1)",
            "rgba(109, 16, 122, 1)",
            "rgba(253, 140, 150, 1)",
            "rgba(50, 136, 27, 1)",
            "rgba(214, 16, 79, 1)",
            "rgba(232, 230, 93, 1)",
            "rgba(79, 204, 240, 1)",
            "rgba(249, 128, 30, 1)",
            "rgba(149, 254, 172, 1)",
            "rgba(54, 106, 118, 1)",
          ],
          borderWidth: 1,
        },
      ],
    });
  };

  const processProviderItemStatusDistribution = () => {
    const providerStatusCounts = orders.reduce((acc, order) => {
      const providerName = order.providerName;
      if (!acc[providerName]) {
        acc[providerName] = { errors: 0, totalOrdered: 0 };
      }
      order.items.forEach((item) => {
        acc[providerName].totalOrdered += item.itemQuantity;
        if (item.status === "missing" || item.status === "backordered") {
          acc[providerName].errors += item.itemQuantity;
        }
      });
      return acc;
    }, {});

    const providerData = Object.entries(providerStatusCounts).map(
      ([providerName, counts]) => ({
        providerName,
        errorPercentage: ((counts.errors / counts.totalOrdered) * 100).toFixed(
          2
        ),
      })
    );

    const topProviders = providerData
      .sort((a, b) => b.errorPercentage - a.errorPercentage)
      .slice(0, 10);

    const backgroundColors = [
      "rgba(255, 99, 132, 0.2)",
      "rgba(54, 162, 235, 0.2)",
      "rgba(255, 206, 86, 0.2)",
      "rgba(75, 192, 192, 0.2)",
      "rgba(153, 102, 255, 0.2)",
      "rgba(255, 159, 64, 0.2)",
      "rgba(199, 199, 199, 0.2)",
      "rgba(83, 102, 255, 0.2)",
      "rgba(40, 180, 99, 0.2)",
      "rgba(255, 99, 132, 0.2)",
    ].slice(0, topProviders.length);

    const borderColors = [
      "rgba(255, 99, 132, 1)",
      "rgba(54, 162, 235, 1)",
      "rgba(255, 206, 86, 1)",
      "rgba(75, 192, 192, 1)",
      "rgba(153, 102, 255, 1)",
      "rgba(255, 159, 64, 1)",
      "rgba(199, 199, 199, 1)",
      "rgba(83, 102, 255, 1)",
      "rgba(40, 180, 99, 1)",
      "rgba(255, 99, 132, 1)",
    ].slice(0, topProviders.length);

    const labels = topProviders.map((data) => data.providerName);
    const errorData = topProviders.map((data) =>
      parseFloat(data.errorPercentage)
    );

    setProviderItemStatusDistributionData({
      labels,
      datasets: [
        {
          label: t("Error Rate (%)"),
          data: errorData,
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: 1,
        },
      ],
    });
  };
  const calculateInventoryValue = () => {
    if (items) {
      const totalValue = items.reduce((acc, item) => {
        return acc + item.itemInventoryQuantity * item.price;
      }, 0);
      setInventoryValue(totalValue);
    }
  };

  const pendingOrders = orders.filter((order) => order.status === "Pending");
  const displayedOrders = showAll ? pendingOrders : pendingOrders.slice(0, 5);

  const handleTimeFrameChange = (e) => {
    handleChange({ name: "timeFrame", value: e.target.value });
  };

  return (
    <>
      {orders.length === 0 ? (
        <Wrapper>
          <p>{t('No Current "Pending" Orders')}</p>
          <h3 className="totalInventory">
            {t("Current Inventory Value")}:{" "}
            <span>{inventoryValue.toFixed(2)}$</span>
          </h3>
        </Wrapper>
      ) : (
        <>
          <Wrapper>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "0.5rem",
                }}
              >
                <h4>
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: "2rem",
                    }}
                  >
                    {pendingOrders.length}
                  </span>{" "}
                  {t("pending orders")}
                </h4>
                <div className="timeframe-selector">
                  <select onChange={handleTimeFrameChange} value={timeFrame}>
                    <option value="week">{t("This Week")}</option>
                    <option value="month">{t("Last Month")}</option>
                    <option value="3months">{t("Last 3 Months")}</option>
                    <option value="6months">{t("Last 6 Months")}</option>
                    <option value="year">{t("Last Year")}</option>
                    <option value="all">{t("All Time")}</option>
                  </select>
                </div>
              </div>
              <div>
                {displayedOrders.map((order, index) => (
                  <div
                    key={index}
                    className="order-card"
                    style={{ marginBottom: "0.5rem" }}
                  >
                    <div className="order-card-header">
                      <div className="order-name-container">
                        <h4 className="order-name-edit">{order.orderName}</h4>
                      </div>
                      <div className="order-card-details">
                        <p>
                          {t("Total Cost")}: ${order.totalCost.toFixed(2)}
                        </p>
                        <p>
                          {t("Items")}: {order.items.length}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {order.orderDeliveryDay &&
                          order.orderDeliveryDay !== "None" && (
                            <div
                              className="delivery-day-icon"
                              style={{ marginRight: "1rem" }}
                            >
                              {order.orderDeliveryDay}
                            </div>
                          )}
                        <span>
                          {new Date(order.createdAt).toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
                {pendingOrders.length > 5 && (
                  <button onClick={() => setShowAll(!showAll)} className="btn">
                    {t(showAll ? "Show Less" : "Show More")}
                  </button>
                )}
              </div>
            </div>
            <h3 className="totalInventory">
              {t("Current Inventory Value")}:{" "}
              <span>{inventoryValue.toFixed(2)}$</span>
            </h3>
          </Wrapper>
          <h3 style={{ marginTop: "1rem" }}>{t("Order Data")}:</h3>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              minWidth: "90vw",
              justifyContent: "center",
            }}
          >
            <Wrapper>
              {orderCostsOverTimeData.labels && (
                <div className="chart-container">
                  <OrderCostsOverTimeChart data={orderCostsOverTimeData} />
                </div>
              )}
            </Wrapper>
            <Wrapper>
              {mostOrderedItemsData.labels && (
                <div className="chart-container">
                  <MostOrderedItemsChart data={mostOrderedItemsData} />
                </div>
              )}
            </Wrapper>
            <Wrapper>
              {providerDistributionData.labels && (
                <div className="chart-container">
                  <ProviderDistributionChart data={providerDistributionData} />
                </div>
              )}
            </Wrapper>
            <Wrapper>
              {providerItemStatusDistributionData.labels && (
                <div className="chart-container">
                  <BackorderedChart data={providerItemStatusDistributionData} />
                </div>
              )}
            </Wrapper>
          </div>
        </>
      )}
    </>
  );
};

export default OrderChartsContainer;
