import Wrapper from "../../assets/wrappers/AddItemContainer";
import { useAppContext } from "../../context/appContext";
import { useTranslation } from "react-i18next";

const AddProviderContainer = () => {
  const { t } = useTranslation();
  const { isEditingProvider, clearValues, isAddingProvider, setAddProvider } =
    useAppContext();

  return (
    <Wrapper>
      <button
        type="button"
        style={{ maxWidth: "10rem", marginLeft: "0.25rem", marginTop: "1rem" }}
        className={isEditingProvider || isAddingProvider ? `btn close` : `btn`}
        onClick={
          !isAddingProvider ? () => setAddProvider() : () => clearValues()
        }
      >
        {isEditingProvider || isAddingProvider ? t("Close") : t("Add Provider")}
      </button>
    </Wrapper>
  );
};
export default AddProviderContainer;
