import {
  ItemsContainerO,
  SearchContainer,
  AddItem,
  AddItemContainer,
  EditViewContainer,
  EditView,
} from "../../components";

const Order = () => {
  return (
    <div>
      <SearchContainer />
      <AddItem />
      <EditView />
      <div style={{ display: "flex" }}>
        <AddItemContainer />
        <EditViewContainer />
      </div>
      <ItemsContainerO />
    </div>
  );
};

export default Order;
