//import stats from "../../assets/images/stats.svg";
import Wrapper from "../../assets/wrappers/Dashboard";
//import ChartsContainer from "../../components/charts/ChartsContainer";
import OrderChartsContainer from "../../components/charts/OrderChartsContainer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToMissing = () => navigate("/missing-info");
  return (
    <Wrapper>
      <OrderChartsContainer />
      <div style={{ display: "flex" }}>
        <div className="orders-tab" onClick={navigateToMissing}>
          <div className="orders-tab-content">
            <h3>{t("Missing Info")}</h3>
            <p>{t("View incomplete item data")}</p>
          </div>
        </div>
      </div>
      {/* <ChartsContainer /> */}
    </Wrapper>
  );
};

export default Dashboard;
