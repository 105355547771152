import { useAppContext } from "../../context/appContext";
import { useState, useLayoutEffect, useMemo, useRef, useEffect } from "react";
import Wrapper from "../../assets/wrappers/ResetQuantities";
import { gsap } from "gsap";
import { useTranslation } from "react-i18next";

const ResetQuantities = ({ filteredItems }) => {
  const { t } = useTranslation();
  const {
    isResettingQuantities,
    resetItemQuantities,
    toggleResetQuantities,
    itemBackup,
    getItemBackup,
    restoreItemQuantities,
  } = useAppContext();
  const [resetItemQuantity, setResetItemQuantity] = useState(true);
  const [resetInventoryQuantity, setResetInventoryQuantity] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (Object.keys(itemBackup).length === 0) {
      getItemBackup();
    }
  }, []);

  const itemsToReset = useMemo(() => {
    return filteredItems.filter((item) => {
      if (resetItemQuantity && resetInventoryQuantity) {
        return item.itemQuantity !== 0 || item.itemInventoryQuantity !== 0;
      } else if (resetItemQuantity) {
        return item.itemQuantity !== 0;
      } else if (resetInventoryQuantity) {
        return item.itemInventoryQuantity !== 0;
      }
      return false;
    });
  }, [filteredItems, resetItemQuantity, resetInventoryQuantity]);

  const handleResetQuantities = () => {
    if (itemsToReset.length > 0) {
      resetItemQuantities(
        itemsToReset,
        resetItemQuantity,
        resetInventoryQuantity
      );
      toggleResetQuantities();
    }
  };

  const handleRestoreClick = () => {
    restoreItemQuantities();
  };

  //ANIMATIONS
  useLayoutEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight + 45);
    }

    const tl = gsap.timeline();

    if (isResettingQuantities) {
      setIsVisible(true);
      tl.set(contentRef.current, {
        height: 0,
        padding: 0,
        autoAlpha: 0,
      }).to(contentRef.current, {
        height: contentHeight,
        padding: "1rem 1rem 1rem 1rem",
        autoAlpha: 1,
        duration: 0.5,
      });
    } else if (contentRef.current) {
      tl.to(contentRef.current, {
        height: 0,
        padding: 0,
        autoAlpha: 0,
        duration: 0.5,
      }).then(() => setIsVisible(false));
    }

    return () => tl.kill();
  }, [isResettingQuantities, contentHeight]);

  if (!isVisible && !isResettingQuantities) {
    return null;
  }
  return (
    <Wrapper>
      <div ref={contentRef} className="reset-quantities-container">
        <h4>{t("Reset Quantities")}</h4>
        <div className="reset-container">
          <div className="reset-options">
            <div className="reset-option">
              <input
                type="checkbox"
                checked={resetInventoryQuantity}
                onChange={() =>
                  setResetInventoryQuantity(!resetInventoryQuantity)
                }
              />

              <h5>{t("Reset Inventory Quantity")}</h5>
            </div>
            <div className="reset-option">
              <input
                type="checkbox"
                checked={resetItemQuantity}
                onChange={() => setResetItemQuantity(!resetItemQuantity)}
              />
              <h5>{t("Reset Order Quantity")}</h5>
            </div>
          </div>
          <div className="reset-button-container">
            <p>{t("Reset items", { count: itemsToReset.length })}</p>
            <button className="btn btn-danger" onClick={handleResetQuantities}>
              {t("Reset Quantities")}
            </button>
          </div>
        </div>
        {itemBackup && itemBackup.updatedAt ? (
          <div>
            <h4>{t("Item quantities backup")}</h4>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "0.5rem",
              }}
            >
              <p>
                {t("Last backup")}:{" "}
                {new Date(itemBackup.updatedAt).toLocaleString(undefined, {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </p>
              <p>
                {t("Number of Items")}: {itemBackup.itemCount || 0}
              </p>
              <button className="btn btn-green" onClick={handleRestoreClick}>
                {t("Restore Quantities")}
              </button>
            </div>
          </div>
        ) : (
          <p>{t("No backup data available.")}</p>
        )}
      </div>
    </Wrapper>
  );
};

export default ResetQuantities;
