import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/Provider";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Menu = ({ menu }) => {
  const { t } = useTranslation();
  const { isEditingMenu, clearValues, setEditMenu, deleteMenu, setMenuItems } =
    useAppContext();
  const navigate = useNavigate();

  const navigateToMenuItem = () => {
    navigate(`/menu/${menu._id}`);
  };
  return (
    <Wrapper>
      <div className="menu-container" onClick={navigateToMenuItem}>
        <div
          className="provider-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <h2>{menu.menuName}</h2>
          <div className="actions">
            <button
              type="button"
              className="btn edit-btn"
              onClick={(e) => {
                e.stopPropagation();
                setEditMenu(menu._id);
              }}
            >
              {t("Edit")}
            </button>
            {/* <button
              type="button"
              className="btn delete-btn"
              onClick={(e) => {
                e.stopPropagation();
                deleteMenu(menu._id);
              }}
            >
              Delete
            </button> */}
          </div>
        </div>
        {menu.menuDescription && (
          <div className="provider-content">
            <div className="provider-info">
              <h4>{menu.menuDescription}</h4>
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default Menu;
