import styled from "styled-components";

const Wrapper = styled.nav`
  z-index: 1;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 0px 0px rgba(0, 0, 0, 0.1);
  background: var(--white);
  position: fixed;
  top: 0;
  left: auto;
  width: 100%;

  .nav-center {
    display: flex;
    flex-direction: row;
    width: 90vw;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    display: flex;
    align-items: center;
    margin-top: 2px;
    margin-left: 3rem;
    width: 100px;
  }
  .toggle-btn {
    background: transparent;
    border-color: transparent;
    font-size: 1.75rem;
    color: var(--primary-500);
    cursor: pointer;
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
  }

  .btn-container {
    position: relative;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 0.5rem;
    position: relative;
    box-shadow: var(--shadow-2);
  }
  .btn:hover {
    box-shadow: var(--shadow-4);
  }

  .dropdown {
    position: absolute;
    top: 36px;
    left: 0;
    width: 100%;
    background: var(--primary-100);
    box-shadow: var(--shadow-2);
    padding: 0.5rem;
    text-align: center;
    visibility: hidden;
    border-radius: var(--borderRadius);
  }
  .dropdown:hover {
    box-shadow: var(--shadow-4);
  }
  .show-dropdown {
    visibility: visible;
  }
  .dropdown-btn {
    background: transparent;
    border-color: transparent;
    color: var(--primary-500);
    letter-spacing: var(--letterSpacing);
    text-transform: capitalize;
    cursor: pointer;
  }

  .logo-text {
    display: none;
    margin: 0;
  }
  @media (min-width: 992px) {
    height: 4.5rem;
    .toggle-btn {
      top: 1.25rem;
      left: 1.25rem;
    }

    .nav-center {
      width: 95%;
    }
    .logo {
      display: none;
    }
    .logo-text {
      display: block;
      margin-left: 35vw;
    }
  }
`;
export default Wrapper;
