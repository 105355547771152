import { FormRow } from "..";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/AddItemLocation";
import { useTranslation } from "react-i18next";

const AddItemKitchen = () => {
  const {
    isLoading,
    isAddingKitchen,
    isEditingKitchen,
    displayAlert,
    kitchenName,
    handleChange,
    createItemKitchen,
    editItemKitchen,
    editItemKitchenId,
    deleteItemKitchen,
  } = useAppContext();
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!kitchenName) {
      displayAlert();
      return;
    }
    if (isEditingKitchen) {
      editItemKitchen();
      return;
    }
    createItemKitchen();
  };

  const handleItemInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({ name, value });
  };

  return (
    <Wrapper>
      <form className={isAddingKitchen ? "form_active" : "form"}>
        {/* <h6>{isEditingLocation ? "edit location" : "add location"} </h6> */}
        <div className="form-center">
          <FormRow
            type="text"
            name="kitchenName"
            value={kitchenName}
            handleChange={handleItemInput}
            labelText={t("Kitchen Name")}
          />
          <div className="btn-container">
            <button
              type="submit"
              className="btn btn-block submit-btn"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {t("Submit")}
            </button>

            {isEditingKitchen && (
              <button
                type="button"
                className="btn delete-btn"
                onClick={() => deleteItemKitchen(editItemKitchenId)}
              >
                {t("Delete")}
              </button>
            )}
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

export default AddItemKitchen;
