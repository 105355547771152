import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 1rem;

  .orders-tab {
    margin: 1rem;

    width: 180px;
    height: 180px;
    background: var(--white);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: var(--shadow-2);
    transition: transform 0.3s ease;
  }

  .orders-tab:hover {
    transform: scale(1.05);
  }

  .orders-tab-content {
    text-align: center;
    padding: 1rem;
  }

  .orders-tab h3 {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    font-size: 1.25rem;
  }

  .orders-tab p {
    font-size: 1em;
    color: #555;
    font-weight: 500;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.1);
    margin-top: 0;
  }
`;
export default Wrapper;
