import { FormRow } from "..";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/AddItem";
import { useEffect, useState, useMemo, useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { useTranslation } from "react-i18next";

const AddOrder = ({ filteredItems }) => {
  const { t } = useTranslation();
  const {
    isLoading,
    isCreatingOrder,
    orderName,
    items,
    providers,
    handleChange,
    createOrder,
    displayAlert,
    clearValues,
    searchKitchen,
  } = useAppContext();
  const [groupedOrders, setGroupedOrders] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);

  function groupItemsByProviders(items, providers) {
    return items.reduce((acc, item) => {
      if (item.itemQuantity > 0) {
        const providerId = item.provider;
        const provider = providers.find((p) => p._id === providerId);
        if (!acc[providerId]) {
          acc[providerId] = {
            providerId,
            providerName: provider?.providerName || "Unknown",
            deliveryDays: provider?.deliveryDay || [],
            items: [],
            selectedDeliveryDay: "",
            minimumOrder: provider?.minimumOrder || 0,
          };
        }
        acc[providerId].items.push(item);
      }
      return acc;
    }, {});
  }

  useEffect(() => {
    if (isCreatingOrder) {
      const groupedItems = groupItemsByProviders(filteredItems, providers);
      const ordersWithNames = Object.entries(groupedItems).map(
        ([providerId, orderInfo]) => {
          const orderItems = orderInfo.items || [];
          const totalCost = orderItems.reduce(
            (sum, item) => sum + parseFloat(item.price) * item.itemQuantity,
            0
          );
          return {
            providerId,
            provider: [orderInfo.providerId, orderInfo.providerName],
            deliveryDays: orderInfo.deliveryDays,
            selectedDeliveryDay: orderInfo.selectedDeliveryDay,
            orderName: `${
              searchKitchen !== "all" ? searchKitchen + " - " : ""
            }${orderInfo.providerName}`,
            items: orderItems,
            totalCost,
            notes: "",
            minimumOrder: orderInfo.minimumOrder,
          };
        }
      );
      setGroupedOrders(ordersWithNames);
    }
  }, [isCreatingOrder, filteredItems, providers, searchKitchen]);

  const handleOrderNameChange = (index, newName) => {
    const updatedOrders = [...groupedOrders];
    updatedOrders[index].orderName = newName;
    setGroupedOrders(updatedOrders);
  };

  const handleNotesChange = (index, newNotes) => {
    const updatedOrders = [...groupedOrders];
    updatedOrders[index].notes = newNotes;
    setGroupedOrders(updatedOrders);
  };
  const handleDeliveryDayChange = (providerId, day) => {
    const updatedOrders = groupedOrders.map((order) => {
      if (order.providerId === providerId) {
        const newSelectedDay = order.selectedDeliveryDay === day ? "" : day;
        return { ...order, selectedDeliveryDay: newSelectedDay };
      }
      return order;
    });
    setGroupedOrders(updatedOrders);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    groupedOrders.forEach((order) => {
      if (!order.orderName) {
        displayAlert();
        return;
      }
      createOrder(
        order.totalCost,
        order.items,
        order.providerId,
        order.provider[1],
        order.orderName,
        order.notes,
        order.selectedDeliveryDay
      );
    });

    clearValues();
  };

  //ANIMATIONS
  useLayoutEffect(() => {
    const tl = gsap.timeline();

    if (isCreatingOrder) {
      setIsVisible(true);
      tl.set(contentRef.current, {
        height: 0,
        padding: 0,
        autoAlpha: 0,
      }).to(contentRef.current, {
        height: "auto",
        padding: "1rem 1rem 1rem 1rem",
        autoAlpha: 1,
        duration: 0.5,
      });
    } else if (contentRef.current) {
      tl.to(contentRef.current, {
        height: 0,
        padding: 0,
        autoAlpha: 0,
        duration: 0.5,
      }).then(() => setIsVisible(false));
    }

    return () => tl.kill();
  }, [isCreatingOrder]);

  if (!isVisible && !isCreatingOrder) {
    return null;
  }
  return (
    <Wrapper>
      <div ref={contentRef} className={`form_order_active`}>
        {groupedOrders.length > 0 ? (
          <form>
            <div className="form-order-center">
              <h4 style={{ margin: "0.75rem" }}>{t("Create Order")}</h4>
              {groupedOrders.map((order, index) => (
                <div key={order.providerId} className="order-container">
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "0.5rem",
                    }}
                  >
                    <FormRow
                      type="text"
                      name={`${index + 1} - ${order.provider[1]}`}
                      value={order.orderName}
                      handleChange={(e) =>
                        handleOrderNameChange(index, e.target.value)
                      }
                    />
                    <div className="day-selector-container">
                      {order.deliveryDays.map((day) => (
                        <button
                          key={day}
                          type="button"
                          className={`day-selector-button ${
                            order.selectedDeliveryDay === day ? "selected" : ""
                          }`}
                          onClick={() =>
                            handleDeliveryDayChange(order.providerId, day)
                          }
                        >
                          {t(day)}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="order-details">
                    <h5
                      style={{
                        color:
                          order.totalCost >= order.minimumOrder
                            ? "#009150"
                            : "#a91b0d",
                      }}
                    >
                      {t("Total Cost")}: ${order.totalCost.toFixed(2)}
                    </h5>
                    <h5 style={{ marginLeft: "0.5rem" }}>
                      {t("Number of Items")}: {order.items.length}
                    </h5>
                  </div>
                  <FormRow
                    type="text"
                    name="notes"
                    value={order.notes}
                    handleChange={(e) =>
                      handleNotesChange(index, e.target.value)
                    }
                  />
                </div>
              ))}
              <button
                style={{ marginTop: "1.5rem" }}
                type="submit"
                className="btn submit-btn"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {t("Create All Orders")}
              </button>
            </div>
          </form>
        ) : (
          <div>
            <h3 style={{ margin: 0 }}>{t("No Items Available for Order")}</h3>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default AddOrder;
