import styled from "styled-components";

const Wrapper = styled.aside`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  h3 {
    margin-top: 0;
  }

  .form {
    display: none;
    margin: 0;
    border-radius: 0;

    box-shadow: none;

    max-width: 100%;
    width: 100%;
  }

  .form_active {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0.5rem;
    background-color: var(--white);
    box-shadow: var(--shadow-2);
    border-radius: 10px;

    margin-top: 0.5rem;
    width: auto;
    justify-self: flex-end;

    @media (min-width: 768px) {
      width: fit-content;
    }
  }

  .form-row {
    margin-bottom: 0;
  }
  .form-center {
    display: flex;
    flex-wrap: wrap;
  }
  .form-center button {
    align-self: flex-end;
    height: 35px;
    margin-top: 0.5rem;

    @media (min-width: 768px) {
      margin-top: 1rem;
    }
  }
  .btn-container {
    display: flex;
    justify-content: space-between;

    margin-top: 0rem;
    width: auto;
    button {
      height: 35px;
    }
  }
  .clear-btn {
    background: var(--grey-500);
  }
  .clear-btn:hover {
    background: var(--black);
  }
  .delete-btn {
    color: var(--white);
    background: var(--red-light);

    :hover {
      background: var(--red-dark);
    }
  }
`;
export default Wrapper;
