import styled from "styled-components";

const Wrapper = styled.div`
  .report-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    transition: box-shadow 0.3s ease;
    cursor: pointer;
  }

  .report-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  .report-card-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .report-card-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .report-card-header h4 {
    margin: 0;
    font-size: 1.2rem;
    color: #333;
  }

  .report-card-header p {
    margin: 0;
    color: #666;
    font-size: 0.9rem;
  }

  .report-card-details {
    display: none;
    margin-top: 0.75rem;
    border-top: 1px solid #eee;
  }

  .report-card.expanded .report-card-details {
    display: block;
  }

  .report-category h5 {
    position: relative;
    color: #333;
    margin: 0.75rem 0 0 0;
    font-size: 1.1rem;
  }
  .report-category h5::after {
    content: "";
    display: block;
    width: 10rem;
    height: 1px;
    background-color: #000;
    position: absolute;
    left: 0;
  }

  .report-category ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .report-category li {
    margin: 0 0;
    color: #666;
    font-size: 0.9rem;
  }
`;
export default Wrapper;
