import styled from "styled-components";

const Wrapper = styled.aside`
  h3 {
    margin-top: 0;
  }

  .form {
    display: none;
    margin: 0;
    border-radius: 0;

    box-shadow: none;

    max-width: 100%;
    width: 100%;
  }

  .form_active {
    display: flex;
    padding: 0.25rem 0.25rem 0.2rem;

    @media (min-width: 768px) {
    }
  }

  .form-row {
    margin-bottom: 0;
  }
  .form-center {
    display: grid;
    row-gap: 0.25rem;
  }
  .form-center button {
    align-self: end;
    height: 35px;

    @media (min-width: 768px) {
      margin-top: 1rem;
    }
  }
  .btn-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    align-self: flex-end;
    margin-top: 0rem;
    button {
      height: 35px;
    }
  }
  .clear-btn {
    background: var(--grey-500);
  }
  .clear-btn:hover {
    background: var(--black);
  }
  .delete-btn {
    color: var(--white);
    background: var(--red-light);

    :hover {
      background: var(--red-dark);
    }
  }
  @media (min-width: 992px) {
    .form-center {
      grid-template-columns: 1fr 1fr;
      align-items: center;
      column-gap: 1rem;
    }
    .btn-container {
      margin-top: 0;
    }
  }
  @media (min-width: 1120px) {
    .form-center {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .form-center button {
      margin-top: 0;
    }
  }
`;
export default Wrapper;
