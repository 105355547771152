import Wrapper from "../assets/wrappers/Navbar";
import { FaAlignLeft, FaUserCircle, FaCaretDown } from "react-icons/fa";
import { useAppContext } from "../context/appContext";
import Logo from "./Logo";
// import DarkSwitch from "./DarkSwitch";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const [showLogout, setShowLogout] = useState(false);
  const { toggleSidebar, logoutUser, user, showSidebar } = useAppContext();
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className="nav-center">
        <button
          type="button"
          className={`toggle-btn ${showSidebar ? "active" : ""}`}
          onClick={toggleSidebar}
        >
          <FaAlignLeft />
        </button>
        <div style={{ marginTop: "0.25rem" }}>
          <Logo />
          {/* <h3 className="logo-text">dashboard</h3> */}
        </div>
        {/* <div>
          <DarkSwitch />
        </div> */}
        <div className="btn-container">
          <button
            type="button"
            className="btn"
            onClick={() => setShowLogout(!showLogout)}
          >
            <FaUserCircle />
            {user?.name}
            <FaCaretDown />
          </button>
          <div className={showLogout ? "dropdown show-dropdown" : "dropdown"}>
            <button type="button" className="dropdown-btn" onClick={logoutUser}>
              {t("Logout")}
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Navbar;
