import styled from "styled-components";

const Wrapper = styled.aside`
  margin-top: 0;
  button {
    font-size: 1.15rem;
    width: 10rem;
    height: 3rem;
  }
  .error-percentage-container {
    padding: 0 0.25rem;
    border: 1px solid var(--grey-300);
    background-color: white;
    border-radius: 10px;
    box-shadow: var(--shadow-2);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
  }
  .error-edit-btn {
    border: none;
    background-color: var(--primary-grey);
    width: 2rem;
    height: 1.5rem;
    margin-top: 0.15rem;
  }
  .error-edit-btn:hover {
    cursor: pointer;
  }
`;
export default Wrapper;
