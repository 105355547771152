import Wrapper from "../../assets/wrappers/Orders";
import { useState } from "react";

import { FaEdit, FaCheck } from "react-icons/fa";

const ItemCompare = ({ item, toggleSelectedItem, updateItemPrice }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handlePriceChange = (event) => {
    const newPrice = event.target.value;
    updateItemPrice(item.order_item_id, newPrice);
  };

  const handleToggleEditing = (e) => {
    e.stopPropagation();
    setIsEditing(!isEditing);
  };

  const priceMatches =
    parseFloat(item.unit_price) === parseFloat(item.originalPrice);

  return (
    <Wrapper>
      <div
        style={{
          flexDirection: "row",
          alignItems: "flex-end",
          flexWrap: "wrap",
          cursor: "pointer",
        }}
        className="order-item-detail"
        onClick={(e) => {
          if (!priceMatches) {
            toggleSelectedItem(item.order_item_id, !item.isSelected);
          }
          e.stopPropagation();
        }}
      >
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <input
            type="checkbox"
            checked={item.isSelected}
            onChange={(e) => {
              e.stopPropagation();
            }}
            disabled={priceMatches}
            style={{ alignSelf: "center", marginRight: "0.5rem" }}
          />
          {/* <h4 style={{ marginRight: "10px" }}>{item.quantity}</h4> */}
          <h2>{item.order_item_name}</h2>
          {/* <h5 className="itemProportions">{item.format}</h5> */}
          <p
            style={{
              color: "var(--green-light)",
              fontWeight: "800",
              marginRight: "0.5rem",
            }}
          >
            {item.provider}
          </p>
        </div>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <h3>
            Current price:{" "}
            <span
              style={{
                color: priceMatches ? "var(--green-light)" : "var(--red-light)",
              }}
            >
              {item.originalPrice}$
            </span>
          </h3>
          <h3 style={{ display: "flex" }}>
            New price:
            {isEditing ? (
              <input
                type="number"
                value={item.unit_price}
                onChange={handlePriceChange}
                onBlur={() => setIsEditing(false)}
                autoFocus
              />
            ) : (
              <span style={{ color: "var(--green-light)" }}>
                {" "}
                {item.unit_price}$
              </span>
            )}
            <button
              type="button"
              style={{
                background: "none",
                border: "none",
                padding: "0 0 0 0.25rem",
              }}
              onClick={handleToggleEditing}
            >
              {isEditing ? (
                <FaCheck className="order-edit-icon" />
              ) : (
                <FaEdit className="order-edit-icon" />
              )}
            </button>
          </h3>
        </div>
      </div>
    </Wrapper>
  );
};

export default ItemCompare;
