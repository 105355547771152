import { Link } from "react-router-dom";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/Item";

const ItemAlternative = ({ item }) => {
  const { addItemAlternative, providers } = useAppContext();
  const selectedProvider = providers.find(
    (provider) => provider._id === item.provider
  );
  const providerName = selectedProvider ? selectedProvider.providerName : "";
  return (
    <Wrapper>
      <div className={`${item.isBackordered ? "active" : ""}`}>
        <header>
          <div className="info">
            <h5>{item.itemName}</h5>
            <div className="info23">
              <div className="info2">
                <p>{item.itemSupplier}</p>
              </div>
              <div className="info3">
                <p>{providerName}</p>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn"
            style={{
              height: "1.5rem",
              minWidth: "1.5rem",
              padding: "0.25rem",
              marginLeft: "auto",
            }}
            onClick={() => addItemAlternative(item)}
          >
            +
          </button>
        </header>
      </div>
    </Wrapper>
  );
};

export default ItemAlternative;
