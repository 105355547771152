import { useAppContext } from "../../context/appContext";
import { useEffect } from "react";
import Alert from "../Alert";
import Loading from "../Loading";
import Provider from "./Provider";
import Wrapper from "../../assets/wrappers/ProvidersContainer";
import { useTranslation } from "react-i18next";

const ProvidersContainer = () => {
  const { t } = useTranslation();
  const { getProviders, providers, isLoading, totalProviders } =
    useAppContext();

  const providerMap = () => {
    providers.map((provider) => {
      return provider.providerName;
    });
  };
  providerMap();

  useEffect(() => {
    getProviders();
  }, []);

  if (!isLoading && providers.length === 0) {
    return (
      <Wrapper>
        <h2>{t("No providers to display...")}</h2>
      </Wrapper>
    );
  }
  const getPluralForm = (count, single, plural) => {
    return count === 1 ? single : plural;
  };

  return (
    <Wrapper>
      <div className="providers-title">
        <h5 className="providers-title-h5">
          {providers.length}{" "}
          {getPluralForm(
            providers.length,
            t("provider found"),
            t("providers found")
          )}
        </h5>
      </div>

      <div className={`providers`}>
        {providers.map((provider) => {
          return <Provider key={provider._id} provider={provider} />;
        })}
      </div>
    </Wrapper>
  );
};

export default ProvidersContainer;
