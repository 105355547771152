import { useAppContext } from "../../context/appContext";
import { useEffect, useState } from "react";
import Alert from "../Alert";
import Loading from "../Loading";
import ItemAlternative from "./ItemAlternative";
import Wrapper from "../../assets/wrappers/ItemsContainer";

const ItemsAlternativesContainer = () => {
  const {
    getItemAlternatives,
    itemAlternatives,
    isLoading,
    showAlert,
    searchAlternatives,
    selectedAlternatives,
    editItemId,
    items,
  } = useAppContext();

  const filteredItems = items
    .filter((item) => {
      const matchesSearchTerm = item.itemName
        .toLowerCase()
        .includes(searchAlternatives.toLowerCase());
      const isNotEditedItem = item._id !== editItemId;
      const isSelectedAlternative = !selectedAlternatives.some(
        (selectedItem) => selectedItem._id === item._id
      );

      return matchesSearchTerm && isNotEditedItem && isSelectedAlternative;
    })
    .slice(0, 5);

  return (
    <Wrapper>
      <div className={`jobs`}>
        {isLoading ? (
          <p>Loading alternatives...</p>
        ) : (
          filteredItems.map((item) => (
            <ItemAlternative key={item._id} item={item} />
          ))
        )}
      </div>
    </Wrapper>
  );
};

export default ItemsAlternativesContainer;
