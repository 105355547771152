import { useAppContext } from "../../context/appContext";
import { useEffect, useState } from "react";
import Alert from "../Alert";
import Loading from "../Loading";
import Order from "./OrderPreview";
import Wrapper from "../../assets/wrappers/Orders";
import { useTranslation } from "react-i18next";

const OrdersContainer = () => {
  const { t } = useTranslation();
  const {
    getOrders,
    orders,
    isLoading,
    timeFrame,
    handleChange,
    toggleSendOrders,
    isSendingOrders,
  } = useAppContext();
  const [activeStatus, setActiveStatus] = useState("Pending");
  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    getOrders();
  }, [timeFrame]);

  useEffect(() => {
    const filtered = orders.filter((order) => order.status === activeStatus);
    setFilteredOrders(filtered);
  }, [orders, activeStatus]);

  const handleTimeFrameChange = (e) => {
    handleChange({ name: "timeFrame", value: e.target.value });
  };
  const getOrderStatusText = (count, status) => {
    // Single and plural keys for status
    const statusKey = `${status} ${count === 1 ? "order" : "orders"}`;
    const foundKey = `found ${count === 1 ? "single" : "plural"}`;

    // Construct the status text based on count and language
    const statusText = t(statusKey);
    const foundText = t(foundKey);
    return `${count} ${statusText} ${foundText}`;
  };
  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "1rem",
        }}
      >
        <div className="orders-filter">
          <button
            onClick={() => setActiveStatus("Pending")}
            className={activeStatus === "Pending" ? "active" : ""}
          >
            {t("Pending")}
          </button>
          <button
            onClick={() => setActiveStatus("Completed")}
            className={activeStatus === "Completed" ? "active" : ""}
          >
            {t("Completed")}
          </button>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            style={{ marginRight: "0.25rem", height: "auto" }}
            className={isSendingOrders ? `btn btn-danger ` : `btn`}
            onClick={() => toggleSendOrders()}
          >
            {isSendingOrders ? t("Close") : t("Send")}
          </button>
          <div className="timeframe-selector">
            <select onChange={handleTimeFrameChange} value={timeFrame}>
              <option value="week">{t("This Week")}</option>
              <option value="month">{t("Last Month")}</option>
              <option value="3months">{t("Last 3 Months")}</option>
              <option value="6months">{t("Last 6 Months")}</option>
              <option value="year">{t("Last Year")}</option>
              <option value="all">{t("All Time")}</option>
            </select>
          </div>
        </div>
      </div>
      {isLoading ? (
        <h2>{t("Loading...")}</h2>
      ) : filteredOrders.length > 0 ? (
        <>
          <div className="orders-title">
            <h5>{getOrderStatusText(filteredOrders.length, activeStatus)}</h5>
          </div>
          <div className="orders-list">
            {filteredOrders.map((order) => (
              <Order key={order._id} order={order} />
            ))}
          </div>
        </>
      ) : (
        <h2 style={{ marginTop: "1rem" }}>
          {t("No")} {t(activeStatus)} {t("orders to display...")}
        </h2>
      )}
    </Wrapper>
  );
};

export default OrdersContainer;
