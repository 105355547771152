import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/ItemsContainer";
import ItemLocation from "./ItemLocation";

const LocationContainer = () => {
  const { itemLocations } = useAppContext();

  return (
    <Wrapper>
      <div className="locations">
        {itemLocations.map((itemLocation) => {
          return (
            <ItemLocation key={itemLocation._id} itemLocation={itemLocation} />
          );
        })}
      </div>
    </Wrapper>
  );
};

export default LocationContainer;
