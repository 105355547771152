import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/Item";
import { FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Alternative = ({ item }) => {
  const { t } = useTranslation();
  const {
    items,
    editItemId,
    removeItemAlternative,
    updateItemQuantity,
    providers,
    setEditItem,
  } = useAppContext();
  const actualItem = items.find((globalItem) => globalItem._id === item._id);
  const displayItem = actualItem || item;
  const selectedProvider = providers.find(
    (provider) => provider._id === item.provider
  );

  const providerName = selectedProvider ? selectedProvider.providerName : "";

  const handleIncrement = () => {
    updateItemQuantity(actualItem, actualItem.itemQuantity + 1);
  };

  const handleDecrement = () => {
    if (actualItem.itemQuantity > 0) {
      updateItemQuantity(actualItem, actualItem.itemQuantity - 1);
    }
  };
  const currentEditingItem = items.find((item) => item._id === editItemId);

  return (
    <Wrapper style={{ margin: "0", marginBottom: "0.5rem" }}>
      <div className={`${item.isBackordered ? "active" : ""}`}>
        <div className="item-alternative-container">
          <div
            className="info"
            style={{ flexWrap: "wrap", justifyContent: "flex-start" }}
          >
            <h5>{displayItem.itemName}</h5>
            <div className="info23" style={{ width: "auto" }}>
              <div className="info2">
                <p>{displayItem.itemSupplier}</p>
              </div>
              <div className="info3" style={{ marginLeft: "0.5rem" }}>
                <p>{providerName}</p>
              </div>
            </div>
          </div>
          {actualItem ? (
            <div className="item-actions-alternative">
              <button
                type="button"
                className="btn edit-btn"
                onClick={() => setEditItem(item._id)}
              >
                {t("Edit")}
              </button>
              <div className="item-quantity-alternative">
                <button
                  style={{ maxHeight: "2rem", maxWidth: "2rem" }}
                  type="button"
                  className="btn"
                  onClick={handleDecrement}
                  disabled={actualItem.itemQuantity === 0}
                >
                  -
                </button>
                <div className="quantity">{actualItem.itemQuantity}</div>
                <button
                  type="button"
                  className="btn"
                  style={{ maxHeight: "2rem", maxWidth: "2rem" }}
                  onClick={handleIncrement}
                >
                  +
                </button>
              </div>
            </div>
          ) : (
            <div className="item-deleted-message">
              {t("item-deleted-message")}
            </div>
          )}
          {currentEditingItem &&
            currentEditingItem.selectedAlternatives &&
            currentEditingItem.selectedAlternatives.length > 0 && (
              <button
                type="button"
                className="btn"
                style={{
                  height: "2rem",
                  minWidth: "2rem",
                  padding: "0",
                  marginLeft: "0.5rem",
                }}
                onClick={() => removeItemAlternative(item)}
              >
                <FaTimes
                  style={{
                    width: "1rem",
                    height: "1rem",
                    marginTop: "0.25rem",
                  }}
                />
              </button>
            )}
        </div>
      </div>
    </Wrapper>
  );
};

export default Alternative;
