import { useState, useEffect } from "react";
import { useAppContext } from "../../context/appContext";
import { FormRow, FormRowSelect } from "..";
import { useTranslation } from "react-i18next";

const MissingItem = ({ item, getMissingInfoCount }) => {
  const { t } = useTranslation();
  const {
    setEditItem,
    providers,
    editItemId,
    editItem,
    itemProportions,
    price,
    itemCode,
    itemAmount,
    itemAmountType,
    itemAmountTypeOptions,
    provider,
    itemSupplier,
    itemNeeded,
    itemNeededType,
    itemLeftToOrder,
    itemLeftToOrderType,
    itemTypes,
    itemTypeOptions,
    itemLocations,
    itemCategories,
    providerOptions,
    itemLocationOptions,
    itemLocation,
    itemCategory,
    itemCategoryOptions,
    itemProportionsType,
    itemProportionsAmount,
    itemProportionsAmountType,
    handleChange,
    clearValues,
  } = useAppContext();

  const [isEditVisible, setIsEditVisible] = useState(false);
  const [fieldsToRender, setFieldsToRender] = useState({});

  const selectedProvider = providers.find((p) => p._id === item.provider);
  const selectedType = itemTypes.find((type) => type._id === item.itemType);
  const selectedNeededType = itemTypes.find(
    (type) => type._id === item.itemNeededType
  );
  const selectedLeftToOrderType = itemTypes.find(
    (type) => type._id === item.itemLeftToOrderType
  );

  const providerName = selectedProvider
    ? selectedProvider.providerName
    : "Unknown Provider";
  const itemTypeName = selectedType ? selectedType.typeName : "Default";
  const itemNeededName = selectedNeededType
    ? selectedNeededType.typeName
    : "Default";
  const itemLeftToOrderName = selectedLeftToOrderType
    ? selectedLeftToOrderType.typeName
    : "Default";

  useEffect(() => {
    const initialFieldsToRender = {
      price: item.price === 0 || item.price === null,
      itemProportions: !item.itemProportions,
      itemSupplier: !item.itemSupplier,
      provider: !item.provider || providerName === "Unknown Provider",
      itemType: itemTypeName === "Default",
      itemAmount: item.itemAmount === 0,
      itemAmountType: item.itemAmountType === "Other",
      itemNeeded: !item.itemNeeded || item.itemNeeded === "0",
      itemNeededType: itemNeededName === "Default",
      itemLeftToOrder: !item.itemLeftToOrder || item.itemLeftToOrder === "0",
      itemLeftToOrderType: itemLeftToOrderName === "Default",
      itemCode: !item.itemCode,
      itemLocation:
        itemLocations.find((l) => l._id === item.itemLocation)?.locationName ===
        "Default",
      itemCategory:
        itemCategories.find((c) => c._id === item.itemCategory)
          ?.categoryName === "Default",
    };
    setFieldsToRender(initialFieldsToRender);
  }, [item]);

  const handleSetEditItem = (currentItemId) => {
    if (editItemId === currentItemId) {
      setIsEditVisible(!isEditVisible);
      clearValues();
    } else {
      setEditItem(currentItemId);
      setIsEditVisible(true);
    }
  };

  const handleItemInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({ name, value });
  };
  const missingInfoCount = getMissingInfoCount(item);

  const handleSaveChanges = (e) => {
    e.preventDefault();
    editItem();
  };
  return (
    <div className="missing-item-container">
      <div className="missing-info-item">
        <div className="item-info">
          <h4>{item.itemName}</h4>
          <p>{providerName}</p>
        </div>
        <div className="missing-edit-container">
          <h6>
            {missingInfoCount + " "} {t("missingInformation")}
          </h6>
          <button
            type="button"
            className={`btn ${
              isEditVisible && editItemId === item._id
                ? "btn-danger"
                : "edit-btn"
            }`}
            onClick={() => handleSetEditItem(item._id)}
          >
            {isEditVisible && editItemId === item._id ? t("Close") : t("Edit")}
          </button>
        </div>
      </div>
      {isEditVisible && editItemId === item._id && (
        <form className="edit-container">
          {fieldsToRender.price && (
            <FormRow
              type="number"
              name="price"
              value={price}
              handleChange={handleItemInput}
              labelText={t("Price")}
            />
          )}
          {fieldsToRender.itemSupplier && (
            <FormRow
              type="text"
              name="itemSupplier"
              value={itemSupplier}
              handleChange={handleItemInput}
              labelText={t("Supplier")}
            />
          )}
          {fieldsToRender.provider && (
            <FormRowSelect
              name="provider"
              value={provider}
              handleChange={handleItemInput}
              labelText={t("Provider")}
              list={providerOptions}
            />
          )}
          {fieldsToRender.itemProportions && (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                backgroundColor: "var(--grey-100)",
                padding: "0.5rem",
                borderRadius: "10px",
              }}
            >
              <FormRow
                type="text"
                name="itemProportions"
                value={itemProportions}
                handleChange={handleItemInput}
                labelText={t("SubItem Quantity")}
                tooltipContent={t(
                  "How many subItems are there in the container. Ex: a case of 12x500ml bottles has 12. If the Item does not have any sub-item, leave this entry empty"
                )}
              />
              <FormRowSelect
                name="itemProportionsType"
                labelText={t("Sub-item Type")}
                value={itemProportionsType}
                handleChange={handleItemInput}
                list={itemTypeOptions}
                tooltipContent={t(
                  "What type is the subItem. Ex: a case of 12x500ml is bottles or packages"
                )}
              />
              <FormRow
                type="number"
                name="itemProportionsAmount"
                labelText={t("Sub-item Amount")}
                value={itemProportionsAmount}
                handleChange={handleItemInput}
                tooltipContent={t(
                  "Individual subItem amount. Ex: 12x500ml, amount is 500ml"
                )}
              />
              <FormRowSelect
                name="itemProportionsAmountType"
                labelText={t("Sub-item Amount Unit")}
                value={itemProportionsAmountType}
                handleChange={handleItemInput}
                list={itemAmountTypeOptions}
              />
            </div>
          )}

          {fieldsToRender.itemAmount && (
            <FormRow
              type="number"
              name="itemAmount"
              value={itemAmount.toString()}
              handleChange={handleItemInput}
              labelText={t("Amount")}
              tooltipContent={t(
                "The total quantity amount an item contains. Ex: a case of 20x500ml would contain 10L. This Amount is used for calculating prices for menu Items. If you input the subItem Quantity, subItemAmount and AmountUnit, you can calculate the TotalAmount automatically"
              )}
            />
          )}
          {fieldsToRender.itemAmountType && (
            <FormRowSelect
              name="itemAmountType"
              value={itemAmountType}
              handleChange={handleItemInput}
              list={itemAmountTypeOptions}
              labelText={t("Amount Type")}
            />
          )}
          {fieldsToRender.itemNeeded && (
            <FormRow
              type="text"
              name="itemNeeded"
              value={itemNeeded}
              handleChange={handleItemInput}
              labelText={t("Item Needed")}
              tooltipContent={t(
                "How much of the item you need weekly or daily, this number is used with the itemNeededType and is used for automatic ordering based on inventory levels"
              )}
            />
          )}
          {fieldsToRender.itemNeededType && (
            <FormRowSelect
              name="itemNeededType"
              value={itemNeededType}
              handleChange={handleItemInput}
              list={itemTypeOptions}
              labelText={t("Item Needed Type")}
              tooltipContent={t(
                "The itemType of itemNeeded, this type has to be the same type as either the itemType or the subItemType for automatic ordering"
              )}
            />
          )}
          {fieldsToRender.itemLeftToOrder && (
            <FormRow
              type="text"
              name="itemLeftToOrder"
              value={itemLeftToOrder}
              handleChange={handleItemInput}
              labelText={t("Item Left To Order")}
              tooltipContent={t(
                "The minimum quantity of an item needed before Reordering. Ex: if you set the itemNeeded to 2 but itemLeftToOrder to 0.5, it will only order if the quantity is below 0.5. This Number is not necessary but is used conjointly with itemLeftToOrderType for automatic ordering"
              )}
            />
          )}
          {fieldsToRender.itemLeftToOrderType && (
            <FormRowSelect
              name="itemLeftToOrderType"
              value={itemLeftToOrderType}
              handleChange={handleItemInput}
              list={itemTypeOptions}
              labelText={t("Item Left To Order Type")}
              tooltipContent={t(
                "The itemType of itemLeftToOrder, this type has to be the same type as either the itemType or the subItemType for automatic ordering"
              )}
            />
          )}
          {fieldsToRender.itemCode && (
            <FormRow
              type="text"
              name="itemCode"
              value={itemCode}
              handleChange={handleItemInput}
              labelText={t("Item Code")}
              tooltipContent={t(
                "The digital code of the item. This can help finding items on the provider's online service. Ex: IDM025CS or 1875329"
              )}
            />
          )}
          {fieldsToRender.itemLocation && (
            <FormRowSelect
              name="itemLocation"
              labelText={t("Location")}
              value={itemLocation}
              handleChange={handleItemInput}
              list={itemLocationOptions}
              tooltipContent={t(
                "The physical location of the item in the Kitchen Ex: Fridge, Spice Shelf, Freezer. These values should be set based on your restaurant setup and help you filter and search through specific items"
              )}
            />
          )}
          {fieldsToRender.itemCategory && (
            <FormRowSelect
              name="itemCategory"
              labelText={t("Category")}
              value={itemCategory}
              handleChange={handleItemInput}
              list={itemCategoryOptions}
              tooltipContent={t(
                "What the item categorizes as, Ex: Meat, Vegetables, Bar, Cleaning products. These values should be set based on your restaurant setup and help you filter and search through specific items"
              )}
            />
          )}
          <button
            type="submit"
            className="btn submit-btn save-btn"
            onClick={handleSaveChanges}
          >
            {t("SaveChanges")}
          </button>
        </form>
      )}
    </div>
  );
};

export default MissingItem;
