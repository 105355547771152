import {
  MenuItemsContainer,
  AddMenuItemContainer,
  AddMenuItem,
  EditErrorPercentage,
} from "../../components";
import { NavLink } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import ItemInfo from "../../components/ItemInfo";

const MenuItems = () => {
  return (
    <div style={{ marginTop: "1rem" }}>
      <div style={{ maxWidth: "6rem", height: "1.5rem" }}>
        <NavLink to="/menu" className="back-to-menus">
          <ItemInfo icon={<BsArrowLeftShort />} text="Menus" />
        </NavLink>
      </div>
      <AddMenuItemContainer />
      <EditErrorPercentage />
      <AddMenuItem />
      <MenuItemsContainer />
    </div>
  );
};

export default MenuItems;
