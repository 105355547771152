import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useAppContext } from "../../context/appContext";
import Alert from "../Alert";
import Loading from "../Loading";
import ReportPreview from "./ReportPreview";
import Wrapper from "../../assets/wrappers/Orders";
import ItemInfo from "../ItemInfo";
import { BsArrowRightShort } from "react-icons/bs";

const ReportsContainer = () => {
  const { fetchEmails, salesReports, isLoading, showAlert, displayAlert } =
    useAppContext();

  useEffect(() => {
    fetchEmails();
  }, []);

  if (!isLoading && salesReports.length === 0) {
    return (
      <Wrapper>
        <h2 style={{ marginBottom: "0" }}>No reports to display...</h2>
        <div style={{ display: "flex" }}>
          <p style={{ margin: "0" }}>Make sure to Login before Fetching</p>
          <NavLink
            to="/profile"
            className="back-to-menus"
            style={{ marginTop: "0.3rem", textDecoration: "underline" }}
          >
            <ItemInfo icon={<BsArrowRightShort />} text="Go to Profile" />
          </NavLink>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="orders-title">
        <h5 className="providers-title-h5">
          {salesReports.length} report{salesReports.length > 1 && "s"} found
        </h5>
        {isLoading && <Loading center />}
        <div className="orders-title-alert">{showAlert && <Alert />}</div>
      </div>

      <div className="orders-list">
        {salesReports.map((report, index) => {
          return <ReportPreview key={index} report={report} />;
        })}
      </div>
    </Wrapper>
  );
};

export default ReportsContainer;
