import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      //Landing
      //Navbar
      Pricing: "Pricing",
      Login: "Login",
      //title
      "AI-Powered Tools": "AI-Powered Tools",
      "for Every Corner of Your Kitchen": "for Every Corner of Your Kitchen",
      "Chumon Description":
        "Chumon is a restaurant Kitchen CMS, it allows to manage your items, orders and much more",
      //Features
      "Effortless Kitchen Management": "Effortless Kitchen Management",
      "Seamless Order Management": "Seamless Order Management",
      "Seamless Order Management Text":
        "Chumon allows you to create and manage orders for all your suppliers in one place. You can easily add new orders, track their status, and manage multiple providers efficiently. This streamlined process helps ensure that you never run out of essential ingredients and can focus more on running your kitchen smoothly.",
      "Efficient Inventory Tracking": "Efficient Inventory Tracking",
      "Efficient Inventory Tracking Text":
        "The app allows you to filter through your manually updated inventory and automatically set order quantities based on your predefined needs for each item. This feature helps streamline the ordering process, saving you time and reducing the risk of over- or under-ordering.",
      "Automated Order Dispatch": "Automated Order Dispatch",
      "Automated Order Dispatch Text":
        "Once your orders are prepared, the app can automatically export and send them directly to your providers. This feature saves time by eliminating the need for manual order processing and helps ensure accuracy in your orders, reducing the risk of errors and delays.",
      //UI
      "Simple and Intuitive Interface": "Simple and Intuitive Interface",
      "Interface Text":
        "With Chumon's user-friendly interface, compatible on all devices, managing your kitchen has never been easier. Spend less time on logistics and more time on creating great food.",
      //Features2
      "Explore Our Advanced Capabilities": "Explore Our Advanced Capabilities",
      "Flexible Item Management": "Flexible Item Management",
      "Flexible Item Management Text":
        "Chumon allows you to manage all your kitchen items, including their alternatives, making it easier to switch ingredients when necessary. This flexibility ensures that your kitchen can adapt to changes in supply without disrupting your operations.",
      "Cost Analysis Insights": "Cost Analysis Insights",
      "Cost Analysis Insights Text":
        "With detailed cost tracking and insightful analytics, Chumon helps you understand your spending habits and identify areas for cost-saving. You can monitor your expenses and make informed decisions to optimize your budget and improve your kitchen's financial performance.",
      "Dynamic Dish Pricing": "Dynamic Dish Pricing",
      "Dynamic Dish Pricing Text":
        "Chumon provides tools to calculate and update the costs of your dishes based on your current ordering costs. This feature helps you maintain accurate pricing for your menu items, ensuring that your pricing strategy remains competitive and profitable, reflecting real-time cost changes.",
      //Pricing
      "Flexible Pricing Plans": "Flexible Pricing Plans",
      "Flexible Pricing Description":
        "We will soon be offering different flexible pricing options tailored to meet your needs. Stay tuned for more details!",
      //Contact
      "Let's get you set up": "Let's get you set up",
      "Reach out to our experts for a seamless setup experience.":
        "Reach out to our experts for a seamless setup experience.",
      "Your name or company?": "Your name or company?",
      "Enter your email": "Enter your email",
      "Enter your phone number": "Enter your phone number",
      "Talk to an expert": "Talk to an expert",
      "Your message has been sent successfully.":
        "Your message has been sent successfully.",
      "An expert will reach out to you shortly.":
        "An expert will reach out to you shortly.",
      "Name is required": "Name is required",
      "Email is required": "Email is required",
      "Invalid email format": "Invalid email format",
      "Phone number is required": "Phone number is required",
      "Invalid phone number format": "Invalid phone number format",
      "Error sending email. Please try again later.":
        "Error sending email. Please try again later.",
      //Footer
      "Learn more": "Learn more",
      Pricing: "Pricing",
      Login: "Login",
      "Schedule a call": "Schedule a call",
      //Login
      Register: "Register",
      Submit: "Submit",
      Name: "Name",
      Email: "Email",
      Password: "Password",
      "Login Button": "Login",
      //APP TRANSLATIONS
      //Navbar
      "Subscription Active": "Subscription Active",
      "Subscription Inactive": "Subscription Inactive",
      Dashboard: "Dashboard",
      Orders: "Orders",
      Stock: "Stock",
      Providers: "Providers",
      Menus: "Menus",
      Trash: "Trash",
      Settings: "Settings",
      Support: "Support",
      Logout: "Logout",
      //Support
      "Need Help?": "Need Help?",
      "Reach out to us anytime": "Reach out to us anytime",
      Phone: "Phone",
      Email: "Email",
      //Profile
      Profile: "Profile",
      Language: "Language",
      "Save Changes": "Save Changes",
      "Please Wait...": "Please Wait...",
      "Subscription Details": "Subscription Details",
      Plan: "Plan",
      Status: "Status",
      "Manage Subscription": "Manage Subscription",
      "Select a Plan": "Select a Plan",
      "Choose Plan": "Choose Plan",
      "No Plan Selected": "No Plan Selected",
      "Item Locations": "Item Locations",
      "Item Categories": "Item Categories",
      "Item Types": "Item Types",
      Kitchens: "Kitchens",
      Add: "Add",
      Close: "Close",
      "Location Name": "Location Name",
      "Category Name": "Category Name",
      "Type Name": "Type Name",
      "Kitchen Name": "Kitchen Name",
      Submit: "Submit",
      Delete: "Delete",
      //Dashboard
      "Missing Info": "Missing Info",
      "View incomplete item data": "View incomplete item data",
      'No Current "Pending" Orders': 'No Current "Pending" Orders',
      "Current Inventory Value": "Current Inventory Value",
      "pending orders": "pending orders",
      "This Week": "This Week",
      "Last Month": "Last Month",
      "Last 3 Months": "Last 3 Months",
      "Last 6 Months": "Last 6 Months",
      "Last Year": "Last Year",
      "All Time": "All Time",
      "Total Cost": "Total Cost",
      Items: "Items",
      "Show Less": "Show Less",
      "Show More": "Show More",
      "Order Data": "Order Data",
      OrderCostsOverTime: "Order Costs Over Time",
      MostOrderedItems: "Most Ordered Items",
      ProviderDistribution: "Provider Distribution",
      BackorderedAndMissing: "Backordered and Missing",
      "Total Order Costs Over Time (Weekly)":
        "Total Order Costs Over Time (Weekly)",
      "Most Ordered Items by Cost": "Most Ordered Items by Cost",
      "Error Rate (%)": "Error Rate (%)",
      //Missing Info
      Dashboard: "Dashboard",
      Price: "Price",
      Supplier: "Supplier",
      Proportions: "Proportions",
      Provider: "Provider",
      Amount: "Amount",
      "Amount Type": "Amount Type",
      Needed: "Needed",
      "Needed Type": "Needed Type",
      "Left to Order": "Left to Order",
      "Left to Order Type": "Left to Order Type",
      Code: "Code",
      "Total Items with Missing": "Total Items with Missing",
      "Total Items with Missing Information":
        "Total Items with Missing Information",
      "Show More": "Show More",
      missingInformation: "missing information",
      Edit: "Edit",
      SaveChanges: "Save Changes",
      //Trash
      Search: "Search",
      provider: "provider",
      category: "category",
      location: "location",
      type: "type",
      Kitchen: "Kitchen",
      "Sort by": "Sort by",
      "Deleted: Newest First": "Deleted: Newest First",
      "Deleted: Oldest First": "Deleted: Oldest First",
      "Alphabetical: A to Z": "Alphabetical: A to Z",
      "Alphabetical: Z to A": "Alphabetical: Z to A",
      "Price: High to Low": "Price: High to Low",
      "Price: Low to High": "Price: Low to High",
      "clear filters": "Reset all",
      "No deleted items to display": "No deleted items to display...",
      "No deleted orders to display": "No deleted orders to display...",
      Items: "Items",
      Orders: "Orders",
      Item: "Item",
      Order: "Order",
      deleted_item: "deleted item",
      deleted_items: "deleted items",
      deleted_order: "deleted order",
      deleted_orders: "deleted orders",
      found: "found",
      found_plural: "found",
      Deleted: "Deleted",
      Restore: "Restore",
      "Delete Permanently": "Delete",
      "Confirm Delete": "Confirm Delete",
      "Total Cost": "Total Cost",
      "Number of Items": "Number of Items",
      //Menus
      "Create Menu": "Create Menu",
      "edit menu": "edit menu",
      "add menu": "add menu",
      "Menu Name": "Menu Name",
      "Menu Description": "Menu Description",
      "menu found": "menu found",
      "menus found": "menus found",
      "No menus to display...": "No menus to display...",
      submit: "submit",
      Edit: "Edit",
      //MenuItems
      "Add Menu Item": "Add Menu Item",
      Error: "Error",
      "Update Error Percentage": "Update Error Percentage",
      "Would you like to update all menu items with the new error percentage?":
        "Would you like to update all menu items with the new error percentage?",
      "Update Settings Only": "Update Settings Only",
      "Update All": "Update All",
      "Confirm Update All": "Confirm Update All",
      Cancel: "Cancel",
      Save: "Save",
      "No menu items to display...": "No menu items to display...",
      "menu item": "menu item",
      "menu items": "menu items",
      "menu item found": "menu item found",
      "menu items found": "menu items found",
      Order: "Order",
      Orders: "Orders",
      Edit: "Edit",
      "Total Cost": "Total Cost",
      "for orders": "for orders",
      "N/A": "N/A",
      Cost: "Cost",
      Waste: "Waste",
      for: "for",
      Name: "Name",
      "Dish cost": "Dish cost",
      "Number of Orders": "Number of Orders",
      "Error Percentage": "Error Percentage",
      Instructions: "Instructions",
      Quantity: "Quantity",
      QType: "Quantity Type",
      "Waste %": "Waste %",
      Add: "Add",
      Delete: "Delete",
      submit: "Submit",
      "edit menuItem": "Edit Menu Item",
      "add menuItem": "Add Menu Item",
      ingredients: "Ingredients",
      Ingredient: "Ingredient",
      Quantity: "Quantity",
      "Quantity Type": "Quantity Type",
      "Waste %": "Waste %",
      tooltipMenuItemPrice:
        "Remember that menuItem prices reflect each individual item price. If custom proportions for an item are active, the price will update to these proportions.",
      //Provider
      "Add Provider": "Add Provider",
      Close: "Close",
      "No providers to display...": "No providers to display...",
      provider: "provider",
      providers: "providers",
      "providers found": "providers found",
      Edit: "Edit",
      Delete: "Delete",
      Id: "Id",
      "Password Info": "Password Info",
      "Minimum Order": "Minimum Order",
      "Order Date": "Order Date",
      "Representative Name": "Representative Name",
      "Closed on": "Closed on",
      Notes: "Notes",
      Su: "Su",
      M: "M",
      T: "T",
      W: "W",
      Th: "Th",
      F: "F",
      Sa: "Sa",
      "edit provider": "edit provider",
      "add provider": "add provider",
      "Provider Name": "Provider Name",
      Number: "Number",
      Website: "Website",
      "Provider's website URL, please include http:// or https://":
        "Provider's website URL, please include http:// or https://",
      "Delivery Days": "Delivery Days",
      "Delivery Time": "Delivery Time",
      Holiday: "Holiday",
      Email: "Email",
      "Provider ID": "Provider ID",
      "Password Info": "Password Info",
      "Minimum Order": "Minimum Order",
      "Order Date": "Order Date",
      "Representative Name": "Representative Name",
      Notes: "Notes",
      submit: "Submit",
      clear: "Clear",
      deletionWarning:
        "There are {{count}} active items and {{trashedCount}} trashed items linked to this provider. Please reassign or delete these items before deleting the provider.",
      //Orders
      "Send Orders from": "Send Orders from",
      "This will send orders from the account's email to the email you set for each provider. You have to verify your account's email to be able to use this feature, contact support for more info":
        "This will send orders from the account's email to the email you set for each provider. You have to verify your account's email to be able to use this feature, contact support for more info",
      "Please set an email for this provider.":
        "Please set an email for this provider.",
      "Order Details": "Order Details",
      "Total Cost": "Total Cost",
      Items: "Items",
      Backordered: "Backordered",
      Missing: "Missing",
      "Send a copy to myself": "Send a copy to myself",
      "Confirm Send": "Confirm Send",
      Send: "Send",
      "Send Single": "Send",
      "Selected Order": "Selected Order",
      "Selected Orders": "Selected Orders",
      "order sent on": "order sent on",
      Pending: "Pending",
      Completed: "Completed",
      "orders to display...": "orders to display...",
      Send: "Send",
      "Pending order": "Pending order",
      "Pending orders": "Pending orders",
      "Completed order": "Completed order",
      "Completed orders": "Completed orders",
      "found single": "found",
      "found plural": "found",
      "order sent on": "order sent on",
      "Total Cost": "Total Cost",
      Items: "Items",
      Backordered: "Backordered",
      Missing: "Missing",
      "Add notes here": "Add notes here",
      Notes: "Notes",
      "Move to Trash": "Move to Trash",
      "Last updated on": "Last updated on",
      "Save changes": "Save changes",
      Received: "Received",
      "Missing Items": "Missing Items",
      Backordered: "Backordered",
      Missing: "Missing",
      "How many items are missing?": "How many items are missing?",
      "Enter export name": "Enter export name",
      Proportions: "Proportions",
      Amount: "Amount",
      Supplier: "Supplier",
      Code: "Code",
      Price: "Price",
      Type: "Type",
      Total: "Total",
      Export: "Export",
      items: "items",
      //Stock
      "include Alternatives": "include Alternatives",
      "a-z": "A to Z",
      "z-a": "Z to A",
      "Order Quantity high-low": "Order Quantity High to Low",
      "Order Quantity low-high": "Order Quantity Low to High",
      "Inventory Quantity high-low": "Inventory Quantity High to Low",
      "Inventory Quantity low-high": "Inventory Quantity Low to High",
      "price high-low": "Price High to Low",
      "price low-high": "Price Low to High",
      "No items to display...": "No items to display...",
      "Reset Quantities": "Reset Quantities",
      Close: "Close",
      "Auto Order": "Auto Order",
      "Create Order": "Create Order",
      "items found": "items found",
      "item found": "item found",
      //ExportData
      Export: "Export",
      GridView: "GridView",
      Qty: "Qty",
      Type: "Type",
      Name: "Name",
      Prop: "Prop",
      Supplier: "Supplier",
      Provider: "Provider",
      "#": "#",
      Price: "Price",
      Location: "Location",
      Category: "Category",
      Amount: "Amount",
      Inventory: "Inventory",
      "Need|Reorder": "Need|Reorder",
      Total: "Total",
      Order: "Order",
      //ResetQuantities
      "Reset Quantities": "Reset Quantities",
      "Reset Inventory Quantity": "Reset Inventory Quantity",
      "Reset Order Quantity": "Reset Order Quantity",
      "Reset items": "Reset {{count}} items",
      "Item quantities backup": "Item quantities backup",
      "Last backup": "Last backup",
      "Number of Items": "Number of Items",
      "Restore Quantities": "Restore Quantities",
      "No backup data available.": "No backup data available.",
      //AutoOrder
      "Auto Order Quantities": "Auto Order Quantities",
      "Valid auto-order items": "{{count}} items valid for auto-ordering.",
      "Invalid auto-order items":
        "{{count}} items cannot be auto-ordered due to invalid inputs.",
      "No update needed items":
        "{{count}} items do not need to be updated (quantity already set)",
      "Auto-Order": "Auto-Order",
      //EditView
      Type: "Type",
      Supplier: "Supplier",
      Provider: "Provider",
      Code: "Code",
      Price: "Price",
      Location: "Location",
      Category: "Category",
      Amount: "Amount",
      Inventory: "Inventory",
      Needed: "Needed",
      Total: "Total",
      Display: "Display",
      "Reset View": "Reset View",
      //CreateOrder
      "Create Order": "Create Order",
      "Total Cost": "Total Cost",
      "Number of Items": "Number of Items",
      "Create All Orders": "Create All Orders",
      "No Items Available for Order": "No Items Available for Order",
      //AddItem
      "edit item": "Edit Item",
      "add item": "Add Item",
      "Item Name": "Name",
      "Item Supplier": "Supplier",
      Provider: "Provider",
      Price: "Price",
      //"Item Type": "Item Type",
      "Item Type": "Type",
      "Edit SubItem Details": "Sub-Item Details",
      "Add SubItem Details": "Sub-Item Details",
      //"SubItem Quantity": "SubItem Quantity",
      "SubItem Quantity": "Quantity",
      //"Sub-item Type": "Sub-item Type",
      "Sub-item Type": "Type",
      //"Sub-item Amount": "Sub-item Amount",
      "Sub-item Amount": "Amount",
      //"Sub-item Amount Unit": "Sub-item Amount Unit",
      "Sub-item Amount Unit": "Unit",
      "Amount Unit": "Unit",
      "The name of the item. Ex: Orange Juice":
        "The name of the item. Ex: Orange Juice",
      "The company that produces the item. Ex: Heinz or Redpath":
        "The company that produces the item. Ex: Heinz or Redpath",
      "The container in which the item comes in. Ex: Box or Case or Package":
        "The container in which the item comes in. Ex: Box or Case or Package",
      "How many subItems are there in the container. Ex: a case of 12x500ml bottles has 12. If the Item does not have any sub-item, leave this entry empty":
        "How many subItems are there in the container. Ex: a case of 12x500ml bottles has 12. If the Item does not have any sub-item, leave this entry empty",
      "What type is the subItem. Ex: a case of 12x500ml is bottles or packages":
        "What type is the subItem. Ex: a case of 12x500ml is bottles or packages",
      "Individual subItem amount. Ex: 12x500ml, amount is 500ml":
        "Individual subItem amount. Ex: 12x500ml, amount is 500ml",
      "Total Amount": "Total",
      "Total Amount Unit": "Total Unit",
      "Item Needed": "Needed",
      "Item Needed Type": "Needed Type",
      "Item Left To Order": "Left To Order",
      "Item Left To Order Type": "Left To Order Type",
      "Item Code": "Item Code",
      Location: "Location",
      Category: "Category",
      Kitchen: "Kitchen",
      "The total quantity amount an item contains. Ex: a case of 20x500ml would contain 10L. This Amount is used for calculating prices for menu Items. If you input the subItem Quantity, subItemAmount and AmountUnit, you can calculate the TotalAmount automatically":
        "The total quantity amount an item contains. Ex: a case of 20x500ml would contain 10L. This Amount is used for calculating prices for menu Items. If you input the subItem Quantity, subItemAmount and AmountUnit, you can calculate the TotalAmount automatically",
      "The unit of measurement for the total amount, such as ml, L, kg, etc.":
        "The unit of measurement for the total amount, such as ml, L, kg, etc.",
      "How much of the item you need weekly or daily, this number is used with the itemNeededType and is used for automatic ordering based on inventory levels":
        "How much of the item you need weekly or daily, this number is used with the itemNeededType and is used for automatic ordering based on inventory levels",
      "The itemType of itemNeeded, this type has to be the same type as either the itemType or the subItemType for automatic ordering":
        "The itemType of itemNeeded, this type has to be the same type as either the itemType or the subItemType for automatic ordering",
      "The minimum quantity of an item needed before Reordering. Ex: if you set the itemNeeded to 2 but itemLeftToOrder to 0.5, it will only order if the quantity is below 0.5. This Number is not necessary but is used conjointly with itemLeftToOrderType for automatic ordering":
        "The minimum quantity of an item needed before Reordering. Ex: if you set the itemNeeded to 2 but itemLeftToOrder to 0.5, it will only order if the quantity is below 0.5. This Number is not necessary but is used conjointly with itemLeftToOrderType for automatic ordering",
      "The itemType of itemLeftToOrder, this type has to be the same type as either the itemType or the subItemType for automatic ordering":
        "The itemType of itemLeftToOrder, this type has to be the same type as either the itemType or the subItemType for automatic ordering",
      "The digital code of the item. This can help finding items on the provider's online service. Ex: IDM025CS or 1875329":
        "The digital code of the item. This can help finding items on the provider's online service. Ex: IDM025CS or 1875329",
      "The physical location of the item in the Kitchen Ex: Fridge, Spice Shelf, Freezer. These values should be set based on your restaurant setup and help you filter and search through specific items":
        "The physical location of the item in the Kitchen Ex: Fridge, Spice Shelf, Freezer. These values should be set based on your restaurant setup and help you filter and search through specific items",
      "What the item categorizes as, Ex: Meat, Vegetables, Bar, Cleaning products. These values should be set based on your restaurant setup and help you filter and search through specific items":
        "What the item categorizes as, Ex: Meat, Vegetables, Bar, Cleaning products. These values should be set based on your restaurant setup and help you filter and search through specific items",
      "Separate your items through different kitchens if you have more than 1. This can be useful when ordering for more than 1 kitchen":
        "Separate your items through different kitchens if you have more than 1. This can be useful when ordering for more than 1 kitchen",
      "Add Custom Proportion": "Add Custom Proportion",
      "If you want to order your item in different proportions and have specific prices for each proportion Ex: Main Proportion is a case of 12 bottles for 10$, but you want to be able to order a single bottle for 2$. You can rotate through your different proportions in the UI and it will update the prices for ordering as well":
        "If you want to order your item in different proportions and have specific prices for each proportion Ex: Main Proportion is a case of 12 bottles for 10$, but you want to be able to order a single bottle for 2$. You can rotate through your different proportions in the UI and it will update the prices for ordering as well",
      "subItem-info":
        "If your item contains multiple subItems ex: a case of 12 bottles of 500ml, you can input the subItem details and it will automatically calculate the totalAmount and type. It will also allow you to switch between your item and subItem Type when doing inventory (counting 2 bottles vs 2 cases).",
      "This item is an alternative to:": "This item is an alternative to:",
      "This item has the following alternatives:":
        "This item has the following alternatives:",
      Alternatives: "Alternatives",
      "item-deleted-message": "This item has been deleted",
      Delete: "Delete",
      "Submit Anyways": "Submit Anyways",
      Submit: "Submit",
      validCalculationPrompt:
        "Please enter a valid calculation (e.g., '=10*2').",
      validNumberOrCalculationPrompt:
        "Please enter a valid number or calculation.",
      calculationConversionError:
        "Error in calculating amount. Please check the units.",
      "Please select a Specific value for:":
        "Please select a Specific value for:",
      "Search for alternatives": "Search for alternatives",
      amount: "Amount",
      itemNeededAndLeftToOrder: "Item Needed and Item Left to Order",
      //DeleteConfirmation
      moveToTrash: "Move to Trash",
      deletePermanently: "Delete Permanently",
      confirmDelete: "Confirm Delete",
      cancel: "Cancel",
      moveToTrashOrDelete: "Move to Trash or Delete Permanently",
      pleaseConfirmAction: "Please confirm your action:",
      "Item Name copied!": "Item Name copied!",
      "Supplier copied!": "Supplier copied!",
      "Provider copied!": "Provider copied!",
      "Item Code copied!": "Item Code copied!",
    },
  },

  fr: {
    translation: {
      //Landing
      //Navbar
      Pricing: "Tarifs",
      Login: "Connexion",
      //title
      "AI-Powered Tools": "Outils propulsés par l'IA",
      "for Every Corner of Your Kitchen":
        "pour tous les recoins de votre cuisine",
      "Chumon Description":
        "Chumon est un CMS pour cuisines de restaurants, conçu pour gérer vos stocks, vos commandes et bien plus encore.",
      //Features
      "Effortless Kitchen Management": "Gestion De Cuisine Simplifiée",
      "Seamless Order Management": "Gestion des Commandes",
      "Seamless Order Management Text":
        "Chumon vous permet de créer et gérer les commandes pour tous vos fournisseurs au même endroit. Vous pouvez facilement ajouter de nouvelles commandes, suivre leur statut et gérer plusieurs fournisseurs de manière efficace. Ce processus optimisé assure que vous ne manquez jamais d'ingrédients essentiels et vous pouvez vous concentrer davantage sur la gestion de votre cuisine.",
      "Efficient Inventory Tracking": "Suivi Efficace de L'inventaire",
      "Efficient Inventory Tracking Text":
        "L'application vous permet de filtrer votre inventaire mis à jour manuellement et de définir automatiquement les quantités de commande basées sur vos besoins prédéfinis pour chaque article. Cette fonctionnalité simplifie le processus de commande, vous faisant gagner du temps et réduisant le risque de sur-commande ou de sous-commande.",
      "Automated Order Dispatch": "Envoi Automatisé des Commandes",
      "Automated Order Dispatch Text":
        "Une fois vos commandes préparées, l'application peut automatiquement les exporter et les envoyer directement à vos fournisseurs. Cette fonctionnalité économise du temps en éliminant le besoin de traitement manuel des commandes et aide à garantir la précision de vos commandes, réduisant le risque d'erreurs et de retards.",
      "Simple and Intuitive Interface": "Interface Simple et Intuitive",
      "Interface Text":
        "Grâce à l'interface ergonomique de Chumon, compatible avec tous les appareils, gérer votre cuisine n'a jamais été aussi facile. Passez moins de temps sur la logistique et plus de temps à créer de délicieux plats.",
      //Features2
      "Explore Our Advanced Capabilities": "Découvrez Nos Capacités Avancées",
      "Flexible Item Management": "Gestion Flexible des Stocks",
      "Flexible Item Management Text":
        "Chumon vous permet de gérer tous les articles de votre cuisine, y compris leurs alternatives, facilitant ainsi le remplacement des ingrédients lorsque nécessaire. Cette flexibilité permet à votre cuisine de s'adapter facilement aux variations d'ingrédients, tout en maintenant le bon fonctionnement de vos opérations.",
      "Cost Analysis Insights": "Aperçus de l'Analyse des Coûts",
      "Cost Analysis Insights Text":
        "Avec un suivi détaillé des coûts et des analyses pertinentes, Chumon vous aide à analyser vos habitudes de dépenses et à déterminer les secteurs où vous pouvez économiser. Vous pouvez surveiller vos dépenses et prendre des décisions éclairées pour optimiser votre budget et améliorer la performance financière de votre cuisine.",
      "Dynamic Dish Pricing": "Tarification Dynamique des Plats",
      "Dynamic Dish Pricing Text":
        "Chumon offre des outils pour calculer et mettre à jour les coûts de vos plats en fonction de vos coûts de commande actuels. Cette fonctionnalité vous aide à maintenir une tarification précise de vos articles de menu, assurant que votre stratégie de prix reste compétitive et rentable, reflétant les changements de coûts en temps réel.",
      //Pricing
      "Flexible Pricing Plans": "Plans Tarifaires Flexibles",
      "Flexible Pricing Description":
        "Nous proposerons bientôt différentes options de tarification flexibles, adaptées à vos besoins. Restez informés pour plus de détails !",
      //Contact
      "Let's get you set up": "Organisons votre installation",
      "Reach out to our experts for a seamless setup experience.":
        "Contactez nos experts pour une intégration fluide.",
      "Your name or company?": "Votre nom ou celui de votre entreprise ?",
      "Enter your email": "Entrez votre courriel",
      "Enter your phone number": "Entrez votre numéro de téléphone",
      "Talk to an expert": "Parlez à un expert",
      "Your message has been sent successfully.":
        "Votre message a été envoyé avec succès.",
      "An expert will reach out to you shortly.":
        "Un expert vous contactera sous peu.",
      "Name is required": "Le nom est requis",
      "Email is required": "Le courriel est requis",
      "Invalid email format": "Format de courriel invalide",
      "Phone number is required": "Le numéro de téléphone est requis",
      "Invalid phone number format": "Format du numéro de téléphone invalide",
      "Error sending email. Please try again later.":
        "Erreur lors de l'envoi du courriel. Veuillez réessayer plus tard.",
      //Footer
      "Learn more": "En savoir plus",
      Pricing: "Tarification",
      Login: "Connexion",
      "Schedule a call": "Planifiez un appel",
      //Login
      Register: "S'inscrire",
      Submit: "Soumettre",
      Name: "Nom",
      Email: "Courriel",
      Password: "Mot de passe",
      "Login Button": "Se connecter",
      //APP TRANSLATIONS
      //Navbar
      "Subscription Active": "Abonnement Actif",
      "Subscription Inactive": "Abonnement Inactif",
      Dashboard: "Tableau de Bord",
      Orders: "Commandes",
      Stock: "Stock",
      Providers: "Fournisseurs",
      Menus: "Menus",
      Trash: "Corbeille",
      Settings: "Paramètres",
      Support: "Support",
      Logout: "Déconnexion",
      //Support
      "Need Help?": "Besoin d'aide ?",
      "Reach out to us anytime": "Contactez-nous à tout moment",
      Phone: "Téléphone",
      Email: "Courriel",
      //Profile
      Profile: "Profil",
      Language: "Langue",
      "Save Changes": "Enregistrer",
      "Please Wait...": "Veuillez patienter...",
      "Subscription Details": "Détails de l'abonnement",
      Plan: "Forfait",
      Status: "Statut",
      "Manage Subscription": "Gérer l'abonnement",
      "Select a Plan": "Sélectionnez un forfait",
      "Choose Plan": "Choisir le forfait",
      "No Plan Selected": "Aucun forfait sélectionné",
      "Item Locations": "Emplacements des articles",
      "Item Categories": "Catégories d'articles",
      "Item Types": "Types d'articles",
      Kitchens: "Cuisines",
      "Item Locations": "Emplacements des articles",
      "Item Categories": "Catégories d'articles",
      "Item Types": "Types d'articles",
      Kitchens: "Cuisines",
      Add: "Ajouter",
      Close: "Fermer",
      "Location Name": "Nom de l'emplacement",
      "Category Name": "Nom de la catégorie",
      "Type Name": "Nom du type",
      "Kitchen Name": "Nom de la cuisine",
      Submit: "Soumettre",
      Delete: "Supprimer",
      //Dashboard
      "Missing Info": "Info Manquantes",
      "View incomplete item data": "Voir les données incomplètes des articles",
      'No Current "Pending" Orders': "Aucune commande en attente",
      "Current Inventory Value": "Valeur actuelle de l'inventaire",
      "pending orders": "commandes en attente",
      "This Week": "Cette semaine",
      "Last Month": "Le mois dernier",
      "Last 3 Months": "Les 3 derniers mois",
      "Last 6 Months": "Les 6 derniers mois",
      "Last Year": "L'année dernière",
      "All Time": "Depuis toujours",
      "Total Cost": "Coût total",
      Items: "Articles",
      "Show Less": "Montrer moins",
      "Show More": "Montrer plus",
      "Order Data": "Données de commande",
      OrderCostsOverTime: "Coûts des Commandes dans le Temps",
      MostOrderedItems: "Articles les Plus Commandés",
      ProviderDistribution: "Répartition des Fournisseurs",
      BackorderedAndMissing: "Commandes en Rupture et Manquantes",
      "Total Order Costs Over Time (Weekly)":
        "Coûts totaux des commandes(hebdomadaire)",
      "Most Ordered Items by Cost": "Articles les plus commandés par coût",
      "Error Rate (%)": "Taux d'erreur (%)",
      //Missing Info
      Dashboard: "Tableau de bord",
      Price: "Prix",
      Supplier: "Fournisseur",
      Proportions: "Proportions",
      Provider: "Fournisseur",
      Amount: "Quantité",
      "Amount Type": "Type de Qté",
      Needed: "Nécessaire",
      "Needed Type": "Type Nécessaire",
      "Left to Order": "Quantité Restante avant Commande",
      "Left to Order Type": "Type de Qté Restante avant Commande",
      Code: "Code",
      "Total Items with Missing": "Articles manquants",
      "Total Items with Missing Information":
        "Articles avec informations manquantes",
      missingInformation: "informations manquantes",
      "Show More": "Afficher plus",
      Edit: "Modifier",
      SaveChanges: "Enregistrer les modifications",
      //Trash
      Search: "Rechercher",
      provider: "fournisseur",
      category: "catégorie",
      location: "emplacement",
      type: "type",
      Kitchen: "cuisine",
      "Sort by": "Trier par",
      "Deleted: Newest First": "Supprimés: Du plus récent au plus ancien",
      "Deleted: Oldest First": "Supprimés: Du plus ancien au plus récent",
      "Alphabetical: A to Z": "Alphabétique: De A à Z",
      "Alphabetical: Z to A": "Alphabétique: De Z à A",
      "Price: High to Low": "Prix: Du plus élevé au plus bas",
      "Price: Low to High": "Prix: Du plus bas au plus élevé",
      "clear filters": "Réinitialiser",
      "No deleted items to display": "Aucun élément supprimé à afficher...",
      "No deleted orders to display": "Aucune commande supprimée à afficher...",
      Items: "Articles",
      Orders: "Commandes",
      Item: "Article",
      Order: "Commande",
      deleted_item: "article supprimé",
      deleted_items: "articles supprimés",
      deleted_order: "commande supprimée",
      deleted_orders: "commandes supprimées",
      found: "trouvé",
      found_plural: "trouvés",
      Deleted: "Supprimé",
      Restore: "Restaurer",
      "Delete Permanently": "Supprimer",
      "Confirm Delete": "Confirmer",
      "Total Cost": "Coût Total",
      "Number of Items": "Nombre d'Articles",
      //Menus
      "Create Menu": "Ajouter",
      "edit menu": "modifier le menu",
      "add menu": "ajouter un menu",
      "Menu Name": "Nom du menu",
      "Menu Description": "Description du menu",
      "menu found": "menu trouvé",
      "menus found": "menus trouvés",
      "No menus to display...": "Aucun menu à afficher...",
      submit: "soumettre",
      Edit: "Modifier",
      //MenuItems
      Close: "Fermer",
      "Add Menu Item": "Ajouter",
      Error: "Erreur",
      "Update Error Percentage": "Mettre à jour le pourcentage d'erreur",
      "Would you like to update all menu items with the new error percentage?":
        "Souhaitez-vous mettre à jour tous les articles de menu avec le nouveau pourcentage d'erreur?",
      "Update Settings Only": "Mettre à jour uniquement les paramètres",
      "Update All": "Tout mettre à jour",
      "Confirm Update All": "Confirmer la mise à jour de tout",
      Cancel: "Annuler",
      "Error Percentage": "% d'erreur",
      Save: "Enregistrer",
      "No menu items to display...": "Aucun élément de menu à afficher...",
      "menu item": "Article de menu",
      "menu items": "Articles de menu",
      "menu item found": "Article de menu trouvé",
      "menu items found": "Articles de menu trouvés",
      Order: "Commande",
      Orders: "Commandes",
      Edit: "Modifier",
      "Total Cost": "Coût Total",
      "for orders": "pour les commandes",
      "N/A": "N/A",
      Cost: "Coût",
      Waste: "Gaspillage",
      for: "pour",
      Name: "Nom",
      "Dish cost": "Coût du Plat",
      "Number of Orders": "Nombre de Commandes",
      Instructions: "Instructions",
      Quantity: "Quantité",
      QType: "Type de Quantité",
      "Waste %": "Pourcentage de Perte",
      Add: "Ajouter",
      Delete: "Supprimer",
      submit: "Soumettre",
      "edit menuItem": "Modifier l'article de Menu",
      "add menuItem": "Ajouter un article de Menu",
      ingredients: "Ingrédients",
      Ingredient: "Ingrédient",
      Quantity: "Quantité",
      "Quantity Type": "Type Quantité",
      "Waste %": "% de Perte",
      tooltipMenuItemPrice:
        "Rappelez-vous que les prix des articles de menu reflètent le prix de chaque article individuellement. Si des proportions personnalisées pour un article sont sélectionnées, le prix sera mis à jour selon ces proportions.",
      //Provider
      "Add Provider": "Ajouter",
      Close: "Fermer",
      "No providers to display...": "Aucun fournisseur à afficher...",
      provider: "fournisseur",
      providers: "fournisseurs",
      "providers found": "fournisseurs trouvés",
      Edit: "Modifier",
      Delete: "Supprimer",
      Id: "Id",
      "Password Info": "Info Mot de Passe",
      "Minimum Order": "Commande Minimum",
      "Order Date": "Date de Commande",
      "Representative Name": "Nom du Représentant",
      "Closed on": "Fermé le",
      Notes: "Notes",
      Su: "D",
      M: "L",
      T: "Ma",
      W: "Me",
      Th: "J",
      F: "V",
      Sa: "S",
      "edit provider": "modifier le fournisseur",
      "add provider": "ajouter un fournisseur",
      "Provider Name": "Nom du Fournisseur",
      Number: "Numéro",
      Website: "Site Web",
      "Provider's website URL, please include http:// or https://":
        "URL du site web du fournisseur, inclure http:// ou https://",
      "Delivery Days": "Jours de Livraison",
      "Delivery Time": "Heure de Livraison",
      Holiday: "Jour Férié",
      Email: "E-mail",
      "Provider ID": "ID du Fournisseur",
      "Password Info": "Infos de Mot de Passe",
      "Minimum Order": "Commande Minimale",
      "Order Date": "Date de Commande",
      "Representative Name": "Nom du Représentant",
      Notes: "Notes",
      submit: "Soumettre",
      clear: "Effacer",
      deletionWarning:
        "Il y a {{count}} articles actifs et {{trashedCount}} articles supprimés liés à ce fournisseur. Veuillez réaffecter ou supprimer ces articles avant de supprimer le fournisseur.",
      //Orders
      "Send Orders from": "Envoyer les commandes de",
      "This will send orders from the account's email to the email you set for each provider. You have to verify your account's email to be able to use this feature, contact support for more info":
        "Ceci enverra les commandes depuis l'email du compte vers l'email que vous avez défini pour chaque fournisseur. Vous devez vérifier l'email de votre compte pour utiliser cette fonctionnalité, contactez le support pour plus d'informations",
      "Please set an email for this provider.":
        "Veuillez définir un email pour ce fournisseur.",
      "Order Details": "Détails",
      "Total Cost": "Coût total",
      Items: "Articles",
      Backordered: "En réapprovisionnement",
      Missing: "Manquant",
      "Send a copy to myself": "Envoyer une copie à moi-même",
      "Confirm Send": "Confirmer l'envoi",
      Send: "Envoyer les",
      "Send Single": "Envoyer la",
      "Selected Order": "commande sélectionnée",
      "Selected Orders": "commandes sélectionnées",
      "order sent on": "commande envoyée le",
      Pending: "En cours",
      Completed: "Complété",
      Send: "Envoyer",
      "Pending order": "commande en attente",
      "Pending orders": "commandes en attente",
      "Completed order": "commande complétée",
      "Completed orders": "commandes complétées",
      "found single": "trouvée",
      "found plural": "trouvées",
      "order sent on": "commande envoyée le",
      "Total Cost": "Coût total",
      Items: "Articles",
      Backordered: "En rupture de stock",
      Missing: "Manquants",
      "Add notes here": "Ajoutez des notes ici",
      Notes: "Notes",
      "Move to Trash": "Déplacer dans la corbeille",
      "Last updated on": "Dernière mise à jour le",
      "Save changes": "Enregistrer",
      Received: "Reçue",
      "Missing Items": "Articles manquants",
      Backordered: "En rupture de stock",
      Missing: "Manquant",
      "How many items are missing?": "Combien d'articles manquent?",
      "Enter export name": "Entrez le nom de l'exportation",
      Proportions: "Proportions",
      Amount: "Quantité",
      Supplier: "Fournisseur",
      Code: "Code",
      Price: "Prix",
      Type: "Type",
      Total: "Total",
      Export: "Exporter",
      items: "articles",
      //Stock
      "include Alternatives": "inclure les Alternatives",
      "a-z": "De A à Z",
      "z-a": "De Z à A",
      "Order Quantity high-low": "Quantité de Commande élevée-basse",
      "Order Quantity low-high": "Quantité de Commande basse-élevée",
      "Inventory Quantity high-low": "Quantité en Inventaire élevée-basse",
      "Inventory Quantity low-high": "Quantité en Inventaire basse-élevée",
      "price high-low": "Prix élevé-bas",
      "price low-high": "Prix bas-élevé",
      "No items to display...": "Aucun article à afficher...",
      "Reset Quantities": "Réinitialiser les quantités",
      Close: "Fermer",
      "Auto Order": "Commande automatique",
      "Create Order": "Créer une commande",
      "items found": "articles trouvés",
      "item found": "article trouvé",
      Export: "Exporter",
      GridView: "Grille",
      Qty: "Qté",
      Type: "Type",
      Name: "Nom",
      Prop: "Prop",
      Supplier: "Fournisseur",
      Provider: "Marque",
      "#": "N°",
      Price: "Prix",
      Location: "Emplacement",
      Category: "Catégorie",
      Amount: "Montant",
      Inventory: "Inventaire",
      "Need|Reorder": "Besoin/Restante",
      Total: "Total",
      Order: "Commander",
      "Reset Quantities": "Réinitialiser",
      "Reset Inventory Quantity": "Réinitialiser Quantité Inventaire",
      "Reset Order Quantity": "Réinitialiser Quantité Commande",
      "Reset items": "Réinitialiser {{count}} articles",
      "Item quantities backup": "Sauvegarde des quantités d'articles",
      "Last backup": "Dernière sauvegarde",
      "Number of Items": "Nombre d'articles",
      "Restore Quantities": "Restaurer les quantités",
      "No backup data available.": "Aucune donnée de sauvegarde disponible.",
      "Auto Order Quantities": "Commande Automatique des Quantités",
      "Valid auto-order items":
        "{{count}} articles valides pour la commande automatique.",
      "Invalid auto-order items":
        "{{count}} articles ne peuvent pas être commandés automatiquement en raison d'entrées non valides.",
      "No update needed items":
        "{{count}} articles ne nécessitent pas de mise à jour (quantité déjà définie)",
      "Auto-Order": "Commande Automatique",
      "Close View": "Close View",
      View: "View",
      Close: "Close",
      "Add Item": "Add Item",
      "Close View": "Fermer la Vue",
      Display: "Affichage",
      Close: "Fermer",
      "Add Item": "Ajouter un Article",
      //EditView
      Type: "Type",
      Supplier: "Fabricant",
      Provider: "Fournisseur",
      Code: "Code",
      Price: "Prix",
      Location: "Emplacement",
      Category: "Catégorie",
      Amount: "Quantité",
      Inventory: "Inventaire",
      Needed: "Requis",
      Total: "Total",
      "Reset View": "Réinitialiser l'affichage",
      //CreateOrder
      "Create Order": "Créer une commande",
      "Total Cost": "Coût total",
      "Number of Items": "Nombre d'articles",
      "Create All Orders": "Créer toutes les commandes",
      "No Items Available for Order": "Aucun article disponible pour commande",
      //AddItem
      "edit item": "Modifier l'article",
      "add item": "Ajouter un article",
      "Item Name": "Nom",
      "Item Supplier": "Fabricant",
      Provider: "Fournisseur",
      Price: "Prix",
      "Item Type": "Type",
      "Edit SubItem Details": "détails de sous-articles",
      "Add SubItem Details": "détails de sous-articles",
      "SubItem Quantity": "Nombre",
      "Sub-item Type": "Type",
      "Sub-item Amount": "Quantité",
      "Amount Unit": "Unité",
      "The name of the item. Ex: Orange Juice":
        "Le nom de l'article. Exemple : Jus d'orange",
      "The company that produces the item. Ex: Heinz or Redpath":
        "La compagnie qui produit l'article. Exemple : Heinz ou Redpath",
      "The container in which the item comes in. Ex: Box or Case or Package":
        "Le type de conteneur pour l'article, tel que boîte, caisse ou paquet",
      "How many subItems are there in the container. Ex: a case of 12x500ml bottles has 12. If the Item does not have any sub-item, leave this entry empty":
        "Le nombre de sous-articles contenus dans l'article.  Ex: une caisse de 12x500ml contient 12 bouteilles. Laissez vide si non applicable",
      "What type is the subItem. Ex: a case of 12x500ml is bottles or packages":
        "Le type de sous-article, comme des bouteilles ou des paquets",
      "Individual subItem amount. Ex: 12x500ml, amount is 500ml":
        "La quantité individuelle pour chaque sous-article. Exemple : 500 dans une caisse de 12x500ml",
      "Sub-item Amount Unit": "Unité",
      "Total Amount": "Total",
      "Total Amount Unit": "Unité Total",
      "Item Needed": "Nécessaires",
      "Item Needed Type": "Type Nécessaire",
      "Item Left To Order": "Qté avant Commander",
      "Item Left To Order Type": "Type Qté avant Commander",
      "Item Code": "Code de l'Article",
      Location: "Emplacement",
      Category: "Catégorie",
      Kitchen: "Cuisine",
      "The total quantity amount an item contains. Ex: a case of 20x500ml would contain 10L. This Amount is used for calculating prices for menu Items. If you input the subItem Quantity, subItemAmount and AmountUnit, you can calculate the TotalAmount automatically":
        "La quantité totale que contient un article. Ex : un carton de 20x500ml contiendrait 10L. Cette quantité est utilisée pour calculer les prix des articles de menu. Si vous entrez la quantité de sous-article, la quantité de sous-article et l'unité de sous-article, vous pouvez calculer automatiquement la quantité totale",
      "The unit of measurement for the total amount, such as ml, L, kg, etc.":
        "L'unité de mesure pour la quantité totale, telle que ml, L, kg, etc.",
      "How much of the item you need weekly or daily, this number is used with the itemNeededType and is used for automatic ordering based on inventory levels":
        "Le nombre d'articles dont vous avez besoin hebdomadairement ou quotidiennement, ce nombre est utilisé avec le type d'article nécessaire et est utilisé pour les commandes automatiques basées sur les niveaux de stock",
      "The itemType of itemNeeded, this type has to be the same type as either the itemType or the subItemType for automatic ordering":
        "Le type d'article nécessaire doit être le même que celui de l'article ou du sous-article pour les commandes automatiques",
      "The minimum quantity of an item needed before Reordering. Ex: if you set the itemNeeded to 2 but itemLeftToOrder to 0.5, it will only order if the quantity is below 0.5. This Number is not necessary but is used conjointly with itemLeftToOrderType for automatic ordering":
        "La quantité minimale d'un article nécessaire avant de re-commander. Ex : si vous fixez l'article nécessaire à 2 mais l'article restant à commander à 0.5, il ne commandera que si la quantité est inférieure à 0.5. Ce nombre n'est pas nécessaire mais est utilisé conjointement avec le type d'article restant à commander pour les commandes automatiques",
      "The itemType of itemLeftToOrder, this type has to be the same type as either the itemType or the subItemType for automatic ordering":
        "Le type de l'article restant à commander doit être le même que celui de l'article ou du sous-article pour les commandes automatiques",
      "The digital code of the item. This can help finding items on the provider's online service. Ex: IDM025CS or 1875329":
        "Le code numérique de l'article. Ceci peut aider à trouver des articles sur le service en ligne du fournisseur. Ex : IDM025CS ou 1875329",
      "The physical location of the item in the Kitchen Ex: Fridge, Spice Shelf, Freezer. These values should be set based on your restaurant setup and help you filter and search through specific items":
        "L'emplacement physique de l'article dans la cuisine Ex : Réfrigérateur, étagère à épices, congélateur. Ces valeurs doivent être définies en fonction de la configuration de votre restaurant et vous aider à filtrer et rechercher des articles spécifiques",
      "What the item categorizes as, Ex: Meat, Vegetables, Bar, Cleaning products. These values should be set based on your restaurant setup and help you filter and search through specific items":
        "Ce que l'article est catégorisé comme, Ex : Viande, Légumes, Bar, Produits de nettoyage. Ces valeurs doivent être définies en fonction de la configuration de votre restaurant et vous aider à filtrer et rechercher des articles spécifiques",
      "Separate your items through different kitchens if you have more than 1. This can be useful when ordering for more than 1 kitchen":
        "Séparez vos articles à travers différentes cuisines. Ceci peut être utile lorsque vous commandez pour plusieurs cuisines",
      "Add Custom Proportion": "Ajouter une proportion personnalisée",
      "If you want to order your item in different proportions and have specific prices for each proportion Ex: Main Proportion is a case of 12 bottles for 10$, but you want to be able to order a single bottle for 2$. You can rotate through your different proportions in the UI and it will update the prices for ordering as well":
        "Si vous souhaitez commander votre article dans différentes proportions et avoir des prix spécifiques pour chaque proportion Ex: La proportion principale est une caisse de 12 bouteilles pour 10$, mais vous souhaitez pouvoir commander une seule bouteille pour 2$. Vous pouvez faire défiler vos différentes proportions dans l'interface utilisateur et cela mettra à jour les prix pour la commande également",
      "subItem-info":
        "Si votre article contient plusieurs sous-articles, par exemple une caisse de 12 bouteilles de 500 ml, vous pouvez saisir les détails des sous-articles et le système calculera automatiquement la quantité totale et le type. Cela vous permettra également de basculer entre votre type d'article et le type de sous-article lors de l'inventaire (comptage de 2 bouteilles vs 2 caisses)",
      "This item is an alternative to:": "Cet article est une alternative à :",
      "This item has the following alternatives:":
        "Cet article a les alternatives suivantes :",
      Alternatives: "Alternatives",
      "item-deleted-message": "Cet article a été supprimé",
      Delete: "Supprimer",
      "Submit Anyways": "Soumettre quand même",
      Submit: "Soumettre",
      "Please select a Specific value for:":
        "Veuillez sélectionner une valeur spécifique pour :",
      validCalculationPrompt: "Veuillez entrer un calcul valide (ex. '=10*2').",
      validNumberOrCalculationPrompt:
        "Veuillez entrer un nombre valide ou un calcul.",
      calculationConversionError:
        "Erreur dans le calcul de la quantité. Veuillez vérifier les unités.",
      "Search for alternatives": "Rechercher des alternatives",
      amount: "Quantité",
      itemNeededAndLeftToOrder:
        "Articles nécessaires et quantité avant commander",
      //DeleteConfirmation
      moveToTrash: "Déplacer vers la Corbeille",
      deletePermanently: "Supprimer Définitivement",
      confirmDelete: "Confirmer la Suppression",
      cancel: "Annuler",
      moveToTrashOrDelete:
        "Déplacer vers la corbeille ou supprimer définitivement",
      pleaseConfirmAction: "Veuillez confirmer votre action :",
      "Item Name copied!": "Nom de l'article copié!",
      "Supplier copied!": "Fabricant copié!",
      "Provider copied!": "Fournisseur copié!",
      "Item Code copied!": "Code de l'article copié!",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("i18nextLng") || "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  pluralSeparator: "_",
});

export default i18n;
