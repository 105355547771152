import { TrashedItemsContainer } from "../../components";

const Trash = () => {
  return (
    <div>
      <TrashedItemsContainer />
    </div>
  );
};

export default Trash;
