import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useAppContext } from "../../context/appContext";
import { BsArrowLeftShort } from "react-icons/bs";
import ItemInfo from "../../components/ItemInfo";
import Wrapper from "../../assets/wrappers/MissingInfo";
import MissingItem from "../../components/item/MissingItem";
import { useTranslation } from "react-i18next";

const MissingInfoComponent = () => {
  const { t } = useTranslation();
  const {
    items,
    getItems,
    setEditItem,
    providers,
    itemTypes,
    itemLocations,
    itemCategories,
  } = useAppContext();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [displayLimit, setDisplayLimit] = useState(50);
  const displayedItems = filteredItems.slice(0, displayLimit);

  useEffect(() => {
    if (items.length < 1) {
      getItems();
    }
  }, []);

  const checkFields = {
    price: (item) => item.price === 0 || item.price == null,
    itemSupplier: (item) => !item.itemSupplier,
    provider: (item) => !item.provider,
    itemProportions: (item) => !item.itemProportions,
    itemType: (item) => {
      const type = itemTypes.find((t) => t._id === item.itemType);
      return type ? type.typeName === "Default" : false;
    },
    itemAmount: (item) => item.itemAmount === 0,
    itemAmountType: (item) => item.itemAmountType === "Other",
    itemNeeded: (item) => item.itemNeeded === "" || item.itemNeeded === "0",
    itemNeededType: (item) => {
      const type = itemTypes.find((t) => t._id === item.itemNeededType);
      return type ? type.typeName === "Default" : false;
    },
    itemLeftToOrder: (item) =>
      item.itemLeftToOrder === "" || item.itemLeftToOrder === "0",
    itemLeftToOrderType: (item) => {
      const type = itemTypes.find((t) => t._id === item.itemLeftToOrderType);
      return type ? type.typeName === "Default" : false;
    },
    itemCode: (item) => item.itemCode === "",
    itemLocation: (item) => {
      const location = itemLocations.find((l) => l._id === item.itemLocation);
      return location ? location.locationName === "Default" : false;
    },
    itemCategory: (item) => {
      const category = itemCategories.find((c) => c._id === item.itemCategory);
      return category ? category.categoryName === "Default" : false;
    },
  };

  useEffect(() => {
    const newCategories = Object.keys(checkFields).filter((category) =>
      items.some((item) => checkFields[category](item))
    );

    let itemsWithMissingInfo = items.filter((item) =>
      Object.keys(checkFields).some((category) => checkFields[category](item))
    );
    if (selectedCategories.length > 0) {
      itemsWithMissingInfo = itemsWithMissingInfo.filter((item) =>
        selectedCategories.every((category) => checkFields[category](item))
      );
    }

    setCategories(newCategories);
    setFilteredItems(itemsWithMissingInfo);
  }, [items, selectedCategories]);

  const getMissingInfoCount = (item) => {
    return Object.keys(checkFields).filter((category) =>
      checkFields[category](item)
    ).length;
  };

  const handleCheckboxChange = (category) => {
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };
  const categoryNamesMapping = {
    price: t("Price"),
    itemSupplier: t("Supplier"),
    itemProportions: t("Proportions"),
    provider: t("Provider"),
    itemType: t("Type"),
    itemAmount: t("Amount"),
    itemAmountType: t("Amount Type"),
    itemNeeded: t("Item Needed"),
    itemNeededType: t("Item Needed Type"),
    itemLeftToOrder: t("Item Left To Order"),
    itemLeftToOrderType: t("Item Left To Order Type"),
    itemCode: t("Item Code"),
    itemLocation: t("Location"),
    itemCategory: t("Category"),
  };

  const formattedSelectedCategories = selectedCategories
    .map((category) => categoryNamesMapping[category] || category)
    .join(", ");

  const handleShowMore = () => {
    setDisplayLimit((prevLimit) => prevLimit + 50);
  };

  return (
    <Wrapper>
      <div>
        <div style={{ maxWidth: "12rem", height: "1.5rem" }}>
          <NavLink to="/" className="back-to-menus">
            <ItemInfo icon={<BsArrowLeftShort />} text={t("Dashboard")} />
          </NavLink>
        </div>
        <div className="category-buttons-container">
          {categories.map((category) => (
            <button
              key={category}
              className={`category-button ${
                selectedCategories.includes(category) ? "selected" : ""
              }`}
              onClick={() => handleCheckboxChange(category)}
            >
              {t(categoryNamesMapping[category] || category)}
            </button>
          ))}
        </div>
        <h5>
          {selectedCategories.length > 0
            ? `${t(
                "Total Items with Missing"
              )} ${formattedSelectedCategories}: ${filteredItems.length}`
            : `${t("Total Items with Missing Information")}: ${
                filteredItems.length
              }`}
        </h5>
        {displayedItems.map((item, index) => (
          <MissingItem
            key={item._id}
            item={item}
            getMissingInfoCount={getMissingInfoCount}
          />
        ))}
        {displayLimit < filteredItems.length && (
          <button className="btn " onClick={handleShowMore}>
            {t("Show More")}
          </button>
        )}
      </div>
    </Wrapper>
  );
};

export default MissingInfoComponent;
