import styled from "styled-components";

const Wrapper = styled.section`
  background: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-2);
  margin-bottom: 0.75rem;
  padding: 1rem;
  width: 100%;
  overflow: hidden;

  .menu-container {
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  .menu-container:hover {
    transform: scale(1.01);
  }

  .provider-title {
    display: flex;
    flex-direction: row;

    // border-bottom: 1px solid var(--grey-100);
    h2 {
      font-weight: 800;
      text-transform: capitalize;
      margin-bottom: 0;
    }
    h4 {
      padding: 0.5rem;
      margin-left: 1rem;
      margin-bottom: 0;
    }
    h5 {
      margin: 0;
      font-size: 1rem;
      text-align: end;
    }
  }

  .provider-content {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-top: 0.25rem;
    border-top: 1px solid var(--grey-100);
  }

  .provider-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;
    margin-right: 0.25rem;

    h4 {
      margin: 0;
    }
    h5 {
      margin: 0;
      font-weight: 600;
    }
    p {
      margin-top: 0.5rem;
      margin-bottom: 0;
    }
    @media (min-width: 576px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      p {
        margin-top: 0;
        margin-right: 0.25rem;
      }
    }
    @media (min-width: 768px) {
      margin-right: 0.5rem;

      p {
        margin-right: 0.5rem;
      }
    }
    @media (min-width: 992px) {
      p {
        margin-right: 0.75rem;
      }
    }
    @media (min-width: 1200px) {
      p {
        margin-right: 1rem;
      }
    }
  }
  .compact-day-selector {
    display: flex;

    border: 1px solid var(--grey-100);
    border-radius: 4px;
    overflow: hidden;
  }

  .compact-day {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px; /* Smaller width */
    height: 30px; /* Smaller height */
    background-color: #f0f0f0; /* Default background */
    color: #000; /* Text color */
    font-size: 1rem; /* Smaller font size */

    transition: background-color 0.3s, color 0.3s;
  }

  .compact-day.selected {
    background-color: var(--primary-500); /* Highlight color */
    color: #fff;
  }

  .actions {
    display: flex;
    flex-direction: row;

    justify-content: flex-end;
    align-self: center;
    padding-right: 0.75rem;
  }
  .edit-btn,
  .delete-btn {
    letter-spacing: var(--letterSpacing);
    cursor: pointer;
    height: 30px;
  }
  .edit-btn {
    color: var(--white);
    background: var(--green-light);
    margin-bottom: 0.75rem;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
    :hover {
      background: var(--green-dark);
    }
  }
  .delete-btn {
    color: var(--white);
    background: var(--red-light);
    @media (min-width: 576px) {
      margin-left: 0.75rem;
    }
    :hover {
      background: var(--red-dark);
    }
  }
`;

export default Wrapper;
