import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../context/appContext";

const OAuthCallback = () => {
  const { handleOAuthCallback } = useAppContext();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");

    if (code) {
      handleOAuthCallback(code)
        .then((data) => {
          if (data.success) {
            const channel = new BroadcastChannel("oauth_channel");
            channel.postMessage({ token: data.token });
            channel.close();
            window.close();
          }
        })
        .catch((error) => {
          console.error("Error handling OAuth callback:", error);
        });
    }
  }, [location, handleOAuthCallback]);

  return <div>Authenticating...</div>;
};

export default OAuthCallback;
