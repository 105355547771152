import { AiFillEdit } from "react-icons/ai";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/Location";

const ItemLocation = ({ itemLocation }) => {
  const { setEditItemLocation } = useAppContext();

  return (
    <Wrapper>
      <div className="item-location">
        <b>{itemLocation.locationName}</b>
        {itemLocation.locationName !== "Default" && (
          <button
            className="item-location-button"
            onClick={() => setEditItemLocation(itemLocation._id)}
          >
            <AiFillEdit />
          </button>
        )}
      </div>
    </Wrapper>
  );
};

export default ItemLocation;
