import styled from "styled-components";

const Wrapper = styled.section`
  h3 {
    margin-top: 0;
  }
  .reset-btn {
    font-size: 1.15rem;

    height: 3rem;

    margin-top: 1rem;
  }
  .form {
    border-radius: 10px;
    width: 100%;
    background: var(--white);
    padding: 1rem 1rem 1rem;
    box-shadow: var(--shadow-2);
    margin: 0;

    box-shadow: var(--shadow-2);

    max-width: 100%;
    width: 100%;
  }

  .profile-location {
    border-radius: 10px;
    width: 100%;
    background: var(--white);
    padding: 0.5rem 1rem 0.75rem;
    box-shadow: var(--shadow-2);
    margin-top: 1rem;

    box-shadow: var(--shadow-2);
    @media (min-width: 768px) {
      padding: 1rem 1rem 1rem;
    }
    h3 {
      margin-bottom: 0.25rem;
    }
  }
  .profile-location-add {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }
  .form-div {
  }

  .form-row {
    margin-bottom: 0;
  }
  .form-center {
    display: grid;
    row-gap: 0.5rem;
  }
  .form-center button {
    align-self: end;
    height: 35px;
    margin-top: 1rem;
  }
  .btn-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    align-self: flex-end;

    button {
      height: 35px;
    }
  }
  .clear-btn {
    background: var(--grey-500);
  }
  .clear-btn:hover {
    background: var(--black);
  }
  @media (min-width: 992px) {
    .form-center {
      grid-template-columns: 1fr 1fr;
      align-items: center;
      column-gap: 1rem;
    }
    .btn-container {
      margin-top: 0;
    }
  }
  @media (min-width: 1120px) {
    .form-center {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .form-center button {
      margin-top: 0;
    }
  }
`;

export default Wrapper;
