import { FormRow, FormRowSelect } from "..";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/AddItem";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { BsQuestionCircle } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const AddProvider = () => {
  const { t } = useTranslation();
  const {
    isLoading,
    isAddingProvider,
    isEditingProvider,
    displayAlert,
    providerName,
    number,
    notes,
    website,
    deliveryDays,
    deliveryDaysOptions,
    deliveryTime,
    holiday,
    email,
    providerId,
    passwordInfo,
    minimumOrder,
    orderDate,
    representName,
    handleChange,
    clearValues,
    createProvider,
    editProvider,
    setDeliveryDay,
    items,
    trashedModels,
    selectedTrashModel,
    getItems,
    getTrashedItems,
    editProviderId,
    deleteProvider,
  } = useAppContext();

  const [canDeleteProvider, setCanDeleteProvider] = useState(true);
  const [deleteReason, setDeleteReason] = useState("");
  const [activeItemsCount, setActiveItemsCount] = useState(0);
  const [trashedItemsCount, setTrashedItemsCount] = useState(0);

  useEffect(() => {
    if (!items.length) {
      getItems();
    }
    if (!trashedModels.length || selectedTrashModel !== "item") {
      getTrashedItems("item");
    }
  }, [isEditingProvider]);

  useEffect(() => {
    const checkProviderDeletionEligibility = () => {
      const activeItems = items.filter(
        (item) => item.provider === editProviderId
      );
      const trashedItems = trashedModels.filter(
        (item) => item.provider === editProviderId
      );

      setActiveItemsCount(activeItems.length);
      setTrashedItemsCount(trashedItems.length);

      if (activeItems.length > 0 || trashedItems.length > 0) {
        setCanDeleteProvider(false);
        setDeleteReason(t("Provider has active or trashed items linked."));
      } else {
        setCanDeleteProvider(true);
        setDeleteReason("");
      }
    };

    if (isEditingProvider) {
      checkProviderDeletionEligibility();
    }
  }, [isEditingProvider, items, trashedModels, editProviderId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!providerName) {
      displayAlert();
      return;
    }
    if (isEditingProvider) {
      editProvider();
      return;
    }
    createProvider();
  };

  const handleProviderInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({ name, value });
  };

  const handleDeleteProvider = () => {
    if (canDeleteProvider) {
      deleteProvider(editProviderId);
    } else {
      console.log("Deletion not allowed due to linked items.");
    }
  };
  const tooltipContent = !canDeleteProvider
    ? t("deletionWarning", {
        count: activeItemsCount,
        trashedCount: trashedItemsCount,
      })
    : "";

  return (
    <Wrapper>
      <form className={isAddingProvider ? "form_active" : "form"}>
        <h3>{isEditingProvider ? t("edit provider") : t("add provider")}</h3>
        <div className="form-center">
          <FormRow
            type="text"
            name="providerName"
            labelText={t("Provider Name")}
            value={providerName}
            handleChange={handleProviderInput}
          />
          <FormRow
            type="text"
            name="number"
            labelText={t("Number")}
            value={number}
            handleChange={handleProviderInput}
          />
          <FormRow
            type="text"
            name="website"
            labelText={t("Website")}
            value={website}
            handleChange={handleProviderInput}
            tooltipContent={t(
              "Provider's website URL, please include http:// or https://"
            )}
          />
          <div className="day-selector">
            <p>{t("Delivery Days")}</p>
            <div>
              {deliveryDaysOptions.map((day) => (
                <button
                  key={day}
                  type="button"
                  className={`day-button ${
                    deliveryDays && deliveryDays.includes(day) ? "selected" : ""
                  }`}
                  onClick={() => setDeliveryDay(day)}
                >
                  {t(day)}
                </button>
              ))}
            </div>
          </div>

          {/* <FormRow
            type="text"
            name="deliveryDay"
            value={deliveryDay}
            handleChange={handleProviderInput}
          /> */}
          <FormRow
            type="text"
            name="deliveryTime"
            labelText={t("Delivery Time")}
            value={deliveryTime}
            handleChange={handleProviderInput}
          />
          <FormRow
            type="text"
            name="holiday"
            labelText={t("Holiday")}
            value={holiday}
            handleChange={handleProviderInput}
          />
          <FormRow
            type="text"
            name="email"
            labelText={t("Email")}
            value={email}
            handleChange={handleProviderInput}
          />
          <FormRow
            type="text"
            name="providerId"
            labelText={t("Provider ID")}
            value={providerId}
            handleChange={handleProviderInput}
          />
          <FormRow
            type="text"
            name="passwordInfo"
            labelText={t("Password Info")}
            value={passwordInfo}
            handleChange={handleProviderInput}
          />
          <FormRow
            type="number"
            name="minimumOrder"
            labelText={t("Minimum Order")}
            value={minimumOrder}
            handleChange={handleProviderInput}
          />
          <FormRow
            type="text"
            name="orderDate"
            labelText={t("Order Date")}
            value={orderDate}
            handleChange={handleProviderInput}
          />
          <FormRow
            type="text"
            name="representName"
            labelText={t("Representative Name")}
            value={representName}
            handleChange={handleProviderInput}
          />

          <FormRow
            type="text"
            name="notes"
            labelText={t("Notes")}
            value={notes}
            handleChange={handleProviderInput}
          />
          <div className="btn-container" style={{ marginTop: "1rem" }}>
            {isEditingProvider && (
              <div style={{ display: "flex" }}>
                <button
                  type="button"
                  className="btn delete-btn"
                  onClick={handleDeleteProvider}
                  disabled={!canDeleteProvider} // Corrected the logic here
                  style={{
                    backgroundColor: canDeleteProvider
                      ? "var(--red-light)"
                      : "var(--grey-600)",
                    color: "white",
                    cursor: canDeleteProvider ? "pointer" : "not-allowed",
                    opacity: canDeleteProvider ? 1 : 0.7,
                    transition: "background-color 0.3s ease",
                  }}
                >
                  {t("Delete")}
                </button>
                {!canDeleteProvider && (
                  <Tippy content={tooltipContent}>
                    <div style={{ width: "2rem" }}>
                      <BsQuestionCircle
                        style={{ marginTop: "1.25rem", marginLeft: "0.5rem" }}
                        className="info-icon"
                      />
                    </div>
                  </Tippy>
                )}
              </div>
            )}
            <button
              type="submit"
              className="btn btn-block submit-btn"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {t("submit")}
            </button>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

export default AddProvider;
