import { MenusContainer, AddMenuContainer, AddMenu } from "../../components";

const Menu = () => {
  return (
    <div>
      <AddMenuContainer />
      <AddMenu />
      <MenusContainer />
    </div>
  );
};

export default Menu;
