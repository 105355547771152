import { useAppContext } from "../../context/appContext";
import { useEffect, useState, useMemo } from "react";
import Item from "./ItemOrder";
import ItemCondensedO from "./ItemCondensedO";
import PageBtnContainer from "../PageBtnContainer";
import { AddOrder, ResetQuantities, ExportData, AutoOrder } from "../";
import Wrapper from "../../assets/wrappers/ItemsContainer";

import { AiFillEdit } from "react-icons/ai";
//import { BiGridAlt } from "react-icons/bi";
import { BsGrid3X3 } from "react-icons/bs";
import { TbFileExport } from "react-icons/tb";
import { useTranslation } from "react-i18next";

import { FaTimes, FaRedo } from "react-icons/fa";
import { MdOutlineAddchart } from "react-icons/md";
import { IoDocumentText } from "react-icons/io5";

import { LiaRedoAltSolid } from "react-icons/lia";

const ItemsContainerO = () => {
  const { t } = useTranslation();
  const {
    getItems,
    items,
    search,
    searchProvider,
    searchCategory,
    searchType,
    searchLocation,
    searchKitchen,
    sort,
    isCondensed,
    toggleCondensed,
    page,
    viewItemName,
    viewItemProportions,
    viewItemPrice,
    viewItemNeeded,
    viewItemSupplier,
    viewItemAmount,
    viewItemCode,
    viewItemProvider,
    viewItemLocation,
    viewItemCategory,
    viewItemType,
    viewItemKitchen,
    viewItemInventoryQuantity,
    viewItemTotal,
    isCreatingOrder,
    toggleCreateOrder,
    toggleResetQuantities,
    isResettingQuantities,
    toggleAutoOrder,
    isAutoOrdering,
    itemsPerPage,
    includeAlternatives,
    alternativePairs,
    providers,
    itemLocations,
    itemCategories,
    itemTypes,
    itemKitchens,
    isEditing,
    isAdding,
    toggleExportData,
    isExportingData,
    exportContext,
  } = useAppContext();

  const [debouncedSearch, setDebouncedSearch] = useState(search);
  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearch(search);
    }, 800);

    return () => clearTimeout(timerId);
  }, [search]);

  useEffect(() => {
    if (items.length < 1) {
      getItems();
    }
  }, []);

  const alternativeItemIds = useMemo(
    () => new Set(alternativePairs.map((pair) => pair.alternative._id)),
    [alternativePairs]
  );

  const providerIdMap = useMemo(
    () =>
      new Map(
        providers.map((provider) => [provider.providerName, provider._id])
      ),
    [providers]
  );
  const locationIdMap = useMemo(
    () => new Map(itemLocations.map((item) => [item.locationName, item._id])),
    [itemLocations]
  );
  const categoryIdMap = useMemo(
    () => new Map(itemCategories.map((item) => [item.categoryName, item._id])),
    [itemCategories]
  );
  const typeIdMap = useMemo(
    () => new Map(itemTypes.map((item) => [item.typeName, item._id])),
    [itemTypes]
  );
  const kitchenIdMap = useMemo(
    () => new Map(itemKitchens.map((item) => [item.kitchenName, item._id])),
    [itemKitchens]
  );

  const filterItems = useMemo(() => {
    return items
      .filter((item) => {
        if (
          debouncedSearch &&
          !(
            item.itemName
              .toLowerCase()
              .includes(debouncedSearch.toLowerCase()) ||
            item.itemSupplier
              .toLowerCase()
              .includes(debouncedSearch.toLowerCase()) ||
            item.itemCode.toLowerCase().includes(debouncedSearch.toLowerCase())
          )
        ) {
          return false;
        }

        if (
          (searchProvider !== "all" &&
            providerIdMap.get(searchProvider) !== item.provider) ||
          (searchCategory !== "all" &&
            categoryIdMap.get(searchCategory) !== item.itemCategory) ||
          (searchType !== "all" &&
            typeIdMap.get(searchType) !== item.itemType) ||
          (searchLocation !== "all" &&
            locationIdMap.get(searchLocation) !== item.itemLocation) ||
          (searchKitchen !== "all" &&
            kitchenIdMap.get(searchKitchen) !== item.itemKitchen)
        ) {
          return false;
        }

        if (alternativeItemIds.has(item._id)) {
          if (!includeAlternatives && item.itemQuantity <= 0) {
            return false;
          }
        }

        return true;
      })
      .sort((a, b) => {
        switch (sort) {
          case "a-z":
            return a.itemName.localeCompare(b.itemName);
          case "z-a":
            return b.itemName.localeCompare(a.itemName);
          case "Order Quantity high-low":
            return b.itemQuantity - a.itemQuantity;
          case "Order Quantity low-high":
            return a.itemQuantity - b.itemQuantity;
          case "Inventory Quantity high-low":
            return b.itemInventoryQuantity - a.itemInventoryQuantity;
          case "Inventory Quantity low-high":
            return a.itemInventoryQuantity - b.itemInventoryQuantity;
          case "price high-low":
            return b.price - a.price;
          case "price low-high":
            return a.price - b.price;
          default:
            return 0;
        }
      });
  }, [
    items,
    debouncedSearch,
    searchProvider,
    searchCategory,
    searchLocation,
    searchType,
    searchKitchen,
    sort,
    includeAlternatives,
    alternativeItemIds,
    providerIdMap,
    locationIdMap,
    categoryIdMap,
    typeIdMap,
    kitchenIdMap,
  ]);

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filterItems.slice(startIndex, endIndex);

  const numOfPages = Math.ceil(filterItems.length / itemsPerPage);
  const getPluralForm = (count, single, plural) =>
    count === 1 ? single : plural;
  if (currentItems.length === 0) {
    return (
      <Wrapper>
        <h2>{t("No items to display...")}</h2>
      </Wrapper>
    );
  }

  const CommonElements = ({
    toggleResetQuantities,
    isResettingQuantities,
    toggleCreateOrder,
    isCreatingOrder,
    toggleExportData,
    isExportingData,
    exportContext,
  }) => (
    <>
      <Wrapper>
        <div className="items-title">
          <h5 className="items-title-h5">
            {filterItems.length}{" "}
            {getPluralForm(filterItems.length, t("item"), t("items"))}
          </h5>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="items-button-container">
              <button
                style={{
                  marginRight: "1rem",
                  width: "2.5rem",
                  height: "2.5rem",
                  padding: "0",
                  paddingTop: "0.25rem",
                }}
                className={`btn btn-green`}
                onClick={() => toggleResetQuantities()}
              >
                {isResettingQuantities ? (
                  <FaTimes />
                ) : (
                  <LiaRedoAltSolid
                    style={{ height: "1.5rem", width: "1.5rem" }}
                  />
                )}
                {/* {isResettingQuantities ? t("Close") : t("Reset Quantities")} */}
              </button>
              <button
                className={isAutoOrdering ? `btn btn-danger ` : `btn btn-green`}
                style={{
                  marginRight: "1rem",
                  width: "2.5rem",
                  height: "2.5rem",
                  padding: "0",
                  paddingTop: "0.25rem",
                  backgroundColor: "#A8D5BA",
                  color: "black",
                }}
                onClick={() => toggleAutoOrder()}
              >
                {isAutoOrdering ? (
                  <FaTimes />
                ) : (
                  <MdOutlineAddchart
                    style={{ height: "1.5rem", width: "1.5rem" }}
                  />
                )}
                {/* {isAutoOrdering ? t("Close") : t("Auto Order")} */}
              </button>
              <button
                style={{
                  marginRight: "1rem",
                  width: "2.5rem",
                  height: "2.5rem",
                  padding: "0",
                  paddingTop: "0.25rem",
                }}
                className={`btn`}
                onClick={() => toggleCreateOrder()}
              >
                {isCreatingOrder ? (
                  <FaTimes />
                ) : (
                  <IoDocumentText
                    style={{ height: "1.5rem", width: "1.5rem" }}
                  />
                )}
                {/* {isCreatingOrder ? t("Close") : t("Create Order")} */}
              </button>
              <button
                className="btn"
                onClick={() => {
                  toggleExportData("customItems");
                }}
                style={{
                  marginTop: "0rem",
                  padding: "0",
                  paddingTop: "0.25rem",
                  width: "2.5rem",
                  height: "2.5rem",
                  backgroundColor: "var(--grey-200)",
                  color: "var(--grey-900)",
                }}
              >
                {isExportingData ? (
                  <FaTimes />
                ) : (
                  <TbFileExport style={{ height: "1.5rem", width: "1.5rem" }} />
                )}
              </button>
            </div>
            {window.innerWidth > 1179 && (
              <div
                style={{
                  display: "flex",
                  marginRight: "1rem",
                  marginBottom: "0.75rem",
                }}
              >
                {/* <p style={{ marginRight: "0.25rem" }}>GridView</p> */}
                <div
                  className="grid-button"
                  onClick={() => toggleCondensed(!isCondensed)}
                  style={{
                    backgroundColor: isCondensed ? "#0055aa" : "initial",
                    color: isCondensed ? "white" : "initial",
                    width: "2.5rem",
                    height: "2.5rem",
                    padding: "0.5rem 0 0 0.5rem",
                  }}
                >
                  <BsGrid3X3
                    style={{
                      width: "1.5rem",
                      height: "1.55rem",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    </>
  );

  if (isCondensed) {
    return (
      <>
        <ResetQuantities filteredItems={filterItems} />
        <AutoOrder filteredItems={filterItems} />
        <AddOrder filteredItems={filterItems} />
        <ExportData customItems={filterItems} />
        <CommonElements
          toggleResetQuantities={toggleResetQuantities}
          isResettingQuantities={isResettingQuantities}
          toggleCreateOrder={toggleCreateOrder}
          isCreatingOrder={isCreatingOrder}
          toggleExportData={toggleExportData}
          isExportingData={isExportingData}
          exportContext={exportContext}
        />
        <Wrapper>
          <div className="items">
            <div className="grid-titles">
              <div style={{ width: "3.75%", paddingLeft: "0" }}>{t("Qty")}</div>
              {viewItemType && <div style={{ width: "6%" }}>{t("Type")}</div>}
              {viewItemName && (
                <div style={{ width: "15.5%" }}>{t("Name")}</div>
              )}
              {viewItemProportions && (
                <div style={{ width: "9%" }}>{t("Prop")}</div>
              )}
              {viewItemSupplier && (
                <div style={{ width: "11%" }}>{t("Supplier")}</div>
              )}
              {viewItemProvider && (
                <div style={{ width: "9%" }}>{t("Provider")}</div>
              )}
              {viewItemCode && <div style={{ width: "9%" }}>{t("#")}</div>}
              {viewItemPrice && <div style={{ width: "5%" }}>{t("Price")}</div>}
              {viewItemLocation && (
                <div style={{ width: "10%" }}>{t("Location")}</div>
              )}
              {viewItemCategory && (
                <div style={{ width: "11%" }}>{t("Category")}</div>
              )}
              {viewItemAmount && (
                <div style={{ width: "6%" }}>{t("Amount")}</div>
              )}
              {viewItemInventoryQuantity && (
                <>
                  {/* <div style={{ maxWidth: "2%", paddingLeft: "0" }}>Unity</div> */}
                  <div style={{ width: "10%" }}>{t("Inventory")}</div>
                </>
              )}
              {viewItemNeeded && (
                <div style={{ width: "11.5%" }}>{t("Need|Reorder")}</div>
              )}
              {viewItemTotal && (
                <div style={{ width: "5.5%" }}>{t("Total")}</div>
              )}
              <div style={{ width: "6.5%" }}>{t("Order")}</div>

              <div
                style={{
                  width: "2.25%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AiFillEdit />
              </div>
            </div>
            <div className={`items-condensed-container`}>
              {currentItems.map((item) => {
                return <ItemCondensedO key={item._id} item={item} />;
              })}
            </div>
          </div>
          {numOfPages > 1 && <PageBtnContainer numOfPages={numOfPages} />}
        </Wrapper>
      </>
    );
  }
  return (
    <>
      <ResetQuantities filteredItems={filterItems} />
      <AutoOrder filteredItems={filterItems} />
      <AddOrder filteredItems={filterItems} />
      <ExportData customItems={filterItems} />
      <CommonElements
        toggleResetQuantities={toggleResetQuantities}
        isResettingQuantities={isResettingQuantities}
        toggleCreateOrder={toggleCreateOrder}
        isCreatingOrder={isCreatingOrder}
        toggleExportData={toggleExportData}
        isExportingData={isExportingData}
        exportContext={exportContext}
      />
      <Wrapper>
        <div className={`items`}>
          {currentItems.map((item) => {
            return <Item key={item._id} item={item} />;
          })}
        </div>
        {numOfPages > 1 && <PageBtnContainer numOfPages={numOfPages} />}
      </Wrapper>
    </>
  );
};

export default ItemsContainerO;
