import { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/Orders";
import { FaTimes } from "react-icons/fa";
import { FormRow } from "../";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

import { BsQuestionCircle } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { gsap } from "gsap";
import { useTranslation } from "react-i18next";

const SendOrders = () => {
  const { t } = useTranslation();
  const {
    isLoading,
    handleChange,
    user,
    orders,
    providers,
    getProviders,
    getOrders,
    toggleSendOrders,
    isSendingOrders,
    sendOrdersByEmail,
  } = useAppContext();
  const [groupedOrders, setGroupedOrders] = useState({});
  const [selectedOrders, setSelectedOrders] = useState({});
  const [orderNotes, setOrderNotes] = useState({});
  const [isConfirmingSend, setIsConfirmingSend] = useState(false);
  const [sendCopyToSelf, setSendCopyToSelf] = useState(true);
  const [providersLoaded, setProvidersLoaded] = useState(false);

  useEffect(() => {
    if (providers.length < 1) {
      getProviders().then(() => {
        setProvidersLoaded(true);
      });
    } else {
      setProvidersLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (!providersLoaded) return;

    const newGroupedOrders = {};
    const newSelectedOrders = {};
    const newOrderNotes = {};

    orders.forEach((order) => {
      const provider = providers.find((p) => p._id === order.providerId);
      const isValidProviderEmail = provider && isValidEmail(provider.email);

      if (order.status === "Pending") {
        if (!newGroupedOrders[provider._id]) {
          newGroupedOrders[provider._id] = [];
        }
        newGroupedOrders[provider._id].push(order);
        newSelectedOrders[order._id] =
          !order.emailSentAt && isValidProviderEmail;
        newOrderNotes[order._id] = "Order details";
      }
    });

    setGroupedOrders(newGroupedOrders);
    setSelectedOrders(newSelectedOrders);
    setOrderNotes(newOrderNotes);
  }, [orders, providers]);

  useEffect(() => {
    let timeout;
    if (isConfirmingSend) {
      timeout = setTimeout(() => {
        setIsConfirmingSend(false);
      }, 3000);
    }
    return () => clearTimeout(timeout);
  }, [isConfirmingSend]);

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleToggleOrder = (orderId) => {
    setSelectedOrders((prev) => ({
      ...prev,
      [orderId]: !prev[orderId],
    }));
  };

  const handleNoteChange = (orderId, note) => {
    setOrderNotes((prev) => ({
      ...prev,
      [orderId]: note,
    }));
  };
  const handleCheckboxChange = () => {
    setSendCopyToSelf(!sendCopyToSelf);
  };

  const sendSelectedOrders = () => {
    Object.entries(selectedOrders).forEach(async ([orderId, isSelected]) => {
      if (!isSelected) return;

      const order = orders.find((o) => o._id === orderId);
      const provider = providers.find((p) => p._id === order.providerId);
      const formatDateForExport = (date) => {
        return new Date(date)
          .toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/\//g, "-");
      };

      const currentDay = formatDateForExport(new Date());

      if (!provider || !provider.email) {
        console.error("Missing provider email");
        return;
      }

      const preparedData = order.items
        .filter((item) => item.itemQuantity > 0)
        .map((item) => ({
          itemName: item.itemProportions
            ? `${item.itemName} (${item.itemProportions})`
            : item.itemName,
          itemType: item.itemType,
          supplier: item.itemSupplier,
          itemCode: item.itemCode,
          price: item.price,
          quantity: item.itemQuantity,
          total: item.totalItemCost,
        }));

      const emailContent = {
        orderId: order._id,
        to: provider.email,
        from: user.email,
        subject: `${order.orderName} order - ${currentDay}`,
        text: orderNotes[orderId],
        preparedData: preparedData,
        exportName: `${order.orderName} order - ${currentDay}`,
        sendCopy: sendCopyToSelf,
      };

      if (isConfirmingSend) {
        await sendOrdersByEmail(emailContent);
      } else {
        setIsConfirmingSend(true);
      }
    });
  };

  const formatDate = (date) => {
    return new Date(date)
      .toLocaleDateString("en-CA", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "-");
  };

  const handleSendClose = () => {
    if (isLoading) return;
    handleChange({ name: "isSendingOrders", value: !isSendingOrders });
  };

  const selectedOrdersCount = Object.keys(selectedOrders).filter(
    (orderId) => selectedOrders[orderId]
  ).length;

  const getButtonText = () => {
    if (isConfirmingSend) {
      return t("Confirm Send");
    } else {
      const prefix = selectedOrdersCount === 1 ? t("Send Single") : t("Send");
      const ordersKey =
        selectedOrdersCount === 1 ? "Selected Order" : "Selected Orders";

      return `${prefix} ${selectedOrdersCount} ${t(ordersKey)}`;
    }
  };
  //ANIMATIONS
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useLayoutEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight + 25);
    }

    const tl = gsap.timeline();

    if (isSendingOrders) {
      setIsVisible(true);
      tl.set(contentRef.current, {
        height: 0,
        padding: 0,
        autoAlpha: 0,
      }).to(contentRef.current, {
        height: contentHeight,
        padding: "1rem 1rem 1rem 1rem",
        autoAlpha: 1,
        duration: 0.5,
      });
    } else if (contentRef.current) {
      tl.to(contentRef.current, {
        height: 0,
        padding: 0,
        autoAlpha: 0,
        duration: 0.5,
      }).then(() => setIsVisible(false));
    }

    return () => tl.kill();
  }, [isSendingOrders, contentHeight]);

  if (!isVisible && !isSendingOrders) return null;

  return (
    <Wrapper>
      <div ref={contentRef} className={"send-orders-container"}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "0.5rem",
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <h4>
              {t("Send Orders from")} {user.email}
            </h4>
            <Tippy
              content={t(
                "This will send orders from the account's email to the email you set for each provider. You have to verify your account's email to be able to use this feature, contact support for more info"
              )}
            >
              <div>
                <BsQuestionCircle
                  style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }}
                  className="info-icon"
                />
              </div>
            </Tippy>
          </div>
          <button
            className="btn delete-btn close-send-orders"
            onClick={handleSendClose}
          >
            <FaTimes style={{ width: "1rem" }} />
          </button>
        </div>
        {Object.entries(groupedOrders).map(([providerId, orders]) => {
          const provider = providers.find(
            (p) => p._id.toString() === providerId
          );
          const isValid =
            provider && provider.email ? isValidEmail(provider.email) : false;
          return (
            <div key={providerId}>
              <h5>
                {provider && provider.email ? (
                  <span>
                    {provider.providerName} :{" "}
                    <span style={{ color: isValid ? "green" : "red" }}>
                      {provider.email}
                    </span>
                  </span>
                ) : (
                  <span>
                    {provider.providerName} :{" "}
                    <span style={{ color: "red" }}>
                      {t("Please set an email for this provider.")}
                    </span>
                  </span>
                )}
              </h5>
              {orders.map((order) => {
                const provider = providers.find(
                  (p) => p._id === order.providerId
                );
                const isValidProviderEmail =
                  provider && isValidEmail(provider.email);

                const backorderedItemsCount = order.items.filter(
                  (item) => item.status === "backordered"
                ).length;
                const missingItemsCount = order.items.filter(
                  (item) => item.status === "missing"
                ).length;
                const statusColor =
                  order.status === "Pending" ? "#FFD700" : "#32CD32";

                return (
                  <div
                    key={order._id}
                    className="order-card-send"
                    onClick={() =>
                      isValidProviderEmail && handleToggleOrder(order._id)
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={selectedOrders[order._id]}
                        onChange={(e) => {
                          e.stopPropagation();
                        }}
                        disabled={!isValidProviderEmail}
                      />
                      <h6>{order.orderName}</h6>
                      <div onClick={(e) => e.stopPropagation()}>
                        <FormRow
                          type="text"
                          name={`note-${order._id}`}
                          labelText={t("Order Details")}
                          value={orderNotes[order._id] || ""}
                          handleChange={(e) =>
                            handleNoteChange(
                              order._id.toString(),
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                    {order.emailSentAt && (
                      <div
                        className="subscription-status"
                        style={{ color: "green" }}
                      >
                        <IoMdCheckmarkCircleOutline
                          style={{ verticalAlign: "middle" }}
                        />
                        {t("order sent on")} {formatDate(order.emailSentAt)}
                      </div>
                    )}
                    <div className="order-card-details">
                      <p>
                        {t("Total Cost")}: ${order.totalCost.toFixed(2)}
                      </p>
                      <p>
                        {t("Items")}: {order.items.length}
                      </p>
                      {backorderedItemsCount > 0 && (
                        <p style={{ color: "#FFA07A" }}>
                          {t("Backordered")}: {backorderedItemsCount}
                        </p>
                      )}
                      {missingItemsCount > 0 && (
                        <p style={{ color: "#DC143C" }}>
                          {t("Missing")}: {missingItemsCount}
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        minWidth: "20rem",
                      }}
                    >
                      {order.orderDeliveryDay &&
                        order.orderDeliveryDay !== "None" && (
                          <div className="delivery-day-icon">
                            {order.orderDeliveryDay}
                          </div>
                        )}
                      <span>
                        {new Date(order.createdAt).toLocaleDateString()}
                      </span>
                      <h5 style={{ color: statusColor, margin: "0" }}>
                        {t(order.status)}
                      </h5>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <label className="view-container">
            <h5>{t("Send a copy to myself")}</h5>
            <input
              type="checkbox"
              checked={sendCopyToSelf}
              onChange={handleCheckboxChange}
            />
          </label>
          {selectedOrdersCount > 0 && (
            <button
              className="btn"
              disabled={isLoading}
              onClick={sendSelectedOrders}
              style={{ marginTop: "0.5rem" }}
            >
              {getButtonText()}
            </button>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default SendOrders;
