import Wrapper from "../../assets/wrappers/AddItemContainer";
import { useAppContext } from "../../context/appContext";
import Alert from "../Alert";
import { useTranslation } from "react-i18next";

const EditViewContainer = () => {
  const { t } = useTranslation();
  const {
    isEditing,
    isAdding,
    clearValues,
    isEditingView,
    setEditView,
    isCondensed,
  } = useAppContext();
  if (isCondensed & !isEditing & !isAdding) {
    return (
      <Wrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "0.5rem",
          }}
        >
          <button
            type="button"
            style={{
              maxWidth: "10rem",
              marginLeft: "0.2rem",
              marginRight: "1rem",
            }}
            className={isEditingView ? `btn btn-danger` : `btn`}
            onClick={!isEditingView ? () => setEditView() : () => clearValues()}
          >
            {t(isEditingView ? "Close" : "Display")}
          </button>
        </div>
      </Wrapper>
    );
  }
};
export default EditViewContainer;
