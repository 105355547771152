import { BsQuestionCircle } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const AlternativeFormRowSelect = ({
  labelText,
  name,
  value,
  handleChange,
  list,
  style,
  selectStyle,
  disabled,
  tooltipContent,
}) => {
  return (
    <div className="form-row" style={style}>
      <div style={{ display: "flex" }}>
        <label htmlFor={name} className="form-label">
          {labelText || name}
        </label>
        {tooltipContent && (
          <Tippy content={tooltipContent}>
            <div>
              <BsQuestionCircle className="info-icon" />
            </div>
          </Tippy>
        )}
      </div>
      <select
        id={name}
        name={name}
        value={value}
        onChange={handleChange}
        className="form-select"
        style={selectStyle}
        disabled={disabled}
      >
        {list.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AlternativeFormRowSelect;
