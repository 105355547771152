import { useAppContext } from "../../context/appContext";
import { HiChevronDoubleLeft, HiChevronDoubleRight } from "react-icons/hi";
import Wrapper from "../../assets/wrappers/PageBtnContainer";

const TrashedPageBtnContainer = ({
  numOfPages,
  currentPage,
  setCurrentPage,
}) => {
  const pages = Array.from({ length: numOfPages }, (_, index) => index + 1);

  const nextPage = () => {
    const newPage = currentPage + 1 > numOfPages ? 1 : currentPage + 1;
    setCurrentPage(newPage);
  };

  const prevPage = () => {
    const newPage = currentPage - 1 < 1 ? numOfPages : currentPage - 1;
    setCurrentPage(newPage);
  };

  return (
    <Wrapper>
      <button className="prev-btn" onClick={prevPage}>
        <HiChevronDoubleLeft />
        Prev
      </button>
      <div className="btn-container">
        {pages.map((pageNumber) => (
          <button
            type="button"
            className={
              pageNumber === currentPage ? "pageBtn active" : "pageBtn"
            }
            key={pageNumber}
            onClick={() => setCurrentPage(pageNumber)}
          >
            {pageNumber}
          </button>
        ))}
      </div>
      <button className="next-btn" onClick={nextPage}>
        Next
        <HiChevronDoubleRight />
      </button>
    </Wrapper>
  );
};

export default TrashedPageBtnContainer;
