import styled from "styled-components";

const Wrapper = styled.section`
  background: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  box-shadow: var(--shadow-2);
  margin-bottom: 0.75rem;
  padding: 1rem;
  width: 100%;

  &:hover {
    cursor: pointer;
    background: var(--grey-100);
  }

  .menu-item-image {
    border-radius: 10px;
    width: 4rem;
    height: 3.5rem;
    margin-right: 0.5rem;
    margin-top: -0.4rem;
  }
  .menu-item-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    .menu-item-text {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      flex-grow: 1;

      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0;
        text-transform: none;
      }

      h2 {
        font-weight: 800;
        text-transform: capitalize;
        flex: 1 1 100%;
      }

      h3 {
        font-weight: 700;
        color: var(--primary-400);
      }

      h5 {
        font-size: 1.5rem;
      }

      h6 {
        font-size: 1rem;
      }

      h4 {
      }
    }
  }
  .menu-item-content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    padding-top: 0.5rem;
    border-top: 1px solid var(--grey-100);

    .ingredient-details {
      margin-right: 1rem;
      display: flex;
      flex-direction: column;
      width: 100%;
      h4 {
        margin: 0;
      }
    }
  }

  .ingredient {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--grey-200);
    width: 100%;

    &:last-child {
      border-bottom: none;
    }

    .ingredient-name {
      font-weight: 500;
      color: var(--primary-500);
      flex: 1;
    }

    .ingredient-quantity,
    .ingredient-cost,
    .ingredient-waste {
      flex: 0 0 25%;
      text-align: center;
      text-transform: none;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    @media (min-width: 768px) {
      padding-right: 0.75rem;
      justify-content: right;
      align-items: center;
      flex-direction: row;
    }
  }

  .edit-btn,
  .delete-btn {
    letter-spacing: var(--letterSpacing);
    cursor: pointer;
    height: 30px;
  }

  .edit-btn {
    color: var(--white);
    background: var(--green-light);

    @media (min-width: 768px) {
    }
    :hover {
      background: var(--green-dark);
    }
  }

  .delete-btn {
    color: var(--white);
    background: var(--red-light);

    @media (min-width: 576px) {
      margin-left: 0.75rem;
    }

    :hover {
      background: var(--red-dark);
    }
  }
`;

export default Wrapper;
