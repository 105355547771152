import Wrapper from "../../assets/wrappers/AlternativeContainer";
import { useAppContext } from "../../context/appContext";
import { FormRow } from "..";
import ItemsAlternativesContainer from "./ItemsAlternativesContainer";
import SelectedAlternatives from "./SelectedAlternatives";

import { FaTimes } from "react-icons/fa";

import { useTranslation } from "react-i18next";

const AlternativeContainer = (item) => {
  const { t } = useTranslation();
  const {
    showAlternatives,
    itemName,
    itemSupplier,
    searchAlternatives,
    handleChange,
    isLoading,
    editItem,
    toggleAlternatives,
    isEditing,
  } = useAppContext();

  const handleSubmit = (e) => {
    e.preventDefault();
    editItem();
  };

  const handleSearch = (e) => {
    if (isLoading) return;
    handleChange({ name: e.target.name, value: e.target.value });
  };
  if (showAlternatives) {
    return (
      <Wrapper>
        <div>
          {/* <div className="alternative-container">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <h4 style={{ marginBottom: "0" }}>{itemName}</h4>
              <span
                style={{
                  color: "var(--primary-400)",
                  fontSize: "1.25rem",
                  marginLeft: "0.5rem",
                }}
              >
                {itemSupplier}
              </span>
            </div>
            <button
                className="btn delete-btn alternative-close"
                onClick={toggleAlternatives}
              >
                <FaTimes style={{ width: "1rem" }} />
              </button>
          </div> */}
          {isEditing && (
            <FormRow
              type="text"
              name="searchAlternatives"
              labelText={t("Search for alternatives")}
              value={searchAlternatives}
              handleChange={handleSearch}
            ></FormRow>
          )}
          {searchAlternatives.length > 0 && <ItemsAlternativesContainer />}
          {/* <h5 style={{ marginTop: "1rem", marginBottom: "0" }}>
            Alternatives:
          </h5>
          <SelectedAlternatives /> */}
          {/* {isEditing && (
              <button
                type="submit"
                className="btn btn-block submit-btn"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                submit
              </button>
            )} */}
        </div>
      </Wrapper>
    );
  }
};

export default AlternativeContainer;
