import { FormRow, FormRowSelect } from "..";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/AddItem";
import { useTranslation } from "react-i18next";

const AddMenu = () => {
  const { t } = useTranslation();
  const {
    isLoading,
    isAddingMenu,
    isEditingMenu,
    displayAlert,
    menuName,
    menuDescription,
    handleChange,
    createMenu,
    editMenu,
    deleteMenu,
    editMenuId,
  } = useAppContext();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!menuName) {
      displayAlert();
      return;
    }
    if (isEditingMenu) {
      editMenu();
      return;
    }
    createMenu();
  };

  const handleMenuInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({ name, value });
  };

  return (
    <Wrapper>
      <form className={isAddingMenu || isEditingMenu ? "form_active" : "form"}>
        <h3>{isEditingMenu ? t("edit menu") : t("add menu")}</h3>
        <div className="form-center">
          <FormRow
            type="text"
            name="menuName"
            value={menuName}
            handleChange={handleMenuInput}
            labelText={t("Menu Name")}
          />
          <FormRow
            type="text"
            name="menuDescription"
            value={menuDescription}
            handleChange={handleMenuInput}
            labelText={t("Menu Description")}
          />
          <div className="btn-container">
            <button
              type="submit"
              className="btn btn-block submit-btn"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {t("submit")}
            </button>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

export default AddMenu;
