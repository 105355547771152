import React from "react";
import { useState, useMemo, useEffect, useLayoutEffect, useRef } from "react";
import { useAppContext } from "../context/appContext.js";
import Wrapper from "../assets/wrappers/ExportSheets.js";

import { exportToExcel } from "react-json-to-excel";
import { FaTimes } from "react-icons/fa";
import jsPDF from "jspdf";
import "jspdf-autotable";
import FormRowSelect from "./FormRowSelect.js";
import { useLocation } from "react-router-dom";
import { gsap } from "gsap";
import { useTranslation } from "react-i18next";

const ExportData = ({ customItems }) => {
  const { t } = useTranslation();
  const {
    isLoading,
    isExportingData,
    orders,
    exportingOrderId,
    exportContext,
    itemTypes,
    handleChange,
    searchProvider,
    toggleExportData,
    searchKitchen,
  } = useAppContext();
  const location = useLocation();

  const [exportName, setExportName] = useState("ExportData");
  const [exportFormat, setExportFormat] = useState("pdf");
  const [isExportingPrice, setExportPrice] = useState(true);
  const [isExportingSupplier, setExportSupplier] = useState(true);
  const [isExportingCode, setExportCode] = useState(true);
  const [isExportingType, setExportType] = useState(true);
  const [isExportingTotal, setExportTotal] = useState(true);
  const [isExportingProportions, setExportingProportions] = useState(true);
  const [isExportingAmount, setExportingAmount] = useState(true);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const determineVisibility = () => {
      if (!isExportingData) return false;
      if (
        exportContext === "customItems" &&
        location.pathname.includes("/stock")
      ) {
        return true;
      } else if (
        exportContext === "orders" &&
        location.pathname.includes("/orders")
      ) {
        return true;
      }
      toggleExportData();
      return false;
    };

    setVisible(determineVisibility());
  }, [isExportingData, exportContext, location.pathname]);

  useEffect(() => {
    const currentDate = new Date().toLocaleDateString("en-CA");
    if (exportContext === "orders") {
      const order = orders.find((order) => order._id === exportingOrderId);
      if (order) {
        setExportName(`${order.orderName} - ${currentDate}`);
      }
    } else if (exportContext === "customItems") {
      let name = "";
      if (searchKitchen && searchKitchen !== "all") {
        name += `${searchKitchen} - `;
      }
      if (searchProvider && searchProvider !== "all") {
        name += `${searchProvider} - `;
      }
      name += currentDate;
      setExportName(name);
    }
  }, [exportContext, orders, exportingOrderId, searchProvider, searchKitchen]);

  const dataToExport = useMemo(() => {
    if (exportContext === "orders") {
      return (
        orders.find((order) => order._id === exportingOrderId)?.items || []
      );
    } else if (exportContext === "customItems") {
      return customItems || [];
    }
    return [];
  }, [exportContext, orders, exportingOrderId, customItems]);

  const preparedData = dataToExport
    .filter((item) => item.itemQuantity > 0)
    .map((item) => {
      const itemName = item.itemName;

      let itemType;
      const itemTypeObject = itemTypes.find(
        (type) => type._id === item.itemType
      );

      if (itemTypeObject) {
        itemType = itemTypeObject.typeName;
      } else {
        itemType = item.itemType;
      }

      let itemProportions = item.itemProportions || "";
      if (isExportingProportions && item.itemProportionsAmount > 0) {
        itemProportions += `x${item.itemProportionsAmount}`;
        if (
          item.itemProportionsAmountType &&
          item.itemProportionsAmountType !== "Other"
        ) {
          itemProportions += item.itemProportionsAmountType;
        }
      }

      let itemAmountDescription = "";
      if (isExportingAmount && item.itemAmount > 0) {
        itemAmountDescription = `${item.itemAmount}${item.itemAmountType}`;
      }

      let total = undefined;
      if (isExportingTotal && item.price && item.itemQuantity) {
        total = (item.price * item.itemQuantity).toFixed(2);
      }

      return {
        itemName,
        itemProportions: itemProportions,
        itemAmount: itemAmountDescription,
        supplier: isExportingSupplier ? item.itemSupplier : undefined,
        itemCode: isExportingCode ? item.itemCode : undefined,
        price: isExportingPrice
          ? item.price
            ? item.price.toFixed(2)
            : undefined
          : undefined,
        quantity: item.itemQuantity,
        itemType,
        total,
      };
    });

  const handleExport = () => {
    if (exportFormat === "pdf") {
      handleExportPDF(preparedData, exportName);
    } else if (exportFormat === "sheets") {
      exportToExcel(preparedData, exportName);
    }
  };

  const handleExportPDF = () => {
    const doc = new jsPDF();
    const columns = [
      { title: "Item Name", dataKey: "itemName" },
      isExportingProportions && {
        title: "Proportions",
        dataKey: "itemProportions",
      },
      isExportingAmount && { title: "Amount", dataKey: "itemAmount" },
      isExportingSupplier && { title: "Supplier", dataKey: "supplier" },
      isExportingCode && { title: "Code", dataKey: "itemCode" },
      isExportingPrice && { title: "Price", dataKey: "price" },
      { title: "Quantity", dataKey: "quantity" },
      isExportingType && { title: "Item Type", dataKey: "itemType" },
      isExportingTotal && { title: "Total", dataKey: "total" },
    ].filter(Boolean);

    const tableData = preparedData.map((item) => ({
      itemName: item.itemName,
      itemType: item.itemType,
      supplier: item.supplier,
      itemCode: item.itemCode,
      price: item.price ? `$${item.price}` : "",
      quantity: item.quantity,
      total: item.total ? `$${item.total}` : "",
      itemProportions: item.itemProportions,
      itemAmount: item.itemAmount,
    }));

    doc.autoTable(columns, tableData, { startY: 20 });

    doc.save(`${exportName}.pdf`);
  };

  const handleExportFormatChange = (e) => {
    setExportFormat(e.target.value);
  };

  const handleExportClose = () => {
    if (isLoading) return;
    handleChange({ name: "isExportingData", value: !isExportingData });
  };

  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  //ANIMATIONS
  useLayoutEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight + 25);
    }

    const tl = gsap.timeline();

    if (visible) {
      setIsVisible(true);
      tl.set(contentRef.current, {
        height: 0,
        padding: 0,
        autoAlpha: 0,
      }).to(contentRef.current, {
        height: contentHeight,
        padding: "1rem 1rem 0 1rem",
        autoAlpha: 1,
        duration: 0.5,
      });
    } else if (contentRef.current) {
      tl.to(contentRef.current, {
        height: 0,
        padding: 0,
        autoAlpha: 0,
        duration: 0.5,
      }).then(() => setIsVisible(false));
    }

    return () => tl.kill();
  }, [visible, contentHeight]);

  if (!isVisible && !visible) return null;

  return (
    <Wrapper>
      <div ref={contentRef} className="export-container">
        <div className="export-header">
          <input
            type="text"
            placeholder={t("Enter export name")}
            value={exportName}
            onChange={(e) => setExportName(e.target.value)}
            style={{ width: "20rem" }}
          />
          <button
            className="btn delete-btn close-export"
            onClick={handleExportClose}
          >
            <FaTimes style={{ width: "1rem" }} />
          </button>
        </div>
        <div className="export-checks-container">
          {[
            {
              label: "Proportions",
              state: isExportingProportions,
              setState: setExportingProportions,
            },
            {
              label: "Amount",
              state: isExportingAmount,
              setState: setExportingAmount,
            },
            {
              label: "Supplier",
              state: isExportingSupplier,
              setState: setExportSupplier,
            },
            { label: "Code", state: isExportingCode, setState: setExportCode },
            {
              label: "Price",
              state: isExportingPrice,
              setState: setExportPrice,
            },
            { label: "Type", state: isExportingType, setState: setExportType },
            {
              label: "Total",
              state: isExportingTotal,
              setState: setExportTotal,
            },
          ].map(({ label, state, setState }) => (
            <div
              key={label}
              onClick={(e) => {
                if (e.target.type !== "checkbox") {
                  setState(!state);
                }
              }}
              className="view_container"
              style={{ cursor: "pointer" }}
            >
              <h5>{t(label)}</h5>
              <input
                type="checkbox"
                checked={state}
                onChange={() => setState(!state)}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          ))}
        </div>
        <div className="export-confirm-container">
          <p>
            {t("Export")} <span>{preparedData.length}</span> {t("items")}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormRowSelect
              //labelText="Export Format"
              //name="exportFormat"
              value={exportFormat}
              handleChange={handleExportFormatChange}
              list={["pdf", "sheets"]}
              style={{ margin: "0 0.5rem 0rem 0" }}
            />
            <button
              className="btn edit-btn export-button"
              style={{ height: "2.19rem" }}
              onClick={() => handleExport()}
            >
              {t("Export")}
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ExportData;
