import { useAppContext } from "../../context/appContext";
import { useState, useLayoutEffect, useMemo, useEffect, useRef } from "react";
import Wrapper from "../../assets/wrappers/Orders";
import { InvoiceItemDetail } from "../";
import ItemCompare from "./ItemCompare";

import { FaTimes } from "react-icons/fa";
import { BsQuestionCircle } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import { gsap } from "gsap";

const OrderCompare = () => {
  const {
    isScanningOrderInvoice,
    scanningOrderId,
    uploadOcrImage,
    toggleScanOrderInvoice,
    isScanningLoading,
    invoiceItems,
    handleChange,
    orders,
    compareInvoiceToOrderItems,
    items,
    linkedItems,
    getItems,
    providers,
    isComparingLoading,
    updateItemPrices,
    getOrders,
  } = useAppContext();
  const [file, setFile] = useState(null);
  const [isFileSelected, setFileSelected] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  const [noChangesItems, setNoChangesItems] = useState([]);
  const [newPriceItems, setNewPriceItems] = useState([]);

  useEffect(() => {
    if (!orders) {
      getOrders();
    }
    if (!items) {
      getItems();
    } else {
      const processedNoChanges = [];
      const processedNewPrices = [];

      linkedItems.forEach((linkedItem) => {
        const invoicePriceStr = linkedItem.unit_price.toString();
        const orderItem = items.find((o) => o._id === linkedItem.order_item_id);
        const provider = providers.find(
          (provider) => provider._id === orderItem.provider
        );
        if (orderItem) {
          const linkedItemPrice = parseFloat(
            invoicePriceStr.replace(/[^0-9.]/g, "")
          );
          const orderItemPrice = parseFloat(orderItem.price.toString());

          if (linkedItemPrice === orderItemPrice) {
            processedNoChanges.push({
              ...linkedItem,
              originalPrice: orderItem.price,
            });
          } else {
            processedNewPrices.push({
              ...linkedItem,
              provider: provider.providerName,
              originalPrice: orderItem.price,
              newPrice: linkedItemPrice,
              isSelected: true,
            });
          }
        } else {
          console.log(
            "No matching order item found for ID:",
            linkedItem.order_item_id
          );
        }
      });

      //console.log("No Changes Items:", processedNoChanges);
      //console.log("New Price Items:", processedNewPrices);

      setNoChangesItems(processedNoChanges);
      setNewPriceItems(processedNewPrices);
    }
  }, [linkedItems, items]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      setFileSelected(true);
    } else {
      setFileSelected(false);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      console.log("Please select a file first!");
      return;
    }
    const formData = new FormData();
    formData.append("invoice", file);
    uploadOcrImage(formData);
  };
  const handleScanClose = () => {
    handleChange({ name: "isScanningOrderInvoice", value: false });
    handleChange({ name: "scanningOrderId", value: "" });
  };

  const order = orders.find((order) => order._id === scanningOrderId);
  const orderName = order?.orderName;
  const itemsLength = order?.items.length;

  const transformOrderItems = (orderItems) => {
    return orderItems.map((item) => ({
      order_item_id: item._id,
      order_item_name: item.itemName,
      quantity: `${item.itemQuantity} ${item.itemType}`,
      format: `${item.itemProportions}x${item.itemProportionsAmount}${item.itemProportionsAmountType}`,
      //unit_price: item.price.toFixed(2),
    }));
  };

  const handleOrderCompare = () => {
    if (!order) {
      console.log("Order not found");
      return;
    }
    const orderItemsFormatted = transformOrderItems(order.items);
    //console.log("Comparing", invoiceItems, orderItemsFormatted);
    compareInvoiceToOrderItems(invoiceItems, orderItemsFormatted);
  };

  const toggleSelectedItem = (itemId, isSelected) => {
    const updatedItems = newPriceItems.map((item) => {
      if (item.order_item_id === itemId) {
        return { ...item, isSelected };
      }
      return item;
    });
    setNewPriceItems(updatedItems);
  };

  const updateItemPrice = (itemId, newPrice) => {
    const updatedItems = newPriceItems.map((item) => {
      if (item.order_item_id === itemId) {
        const updatedItem = { ...item, unit_price: newPrice };

        updatedItem.isSelected =
          parseFloat(newPrice) !== parseFloat(item.originalPrice);

        return updatedItem;
      }
      return item;
    });
    setNewPriceItems(updatedItems);
  };

  const handlePriceUpdate = () => {
    const itemsToUpdate = newPriceItems
      .filter((item) => item.isSelected)
      .map((item) => ({
        order_item_id: item.order_item_id,
        unit_price: item.unit_price,
      }));
    updateItemPrices(itemsToUpdate);
  };

  const unmatchedItemCount = invoiceItems.length - linkedItems.length;
  const selectedItemCount = newPriceItems.filter(
    (item) => item.isSelected
  ).length;

  //ANIMATIONS
  // useLayoutEffect(() => {
  //   if (contentRef.current) {
  //     setContentHeight(contentRef.current.scrollHeight + 50);
  //   }

  //   const tl = gsap.timeline();

  //   if (isScanningOrderInvoice) {
  //     setIsVisible(true);
  //     tl.set(contentRef.current, {
  //       height: 0,
  //       padding: 0,
  //       autoAlpha: 0,
  //     }).to(contentRef.current, {
  //       height: contentHeight,
  //       padding: "1rem 1rem 1rem 1rem",
  //       autoAlpha: 1,
  //       duration: 0.5,
  //     });
  //   } else if (contentRef.current) {
  //     tl.to(contentRef.current, {
  //       height: 0,
  //       padding: 0,
  //       autoAlpha: 0,
  //       duration: 0.5,
  //     }).then(() => setIsVisible(false));
  //   }

  //   return () => tl.kill();
  // }, [isScanningOrderInvoice, isScanningLoading, contentHeight, linkedItems]);

  if (!isVisible && !isScanningOrderInvoice) {
    return null;
  }
  return (
    <Wrapper>
      <div
        style={{ padding: "0.5rem" }}
        ref={contentRef}
        className={"send-orders-container"}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "0.5rem",
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <span className="beta-tag">BETA</span>
            <h5>Scan invoice for Order:</h5>
            <h4>
              {" "}
              {orderName} - {itemsLength} items
            </h4>
            <Tippy content="This feature allows to scan a food invoice, retreive its related items and compare them to your local items. You can then update your local items with the info from the invoice items">
              <div>
                <BsQuestionCircle
                  style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }}
                  className="info-icon"
                />
              </div>
            </Tippy>
          </div>
          <button
            className="btn delete-btn close-send-orders"
            onClick={handleScanClose}
          >
            <FaTimes style={{ width: "1rem" }} />
          </button>
        </div>
        <div className="file-upload-container">
          <input type="file" onChange={handleFileChange} accept="image/*" />
          <button
            className="btn"
            onClick={handleUpload}
            disabled={isScanningLoading || !file}
          >
            Upload Invoice
          </button>
          <Tippy content="Make sure that the image is centered horizontally, that the text is properly contrasted with the background and can capture the full invoice">
            <div>
              <BsQuestionCircle
                style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }}
                className="info-icon"
              />
            </div>
          </Tippy>
        </div>
        <div style={{ marginTop: "0.5rem" }}>
          {isScanningLoading && (
            <div style={{ display: "flex", marginTop: "0.5rem" }}>
              <h5>Scanning Invoice...</h5>
              <div className="invoice-loading"></div>
            </div>
          )}
          {invoiceItems.length > 0 && (
            <h5 style={{ marginBottom: "0.25rem" }}>
              Detected {invoiceItems.length} invoice item
              {invoiceItems.length > 1 ? "s" : ""}:
            </h5>
          )}

          {invoiceItems.map((item, index) => (
            <InvoiceItemDetail key={index} item={item} />
          ))}
        </div>
        {invoiceItems.length > 0 && (
          <div style={{ display: "flex" }}>
            <button
              className="btn"
              onClick={handleOrderCompare}
              disabled={isComparingLoading}
            >
              Compare Items
            </button>
            <Tippy content="This will make the AI compare invoice items to order items and associate them. The more alike the itemNames are for both orderItems and invoiceItems, the easier it will be able to associate them.">
              <div>
                <BsQuestionCircle
                  style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }}
                  className="info-icon"
                />
              </div>
            </Tippy>
          </div>
        )}
        {isComparingLoading && (
          <div style={{ display: "flex", marginTop: "0.5rem" }}>
            <h5>Comparing Items...</h5>
            <div className="invoice-loading"></div>
          </div>
        )}
        {linkedItems.length > 0 && (
          <div>
            <h5>
              <span style={{ color: "var(--green-light)" }}>
                {noChangesItems.length} Item
                {noChangesItems.length === 1 ? "" : "s"} with no Change in
                Prices
              </span>{" "}
              {unmatchedItemCount > 0 && (
                <span style={{ color: "var(--red-light)" }}>
                  {" "}
                  - {unmatchedItemCount} Item
                  {unmatchedItemCount === 1 ? "" : "s"} could not be associated
                </span>
              )}
            </h5>
            <h5>
              {newPriceItems.length} Item{newPriceItems.length === 1 ? "" : "s"}{" "}
              with New Prices
            </h5>
            {newPriceItems.map((item, index) => (
              <ItemCompare
                key={index}
                item={item}
                toggleSelectedItem={toggleSelectedItem}
                updateItemPrice={updateItemPrice}
              />
            ))}
            <button className="btn" onClick={handlePriceUpdate}>
              update prices for {selectedItemCount} Item
              {selectedItemCount === 1 ? "" : "s"}
            </button>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default OrderCompare;
