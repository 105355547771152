import React, {
  useState,
  useEffect,
  useMemo,
  useLayoutEffect,
  useRef,
} from "react";
import { useAppContext } from "../context/appContext";
import Wrapper from "../assets/wrappers/ResetQuantities";
import { gsap } from "gsap";
import { useTranslation } from "react-i18next";

const AutoOrder = ({ filteredItems }) => {
  const { t } = useTranslation();
  const {
    isAutoOrdering,
    autoOrderItemQuantities,
    updateItemQuantity,
    toggleAutoOrder,
  } = useAppContext();
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  const { typeMismatchItems, orderItems, noUpdateNeededItems } = useMemo(() => {
    const typeMismatchItems = [];
    const orderItems = [];
    const noUpdateNeededItems = [];

    filteredItems.forEach((item) => {
      const {
        _id,
        itemName,
        itemQuantity,
        itemNeededType,
        itemLeftToOrderType,
        selectedInventoryType,
        itemType,
        itemProportionsType,
        itemProportions,
        itemNeeded,
        itemLeftToOrder,
        itemInventoryQuantity,
      } = item;

      if (
        ![itemType, itemProportionsType].includes(itemNeededType) ||
        (itemLeftToOrder &&
          ![itemType, itemProportionsType].includes(itemLeftToOrderType))
      ) {
        typeMismatchItems.push({ itemName, reason: "Mismatched Types inputs" });
        return;
      }

      const proportion = parseInt(itemProportions, 10);
      const proportionInverse = 1 / proportion;
      const getProportionalValue = (type, amount) =>
        parseFloat(amount) *
        (type === itemProportionsType ? proportionInverse : 1);

      const neededValue = getProportionalValue(itemNeededType, itemNeeded);
      const leftToOrderValue = itemLeftToOrder
        ? getProportionalValue(itemLeftToOrderType, itemLeftToOrder)
        : Infinity;
      const inventoryQuantityValue = getProportionalValue(
        selectedInventoryType,
        itemInventoryQuantity
      );

      if (
        inventoryQuantityValue < neededValue &&
        (itemLeftToOrder === null || inventoryQuantityValue < leftToOrderValue)
      ) {
        const orderQuantity = Math.ceil(neededValue - inventoryQuantityValue);
        if (itemQuantity === orderQuantity) {
          noUpdateNeededItems.push({ _id, itemName, orderQuantity });
        } else {
          orderItems.push({ _id, itemName, orderQuantity });
        }
      }
    });

    return { typeMismatchItems, orderItems, noUpdateNeededItems };
  }, [filteredItems]);

  const handleAutoOrderItemQuantities = () => {
    if (orderItems.length > 0) {
      orderItems.forEach((item) => {
        updateItemQuantity(item, item.orderQuantity);
      });
      toggleAutoOrder();
    }
  };

  //ANIMATIONS
  useLayoutEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight + 25);
    }

    const tl = gsap.timeline();

    if (isAutoOrdering) {
      setIsVisible(true);
      tl.set(contentRef.current, {
        height: 0,
        padding: 0,
        autoAlpha: 0,
      }).to(contentRef.current, {
        height: contentHeight,
        padding: "1rem 1rem 1rem 1rem",
        autoAlpha: 1,
        duration: 0.5,
      });
    } else if (contentRef.current) {
      tl.to(contentRef.current, {
        height: 0,
        padding: 0,
        autoAlpha: 0,
        duration: 0.5,
      }).then(() => setIsVisible(false));
    }

    return () => tl.kill();
  }, [isAutoOrdering, contentHeight]);

  if (!isVisible && !isAutoOrdering) {
    return null;
  }

  return (
    <Wrapper>
      <div ref={contentRef} className="auto-order-container">
        <h4>{t("Auto Order Quantities")}</h4>
        {/* <p>
          {sufficientQuantityItems.length} Items already have sufficient stock
        </p> */}
        <p>{t("Valid auto-order items", { count: orderItems.length })}</p>
        <p style={{ color: "#DC143C" }}>
          {t("Invalid auto-order items", { count: typeMismatchItems.length })}
        </p>
        <p style={{ color: "var(--green-light)" }}>
          {t("No update needed items", { count: noUpdateNeededItems.length })}
        </p>
        <button
          className="btn btn-green"
          onClick={handleAutoOrderItemQuantities}
        >
          {t("Auto-Order")}
        </button>
      </div>
    </Wrapper>
  );
};

export default AutoOrder;
