import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
ChartJS.register(ArcElement, Tooltip, Legend);

const BackorderedChart = ({ data }) => {
  const { t } = useTranslation();
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: t("BackorderedAndMissing"),
      },
    },
  };

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "400px",
        margin: "0 auto",
        height: "auto",
      }}
    >
      <Pie data={data} options={options} />
    </div>
  );
};

export default BackorderedChart;
