import styled from "styled-components";

const Wrapper = styled.aside`
  margin-top: 0.5rem;

  border-radius: 10px;

  background: var(--white);
  box-shadow: var(--shadow-2);

  overflow: hidden;

  h5 {
    margin-bottom: 0;
  }

  .export-container {
    display: flex;
    flex-direction: column;

    padding: 1rem 1rem 0 1rem;
  }
  .export-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  .export-checks-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0.25rem;
  }
  .view_container {
    display: flex;

    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    cursor: pointer;
  }
  .view_container input {
    margin-left: 0.25rem;
    margin-top: 0.2rem;
  }

  .export-confirm-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .export-confirm-container p {
    margin: 0;
    line-height: 1rem;
  }
  .export-confirm-container span {
    font-weight: 700;
  }
  .export-button {
    // margin: 0.25rem 0.25rem 0.25rem auto;
  }
  .close-export {
    width: 2rem;
    height: 2rem;
    border-radius: 8px;
    padding: 0.5rem;

    color: var(--white);
    background: var(--red-light);
    @media (min-width: 576px) {
      margin-left: 0.75rem;
    }
    :hover {
      background: var(--red-dark);
    }
  }
  .edit-btn {
    color: var(--white);
    background: var(--green-light);
    :hover {
      background: var(--green-dark);
    }
  }
`;
export default Wrapper;
