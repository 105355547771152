import styled from "styled-components";

const Wrapper = styled.aside`
  .alternative-container {
    display: flex;
    justify-content: space-between;
  }

  .alternative-close {
    width: 2rem;
    height: 2rem;
    border-radius: 10px;
    padding: 0.5rem;

    color: var(--white);
    background: var(--red-light);
    @media (min-width: 576px) {
      margin-left: 0.75rem;
    }
    :hover {
      background: var(--red-dark);
    }
  }
`;
export default Wrapper;
