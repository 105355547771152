import {
  OrdersContainer,
  ExportData,
  SendOrders,
  OrderCompare,
} from "../../components";

const Orders = () => {
  return (
    <div>
      <SendOrders />
      <OrderCompare />
      <ExportData />
      <OrdersContainer />
    </div>
  );
};

export default Orders;
