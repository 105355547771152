import { FormRow } from "..";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/AddItemLocation";
import { useTranslation } from "react-i18next";

const AddItemCategory = () => {
  const {
    isLoading,
    isAddingCategory,
    isEditingCategory,
    displayAlert,
    categoryName,
    handleChange,
    createItemCategory,
    editItemCategory,
    editItemCategoryId,
    deleteItemCategory,
  } = useAppContext();
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!categoryName) {
      displayAlert();
      return;
    }
    if (isEditingCategory) {
      editItemCategory();
      return;
    }
    createItemCategory();
  };

  const handleItemInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({ name, value });
  };

  return (
    <Wrapper>
      <form className={isAddingCategory ? "form_active" : "form"}>
        <div className="form-center">
          <FormRow
            type="text"
            name="categoryName"
            value={categoryName}
            handleChange={handleItemInput}
            labelText={t("Category Name")}
          />
          <div className="btn-container">
            <button
              type="submit"
              className="btn btn-block submit-btn"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {t("Submit")}
            </button>
            {isEditingCategory && (
              <button
                type="button"
                className="btn delete-btn"
                onClick={() => deleteItemCategory(editItemCategoryId)}
              >
                {t("Delete")}
              </button>
            )}
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

export default AddItemCategory;
