import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/Provider";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const FilteredProvider = ({ provider }) => {
  const { t } = useTranslation();
  const {
    isEditingProvider,
    clearValues,
    setEditProvider,
    deleteProvider,
    deliveryDaysOptions,
  } = useAppContext();

  return (
    <Wrapper style={{ marginTop: ".75rem" }}>
      <div>
        <div className="provider-title">
          <h2>
            {provider.providerName}
            {provider.website && (
              <a href={provider.website} target="_blank">
                <FaExternalLinkAlt
                  style={{ width: "1rem", marginLeft: "0.5rem" }}
                />
              </a>
            )}
          </h2>
          <div
            style={{
              marginLeft: "0.25rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <h5>
              {t("Login")}: {provider.providerId}
            </h5>
            <h5>
              {t("Password")}: {provider.passwordInfo}
            </h5>
          </div>
        </div>
        <div className="provider-content">
          <div className="provider-info">
            <h4>{provider.number}</h4>
            <div className="compact-day-selector">
              {deliveryDaysOptions.map((day) => (
                <span
                  key={day}
                  className={`compact-day ${
                    provider.deliveryDay.includes(day) ? "selected" : ""
                  }`}
                >
                  {t(day)}
                </span>
              ))}
            </div>
            <h5 style={{ color: "var(--primary-600)", whiteSpace: "nowrap" }}>
              {provider.deliveryTime && `:${provider.deliveryTime}`}
            </h5>
            <p>{provider.notes}</p>
            {provider.holiday && (
              <h5>
                {t("Closed on")}: {provider.holiday}
              </h5>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default FilteredProvider;
