import Wrapper from "../../assets/wrappers/Reports";
import { useState } from "react";

const ReportPreview = ({ report }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Wrapper>
      <div
        className={`report-card ${isExpanded ? "expanded" : ""}`}
        onClick={toggleExpand}
      >
        <div className="report-card-header">
          <h4>{report.summary.dayClose}</h4>
          <p>{report.summary.date}</p>
          <p>{report.summary.time}</p>
          <p>{report.summary["Items Sales"]}</p>
        </div>

        {isExpanded && (
          <div className="report-card-details">
            <div className="report-card-categories">
              {Object.entries(report.categories).map(
                ([categoryName, items], idx) => (
                  <div key={idx} className="report-category">
                    <h5>{categoryName}</h5>
                    <ul>
                      {items.map((item, itemIdx) => (
                        <li key={itemIdx}>
                          {item.quantity} x {item.name} - {item.total}
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default ReportPreview;
