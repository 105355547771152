import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/MenuItem";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const MenuItem = ({ menuItem }) => {
  const { t } = useTranslation();
  const {
    isEditingMenuItem,
    setEditMenuItem,
    deleteMenuItem,
    menuItems,
    items,
  } = useAppContext();

  const [isOpen, setIsOpen] = useState(false);

  //Finds ingredient names based on items object
  const getItemNameFromId = (itemId) => {
    const foundItem = items.find((item) => item._id === itemId);
    return foundItem ? foundItem.itemName : "";
  };

  const getItemForIngredient = (ingredientId) => {
    const item = items.find((item) => item._id === ingredientId);
    return item;
  };

  const convertToBaseUnit = (quantity, type) => {
    switch (type) {
      case "L":
        return quantity * 1000;
      case "ml":
        return quantity;
      case "Kg":
        return quantity * 1000;
      case "g":
        return quantity;
      case "Oz":
        return quantity * 28.35;
      case "lb":
        return quantity * 453.592;
      case "unit":
        return quantity;
      default:
        return quantity;
    }
  };

  const computeIngredientCost = (ingredient, item) => {
    if (!item) return 0;

    const ingredientBaseQuantity = convertToBaseUnit(
      ingredient.ingredientQuantity,
      ingredient.ingredientQType
    );
    const itemBaseAmount = convertToBaseUnit(
      item.itemAmount,
      item.itemAmountType
    );

    const factor = ingredientBaseQuantity / itemBaseAmount;

    return item.price * factor;
  };

  //Total cost for each ingredients together
  const totalCost = menuItem.menuItemIngredients.reduce((acc, ingredient) => {
    const correspondingItem = getItemForIngredient(ingredient.item);
    const cost = computeIngredientCost(ingredient, correspondingItem);

    // Apply waste percentage
    const wasteFactor = 1 + ingredient.wastePercentage / 100;
    const costWithWaste = cost * wasteFactor;

    return acc + (costWithWaste ? costWithWaste : 0);
  }, 0);
  const getPluralForm = (count, single, plural) => {
    return count === 1 ? single : plural;
  };
  const dishCost =
    menuItem.menuItemOrders > 0
      ? parseFloat((totalCost / menuItem.menuItemOrders).toFixed(2))
      : 0.0;

  // Apply error percentage to final price
  const errorFactor = 1 + menuItem.menuItemError / 100;
  const finalDishCost = (dishCost * errorFactor).toFixed(2);
  const markup = (menuItem.menuItemPrice / finalDishCost).toFixed(2);

  const finalDishCostColor = markup >= 3 ? "green" : "red";
  return (
    <Wrapper>
      <div onClick={() => setIsOpen(!isOpen)}>
        <div className="menu-item-title">
          <div className="menu-item-text">
            {/* {menuItem.menuItemImage && (
            <img
              src={menuItem.menuItemImage}
              alt={menuItem.menuItemName}
              className="menu-item-image"
            />
          )} */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <h2>{menuItem.menuItemName}</h2>
              <div className="actions">
                {/* <h5>for {menuItem.menuItemOrders} orders</h5> */}
                <button
                  type="button"
                  className="btn edit-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditMenuItem(menuItem._id);
                  }}
                >
                  {t("Edit")}
                </button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <h3>{menuItem.menuItemPrice}$</h3>
              <h5 style={{ color: finalDishCostColor }}>
                {finalDishCost}$ ({markup}x)
              </h5>
              <h6>
                {menuItem.menuItemError}% {t("Error")}
              </h6>

              <h4>
                {t("for")} {menuItem.menuItemOrders}{" "}
                {t(getPluralForm(menuItem.menuItemOrders, "Order", "Orders"))}
              </h4>
              <h4>{menuItem.menuItemNotes}</h4>
            </div>
          </div>
        </div>
        {isOpen && menuItem.menuItemIngredients.length > 0 && (
          <div className="menu-item-content">
            <div className="ingredient-details">
              {menuItem.menuItemIngredients.map((ingredient, idx) => {
                const correspondingItem = getItemForIngredient(ingredient.item);
                if (!correspondingItem) return null;
                const cost = computeIngredientCost(
                  ingredient,
                  correspondingItem
                );
                const wasteFactor = 1 + ingredient.wastePercentage / 100;
                const costWithWaste = cost * wasteFactor;
                return (
                  <div key={idx} className="ingredient">
                    <h4 className="ingredient-name">
                      {getItemNameFromId(ingredient.item) || "N/A"}
                    </h4>
                    <h4 className="ingredient-quantity">
                      {ingredient.ingredientQuantity}{" "}
                      {ingredient.ingredientQType}
                    </h4>
                    <h4 className="ingredient-cost">
                      {t("Cost")}:{" "}
                      {isNaN(costWithWaste) || !isFinite(costWithWaste)
                        ? "N/A"
                        : costWithWaste.toFixed(2)}
                      $
                    </h4>
                    <h4 className="ingredient-waste">
                      {t("Waste")}: {ingredient.wastePercentage}%
                    </h4>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default MenuItem;
