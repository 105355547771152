import Wrapper from "../../assets/wrappers/AddItemContainer";
import { useAppContext } from "../../context/appContext";
import { useTranslation } from "react-i18next";

const AddMenuContainer = () => {
  const { t } = useTranslation();
  const { isEditingMenu, clearValues, isAddingMenu, setAddMenu } =
    useAppContext();

  return (
    <Wrapper>
      <button
        type="button"
        style={{ maxWidth: "10rem", marginLeft: "0.25rem", marginTop: "1rem" }}
        className={isEditingMenu || isAddingMenu ? `btn btn-danger` : `btn`}
        onClick={!isAddingMenu ? () => setAddMenu() : () => clearValues()}
      >
        {isEditingMenu || isAddingMenu ? t("Close") : t("Create Menu")}
      </button>
    </Wrapper>
  );
};
export default AddMenuContainer;
