import { FormRow, FormRowSelect } from "..";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/AddItem";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const AddMenuItem = () => {
  const { t } = useTranslation();
  const {
    isLoading,
    isAddingMenuItem,
    isEditingMenuItem,
    editMenuItemId,
    displayAlert,
    menuItemName,
    menuItemPrice,
    menuItemNotes,
    menuItemOrders,
    menuItemError,
    menuItemImage,
    items,
    alternativePairs,
    providers,
    menuItemIngredients,
    addIngredientField,
    removeIngredientField,
    updateIngredientField,
    searchMenuIngredients,
    searchIngredients,
    menuIngredients,
    itemAmountTypeOptions,
    handleChange,
    clearValues,
    createMenuItem,
    editMenuItem,
    deleteMenuItem,
  } = useAppContext();
  const { id: menuId } = useParams();
  const [currentDisplayValues, setCurrentDisplayValues] = useState([]);
  const [activeIngredientIndex, setActiveIngredientIndex] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!menuItemName) {
      displayAlert();
      return;
    }
    if (isEditingMenuItem) {
      editMenuItem(menuId);
      return;
    }
    createMenuItem(menuId);
  };

  const defaultIngredientAmountTypeOptions = ["ml", "L", "g", "Kg", "Oz", "lb"];

  const convertToBaseUnit = (quantity, type) => {
    switch (type) {
      case "L":
        return quantity * 1000;
      case "ml":
        return quantity;
      case "Kg":
        return quantity * 1000;
      case "g":
        return quantity;
      case "Oz":
        return quantity * 28.35;
      case "lb":
        return quantity * 453.592;
      case "unit":
        return quantity;
      default:
        return quantity;
    }
  };

  const calculateIngredientPrice = (ingredient) => {
    const item = items.find((item) => item._id === ingredient.item);

    if (!item) {
      return "Select Item";
    }

    if (item.itemAmountType === "Other") {
      return "Cannot calculate price for 'Other' type";
    }

    if (item.itemAmount === 0) {
      return "Set an itemAmount First";
    }

    if (item.price === 0) {
      return "No item price";
    }

    const ingredientBaseQuantity = convertToBaseUnit(
      ingredient.ingredientQuantity,
      ingredient.ingredientQType
    );
    const itemBaseAmount = convertToBaseUnit(
      item.itemAmount,
      item.itemAmountType
    );

    if (
      ingredientBaseQuantity === undefined ||
      itemBaseAmount === undefined ||
      itemBaseAmount === 0
    ) {
      return `Error: Ingredient or item amount type is '${ingredient.ingredientQType}' or '${item.itemAmountType}'`;
    }

    const baseCost = (ingredientBaseQuantity / itemBaseAmount) * item.price;
    const wasteFactor = 1 + ingredient.wastePercentage / 100;
    const costWithWaste = baseCost * wasteFactor;

    return costWithWaste.toFixed(2) + "$";
  };

  const handleMenuItemInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({ name, value });
  };

  const getItemNameFromId = (itemId) => {
    const foundItem = items.find((item) => item._id === itemId);
    return foundItem ? foundItem.itemName : "";
  };

  const isItemValid = (inputValue) => {
    return items.some((item) => item.itemName === inputValue);
  };

  const isBaseItemTypeLocked = (ingredientId) => {
    const item = items.find((item) => item._id === ingredientId);
    return item?.itemAmountType === "unit" || item?.itemAmountType === "Other";
  };

  const areAllIngredientsValid = () => {
    return menuItemIngredients.every((ingredient) =>
      items.some((item) => item._id === ingredient.item)
    );
  };

  const handleSearchChange = (e) => {
    handleChange({ name: "searchIngredients", value: e.target.value });
  };

  const handleIngredientChange = (index, field, value) => {
    const ingredient = menuItemIngredients[index];
    const item = items.find((item) => item._id === ingredient.item);
    let updatedField = { [field]: value };

    if (field === "ingredientQType" && item && item.itemAmountType === "unit") {
      updatedField = { [field]: "unit" };
    }

    updateIngredientField(index, updatedField);
  };

  const handleSelectIngredient = (menuIngId, index) => {
    const newIngredient = items.find((ing) => ing._id === menuIngId);
    if (newIngredient) {
      const updatedField = {
        item: menuIngId,
        name: newIngredient.itemName,
        ingredientQType:
          newIngredient.itemAmountType === "unit"
            ? "unit"
            : newIngredient.itemAmountType === "Other"
            ? "Other"
            : "ml",
      };
      updateIngredientField(index, updatedField);

      const updatedDisplayValues = [...currentDisplayValues];
      updatedDisplayValues[index] = newIngredient.itemName;
      setCurrentDisplayValues(updatedDisplayValues);
      setActiveIngredientIndex(null);
    }
  };

  useEffect(() => {
    const displayValues = menuItemIngredients.map((ing) =>
      getItemNameFromId(ing.item)
    );
    setCurrentDisplayValues(displayValues);
  }, [menuItemIngredients]);

  //Calls the function to fetch our items based on query
  useEffect(() => {
    const delayForTyping = setTimeout(() => {
      searchMenuIngredients();
    }, 800);

    return () => clearTimeout(delayForTyping);
  }, [searchIngredients]);

  return (
    <Wrapper style={{ overflow: "visible" }}>
      <form className={isAddingMenuItem ? "form_active" : "form"}>
        <h3>{isEditingMenuItem ? t("edit menuItem") : t("add menuItem")}</h3>
        <div className="form-center">
          <FormRow
            type="text"
            labelText={t("Name")}
            name="menuItemName"
            value={menuItemName}
            handleChange={handleMenuItemInput}
          />
          <FormRow
            type="number"
            labelText={t("Dish cost")}
            name="menuItemPrice"
            value={menuItemPrice}
            handleChange={handleMenuItemInput}
          />

          <FormRow
            type="number"
            labelText={t("Number of Orders")}
            name="menuItemOrders"
            value={menuItemOrders}
            handleChange={handleMenuItemInput}
          />
          <FormRow
            type="number"
            labelText={t("Error Percentage")}
            name="menuItemError"
            value={menuItemError}
            handleChange={handleMenuItemInput}
          />
          <FormRow
            type="text"
            labelText={t("Instructions")}
            name="menuItemNotes"
            value={menuItemNotes}
            handleChange={handleMenuItemInput}
          />
          {/* <FormRow
            type="text"
            labelText="Image Link"
            name="menuItemImage"
            value={menuItemImage}
            handleChange={handleMenuItemInput}
          /> */}
        </div>
        <h3 style={{ marginTop: "1rem" }}>{t("ingredients")}</h3>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          {menuItemIngredients.map((ingredient, index) => {
            const selectedItem = items.find(
              (item) => item._id === ingredient.item
            );
            const isOtherType =
              selectedItem && selectedItem.itemAmountType === "Other";
            const ingredientAmountTypeOptions = isOtherType
              ? ["Other"]
              : selectedItem && selectedItem.itemAmountType === "unit"
              ? ["unit"]
              : defaultIngredientAmountTypeOptions;

            return (
              <div key={index} className="ingredient-row">
                <div className="search-container">
                  <FormRow
                    type="text"
                    name={`ingredient ${index + 1}`}
                    labelText={t("Ingredient") + ` ${index + 1}`}
                    value={ingredient.name || ""}
                    className={
                      isItemValid(currentDisplayValues[index])
                        ? "valid"
                        : "invalid"
                    }
                    handleChange={(e) => {
                      updateIngredientField(index, { name: e.target.value });
                      setActiveIngredientIndex(index);
                      handleSearchChange(e);
                    }}
                  />

                  {activeIngredientIndex === index &&
                    menuIngredients &&
                    menuIngredients.length > 0 && (
                      <div className="menuIngredients-list">
                        {menuIngredients.slice(0, 5).map((menuIng) => {
                          const selectedProvider = providers.find(
                            (provider) => provider._id === menuIng.provider
                          );

                          const providerName = selectedProvider
                            ? selectedProvider.providerName
                            : "";

                          const isAlternative = alternativePairs.some(
                            (pair) => pair.alternative._id === menuIng._id
                          );

                          const isMainItem = alternativePairs.some(
                            (pair) => pair.mainItem._id === menuIng._id
                          );
                          const itemClass = isAlternative
                            ? "invalid"
                            : isMainItem
                            ? "valid"
                            : "";

                          return (
                            <div
                              key={menuIng._id}
                              className={`search-item ${itemClass}`}
                              onClick={() =>
                                handleSelectIngredient(menuIng._id, index)
                              }
                            >
                              <span>{menuIng.itemName}</span>
                              {menuIng.itemSupplier && (
                                <span
                                  style={{
                                    color: "var(--primary-400)",
                                    marginLeft: "8px",
                                  }}
                                >
                                  {menuIng.itemSupplier}
                                </span>
                              )}
                              {providerName && (
                                <span
                                  className="provider"
                                  style={{ marginLeft: "8px" }}
                                >
                                  {providerName}
                                </span>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                </div>
                {selectedItem && (
                  <div className="item-details">
                    <p>
                      {selectedItem.itemAmount}
                      {selectedItem.itemAmountType} - {selectedItem.price}$
                    </p>
                  </div>
                )}
                <div className="ingredient-quantity-row">
                  <FormRow
                    type="number"
                    name={`Quantity`}
                    labelText={t("Quantity")}
                    value={ingredient.ingredientQuantity}
                    handleChange={(e) =>
                      handleIngredientChange(
                        index,
                        "ingredientQuantity",
                        e.target.value
                      )
                    }
                    className="ingredient-formRow"
                    disabled={isOtherType}
                  />
                  <FormRowSelect
                    labelText={t("Quantity Type")}
                    name={`QuantityType`}
                    value={ingredient.ingredientQType}
                    handleChange={(e) =>
                      handleIngredientChange(
                        index,
                        "ingredientQType",
                        e.target.value
                      )
                    }
                    list={ingredientAmountTypeOptions}
                    className="ingredient-formRow"
                    disabled={
                      isBaseItemTypeLocked(ingredient.item) || isOtherType
                    }
                  />
                  <FormRow
                    type="number"
                    labelText={t("Waste %")}
                    name={`wastePercentage${index}`}
                    value={ingredient.wastePercentage || 0}
                    handleChange={(e) =>
                      handleIngredientChange(
                        index,
                        "wastePercentage",
                        e.target.value
                      )
                    }
                    className="ingredient-formRow"
                    disabled={isOtherType}
                  />
                  <h5
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: "600",
                      marginLeft: "0.25rem",
                      marginBottom: "0.25rem",
                      width: "auto",
                      maxWidth: "12rem",
                      alignSelf: "end",
                    }}
                  >
                    {calculateIngredientPrice(ingredient)}
                  </h5>
                </div>
                <button
                  type="button"
                  className="btn delete-btn"
                  onClick={() => removeIngredientField(index)}
                  style={{
                    width: "2rem",
                    paddingLeft: "0.5rem",
                    marginTop: "2rem",
                  }}
                >
                  <FaTimes style={{ width: "1rem" }} />
                </button>
              </div>
            );
          })}
        </div>
        <button
          type="button"
          className="btn edit-btn"
          onClick={addIngredientField}
          style={{ marginTop: "1rem" }}
        >
          {t("Add")}
        </button>
        <div
          className="btn-container"
          style={{
            marginTop: "1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            className="btn btn-block delete-btn"
            onClick={() => deleteMenuItem(editMenuItemId, menuId)}
          >
            {t("Delete")}
          </button>
          <button
            type="submit"
            className="btn btn-block submit-btn"
            onClick={handleSubmit}
            disabled={isLoading || !areAllIngredientsValid()}
          >
            {t("submit")}
          </button>
          {/* <button
            className="btn btn-block clear-btn"
            onClick={(e) => {
              e.preventDefault();
              clearValues();
            }}
          >
            clear
          </button> */}
        </div>
      </form>
    </Wrapper>
  );
};

export default AddMenuItem;
