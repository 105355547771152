import styled from "styled-components";

const Wrapper = styled.article`
  .item-location {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    border: 2px solid var(--primary-500);
    border-radius: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (min-width: 768px) {
      border: 3px solid var(--primary-500);
    }
  }
  .item-location-button {
    border: none;
    background-color: var(--primary-grey);
    padding-top: 0.25rem;
  }
  .item-location-button:hover {
    cursor: pointer;
  }
`;

export default Wrapper;
