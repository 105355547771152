import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FormRow,
  AddItemLocation,
  AddItemLocationContainer,
  LocationContainer,
  AddItemCategory,
  AddItemCategoryContainer,
  CategoryContainer,
  AddItemType,
  AddItemTypeContainer,
  TypeContainer,
  AddItemKitchen,
  AddItemKitchenContainer,
  KitchenContainer,
  FormRowSelect,
  //GoogleLoginButton,
} from "../../components";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/Profile";

const Profile = () => {
  const {
    user,
    planId,
    subscription,
    displayAlert,
    updateUser,
    isLoading,
    getItemLocations,
    getItemCategories,
    getItemTypes,
    getItemKitchens,
    createCheckoutSession,
    accessStripePortal,
    verifyStripeSession,
    checkSubscription,
  } = useAppContext();
  const { t, i18n } = useTranslation();
  const [name, setName] = useState(user?.name);
  const [email, setEmail] = useState(user?.email);
  const [lastName, setLastName] = useState(user?.lastName);
  const [language, setLanguage] = useState(user?.language);

  const [selectedPlan, setSelectedPlan] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const sessionId = query.get("session_id");
  const subscriptionUpdated = query.get("subscription_updated");

  useEffect(() => {
    if (sessionId) {
      verifyStripeSession(sessionId);
      navigate("/profile");
    }
  }, [sessionId]);

  useEffect(() => {
    if (subscriptionUpdated) {
      checkSubscription();
      navigate("/profile");
    }
  }, [subscriptionUpdated]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !email || !lastName) {
      displayAlert();
      return;
    }

    updateUser({ name, email, lastName, language });
    localStorage.setItem("i18nextLng", language);
    i18n.changeLanguage(language);
  };
  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);
  };

  useEffect(() => {
    getItemLocations();
    getItemCategories();
    getItemTypes();
    getItemKitchens();
  }, []);

  const handleCreateCheckout = () => {
    createCheckoutSession(selectedPlan);
  };
  const handlePortalAccess = () => {
    accessStripePortal();
  };

  // const plansTest = [
  //   { name: "Basic", stripePriceId: "price_1P9rvK2Lb6tX2weRvy8dmj8c" },
  //   { name: "Standard", stripePriceId: "price_1P9rzK2Lb6tX2weRe7d4deaL" },
  // ];
  const plans = [
    { name: "Basic", stripePriceId: "price_1PBKkb2Lb6tX2weRgJ0uS74y" },
    { name: "Standard", stripePriceId: "price_1PBKkV2Lb6tX2weR8aBKvWMg" },
  ];

  const getPlanName = (planId) => {
    const plan = plans.find((p) => p.stripePriceId === planId);
    return plan ? t(plan.name) : t("No Plan Selected");
  };

  const languageOptions = [
    { label: t("English"), value: "en" },
    { label: t("Français"), value: "fr" },
  ];

  return (
    <Wrapper>
      <form
        className="form"
        style={{ marginTop: "0.75rem" }}
        onSubmit={handleSubmit}
      >
        <h3>{t("Profile")}</h3>
        <div className="form-center">
          <FormRow
            type="text"
            name="name"
            value={name}
            handleChange={(e) => setName(e.target.value)}
            labelText={t("Name")}
          />
          {/* <FormRow
              labelText="last name"
              type="text"
              name="lastName"
              value={lastName}
              handleChange={(e) => setLastName(e.target.value)}
            /> */}
          <FormRow
            type="email"
            name="email"
            value={email}
            handleChange={(e) => setEmail(e.target.value)}
            labelText={t("Email")}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <FormRowSelect
              name="language"
              labelText={t("Language")}
              value={language}
              handleChange={handleLanguageChange}
              list={languageOptions.map((option) => option.value)}
              options={languageOptions}
            />
            <button
              style={{ marginTop: "1rem", marginLeft: "0.5rem" }}
              className="btn btn-block"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? t("Please Wait...") : t("Save Changes")}
            </button>
          </div>
        </div>
      </form>
      <section style={{ marginTop: "0.75rem" }} className="form">
        <h4>{t("Subscription Details")}</h4>
        <p style={{ margin: "0" }}>
          {t("Plan")}: {getPlanName(planId)}
        </p>
        <p style={{ margin: "0" }}>
          {t("Status")}: {t(subscription)}
        </p>
        {subscription === "active" ? (
          <>
            <button
              style={{ marginTop: "0.5rem" }}
              className="btn btn-green"
              onClick={handlePortalAccess}
            >
              {t("Manage Subscription")}
            </button>
          </>
        ) : (
          <>
            <div>
              <h5 style={{ margin: "0" }}>{t("Select a Plan")}:</h5>
              {plans.map((plan) => (
                <div key={plan.stripePriceId}>
                  <input
                    type="radio"
                    id={plan.stripePriceId}
                    name="plan"
                    value={plan.stripePriceId}
                    checked={selectedPlan === plan.stripePriceId}
                    onChange={() => setSelectedPlan(plan.stripePriceId)}
                    disabled={isLoading}
                  />
                  <label htmlFor={plan.stripePriceId}>{t(plan.name)}</label>
                </div>
              ))}
              <button
                className="btn btn-primary"
                onClick={handleCreateCheckout}
                disabled={!selectedPlan}
              >
                {t("Choose Plan")}
              </button>
            </div>
          </>
        )}
      </section>
      {/* <GoogleLoginButton />
      <NavLink
        to="/"
        className="back-to-menus"
        style={{ textDecoration: "underline" }}
      >
        <ItemInfo icon={<BsArrowLeftShort />} text="Go to Dashboard" />
      </NavLink> */}
      <div className="profile-location">
        <h3>{t("Item Locations")}</h3>
        <LocationContainer />
        <div className="profile-location-add">
          <AddItemLocationContainer />
          <AddItemLocation />
        </div>
      </div>
      <div className="profile-location">
        <h3>{t("Item Categories")}</h3>
        <CategoryContainer />
        <div className="profile-location-add">
          <AddItemCategoryContainer />
          <AddItemCategory />
        </div>
      </div>
      <div className="profile-location">
        <h3>{t("Item Types")}</h3>
        <TypeContainer />
        <div className="profile-location-add">
          <AddItemTypeContainer />
          <AddItemType />
        </div>
      </div>
      <div className="profile-location">
        <h3>{t("Kitchens")}</h3>
        <KitchenContainer />
        <div className="profile-location-add">
          <AddItemKitchenContainer />
          <AddItemKitchen />
        </div>
      </div>
    </Wrapper>
  );
};

export default Profile;
