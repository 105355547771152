import { NavLink } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import ItemInfo from "../../components/ItemInfo";
import { ReportsContainer } from "../../components";

const Sales = () => {
  return (
    <div style={{ marginTop: "1rem" }}>
      <div style={{ maxWidth: "6rem" }}>
        <NavLink to="/" className="back-to-menus">
          <ItemInfo icon={<BsArrowLeftShort />} text="Dashboard" />
        </NavLink>
      </div>
      <ReportsContainer />
    </div>
  );
};

export default Sales;
