import Wrapper from "../../assets/wrappers/AddItemContainer";
import { useAppContext } from "../../context/appContext";
import { useTranslation } from "react-i18next";

const AddItemCategoryContainer = () => {
  const { isAddingCategory, setAddItemCategory } = useAppContext();
  const { t } = useTranslation();
  return (
    <Wrapper>
      <button
        type="button"
        style={{
          maxWidth: "10rem",
          marginLeft: "0.2rem",
        }}
        className={isAddingCategory ? `btn btn-danger` : `btn`}
        onClick={setAddItemCategory}
      >
        {t(isAddingCategory ? "Close" : "Add")}
      </button>
    </Wrapper>
  );
};
export default AddItemCategoryContainer;
