import { FormRow, FormRowSelect } from "..";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/AddItem";
import { FaTimes } from "react-icons/fa";
import { AlternativeContainer, Alternative } from "../";
import SelectedAlternatives from "../itemAlternatives/SelectedAlternatives";
import { useState, useEffect } from "react";
import { evaluate } from "mathjs";

import { MdOutlineCalculate } from "react-icons/md";
import { BsQuestionCircle } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useTranslation } from "react-i18next";

const AddItem = () => {
  const { t } = useTranslation();
  const {
    isLoading,
    isEditing,
    isAdding,
    displayAlert,
    itemName,
    price,
    itemCode,
    provider,
    providerOptions,
    itemSupplier,
    itemAmount,
    itemAmountType,
    itemAmountTypeOptions,
    itemCategory,
    itemCategoryOptions,
    itemLocation,
    itemLocationOptions,
    itemType,
    itemTypeOptions,
    itemKitchen,
    itemKitchenOptions,
    itemNeeded,
    itemLeftToOrder,
    handleChange,
    createItem,
    editItem,
    toggleAlternatives,
    toggleDeleteConfirmation,
    softDeleteItem,
    editItemId,
    showAlternatives,
    alternativePairs,
    providers,
    setAddItem,
    clearValues,
    itemProportions,
    itemProportionsType,
    itemProportionsAmount,
    itemProportionsAmountType,
    itemNeededType,
    itemLeftToOrderType,
    selectedAlternatives,
    addCustomProportions,
    itemCustomProportions,
    handleChangeCustomProportion,
    removeCustomProportion,
    toggleSubItemDetails,
    isShowingProportions,
  } = useAppContext();
  const [priceError, setPriceError] = useState("");
  const [itemAmountError, setItemAmountError] = useState("");
  const [itemNeededError, setItemNeededError] = useState("");
  const [itemLeftToOrderError, setItemLeftToOrderError] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [missingFields, setMissingFields] = useState([]);

  useEffect(() => {
    setItemNeededError("");
    setItemLeftToOrderError("");
    setPriceError("");
    setItemAmountError("");
  }, [isAdding, isEditing]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!itemName) {
      displayAlert();
      return;
    }
    const missing = [];
    if (itemType === "Default") missing.push("Item Type");
    if (itemLocation === "Default") missing.push("Item Location");
    if (itemCategory === "Default") missing.push("Item Category");

    if (missing.length > 0 && !showWarning) {
      setMissingFields(missing);
      setShowWarning(true);
      return;
    }

    setShowWarning(false);
    setMissingFields([]);

    if (isEditing) {
      editItem();
    } else {
      createItem();
    }
  };

  const handleDelete = () => {
    toggleDeleteConfirmation();
    setShowWarning(false);
    setMissingFields([]);
  };

  const handleItemInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({ name, value });

    if (showWarning) {
      let updatedMissingFields = [...missingFields];

      if (name === "itemType") {
        if (value !== "Default") {
          updatedMissingFields = updatedMissingFields.filter(
            (field) => field !== "Item Type"
          );
        } else if (!updatedMissingFields.includes("Item Type")) {
          updatedMissingFields.push("Item Type");
        }
      }
      if (name === "itemLocation") {
        if (value !== "Default") {
          updatedMissingFields = updatedMissingFields.filter(
            (field) => field !== "Item Location"
          );
        } else if (!updatedMissingFields.includes("Item Location")) {
          updatedMissingFields.push("Item Location");
        }
      }
      if (name === "itemCategory") {
        if (value !== "Default") {
          updatedMissingFields = updatedMissingFields.filter(
            (field) => field !== "Item Category"
          );
        } else if (!updatedMissingFields.includes("Item Category")) {
          updatedMissingFields.push("Item Category");
        }
      }

      if (updatedMissingFields.length === 0) {
        setShowWarning(false);
      }
      setMissingFields(updatedMissingFields);
    }
  };

  const isCurrentItemAnAlternative = alternativePairs.some(
    (pair) => pair.alternative._id === editItemId
  );

  const currentItemAsAlternative = alternativePairs.find(
    (pair) => pair.alternative._id === editItemId
  );
  const mainItemOfCurrent = currentItemAsAlternative
    ? currentItemAsAlternative.mainItem
    : null;

  const currentItemAsMain = alternativePairs.filter(
    (pair) => pair.mainItem._id === editItemId
  );
  const alternativesOfCurrent =
    currentItemAsMain.length > 0
      ? currentItemAsMain.map((pair) => pair.alternative)
      : [];

  const handleToggleAlternatives = () => {
    toggleAlternatives(editItemId);
  };

  const handleAddCustomProportion = () => {
    addCustomProportions();
  };
  const handleCustomProportionInput = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChangeCustomProportion(index, name, value);
  };

  const handleCalculationOnBlur = (e) => {
    const { name, value } = e.target;
    const isValidExpression = /^[0-9+\-*/().\s=]*$/;
    const isPotentialExpression =
      value.startsWith("=") && isValidExpression.test(value.substring(1));
    const isValidNumber = !isNaN(value) && value.trim() !== "";

    const setError = (fieldName, message) => {
      switch (fieldName) {
        case "itemNeeded":
          setItemNeededError(message);
          break;
        case "itemLeftToOrder":
          setItemLeftToOrderError(message);
          break;
        case "price":
          setPriceError(message);
          break;
        case "itemAmount":
          setItemAmountError(message);
          break;
        default:
          break;
      }
    };

    if (
      (name === "itemNeeded" || name === "itemLeftToOrder") &&
      value.trim() === ""
    ) {
      setError(name, "");
      return;
    }

    if (isValidNumber || isPotentialExpression) {
      if (isPotentialExpression) {
        try {
          const result = evaluate(value.substring(1));
          handleChange({ name, value: result.toString() });
          setError(name, "");
        } catch (error) {
          console.error("Error evaluating expression:", error);
          setError(name, t("validCalculationPrompt"));
        }
      } else {
        setError(name, "");
      }
    } else {
      setError(name, t("validNumberOrCalculationPrompt"));
    }
  };

  const unitConversions = {
    ml: {
      toBaseUnit: 1,
      baseUnit: "ml",
      nextLargerUnit: "L",
      scalingFactor: 1000,
    },
    L: {
      toBaseUnit: 1000,
      baseUnit: "ml",
      nextSmallerUnit: "ml",
      scalingFactor: 0.001,
    },
    g: {
      toBaseUnit: 1,
      baseUnit: "g",
      nextLargerUnit: "Kg",
      scalingFactor: 1000,
    },
    Kg: {
      toBaseUnit: 1000,
      baseUnit: "g",
      nextSmallerUnit: "g",
      scalingFactor: 0.001,
    },
    Oz: {
      toBaseUnit: 1,
      baseUnit: "oz",
      nextLargerUnit: "lb",
      scalingFactor: 16,
    },
    lb: {
      toBaseUnit: 16,
      baseUnit: "oz",
      nextSmallerUnit: "oz",
      scalingFactor: 0.0625,
    },
    unit: {
      toBaseUnit: 1,
      baseUnit: "units",
      scalingFactor: 1,
    },
  };

  function convertUnitsToAppropriateType(amount, unitType) {
    let unitInfo = unitConversions[unitType];

    if (!unitInfo) {
      throw new Error(`Unsupported unit type for conversion: ${unitType}`);
    }

    let finalAmount = amount;
    let toUnit = unitType;

    if (unitType !== "unit") {
      while (finalAmount >= unitInfo.scalingFactor && unitInfo.nextLargerUnit) {
        finalAmount /= unitInfo.scalingFactor;
        toUnit = unitInfo.nextLargerUnit;
        unitInfo = unitConversions[toUnit];
      }

      while (finalAmount < 1 && unitInfo.nextSmallerUnit) {
        finalAmount *= unitInfo.scalingFactor;
        toUnit = unitInfo.nextSmallerUnit;
        unitInfo = unitConversions[toUnit];
      }
    }

    return { finalAmount, finalUnit: toUnit };
  }

  function handleCalculateAndConvert() {
    const baseAmount = itemProportions * itemProportionsAmount;

    try {
      const { finalAmount, finalUnit } = convertUnitsToAppropriateType(
        baseAmount,
        itemProportionsAmountType
      );

      handleChange({ name: "itemAmount", value: finalAmount.toString() });
      handleChange({ name: "itemAmountType", value: finalUnit });
    } catch (error) {
      console.error("Calculation or conversion error:", error);
      setItemAmountError(t("calculationConversionError"));
    }
  }

  function handleCustomCalculateAndConvert(index) {
    const proportion = itemCustomProportions[index];
    if (proportion.itemProportions && proportion.itemProportionsAmount) {
      const baseAmount =
        proportion.itemProportions * proportion.itemProportionsAmount;

      try {
        const { finalAmount, finalUnit } = convertUnitsToAppropriateType(
          baseAmount,
          proportion.itemProportionsAmountType
        );

        handleCustomProportionInput(
          { target: { name: "itemAmount", value: finalAmount.toString() } },
          index,
          "itemAmount"
        );
        handleCustomProportionInput(
          { target: { name: "itemAmountType", value: finalUnit } },
          index,
          "itemAmountType"
        );
      } catch (error) {
        console.error("Calculation or conversion error:", error);
      }
    }
  }

  return (
    <Wrapper>
      {(isEditing || isAdding) && <div className="edit-backdrop"></div>}
      <form
        className={`${
          isEditing || isAdding ? "form_active form_fixed" : "form"
        }`}
      >
        <div className="addItem-group">
          <h3>{isEditing ? t("edit item") : t("add item")} </h3>
          <button
            className="btn btn-danger"
            type="button"
            style={{
              width: "2rem",
              height: "2rem",
              padding: "0.5rem 0.5rem",
              alignSelf: "flex-start",
            }}
            onClick={(e) => {
              e.preventDefault();
              clearValues();
              setShowWarning(false);
              setMissingFields([]);
            }}
          >
            <FaTimes />
          </button>
        </div>

        <div className="form-center">
          <FormRow
            type="text"
            name="itemName"
            labelText={t("Item Name")}
            value={itemName}
            handleChange={handleItemInput}
            tooltipContent={t("The name of the item. Ex: Orange Juice")}
          />
          <div className="addItem-group">
            <FormRowSelect
              name="provider"
              value={provider}
              handleChange={handleItemInput}
              list={providerOptions}
              labelText={t("Provider")}
            />
            <FormRow
              type="text"
              name="itemSupplier"
              labelText={t("Item Supplier")}
              value={itemSupplier}
              handleChange={handleItemInput}
              tooltipContent={t(
                "The company that produces the item. Ex: Heinz or Redpath"
              )}
              style={{ marginLeft: "10px" }}
            />
          </div>
          <div className="addItem-group">
            <div>
              <FormRow
                type="text"
                name="price"
                labelText={t("Price")}
                value={price}
                handleChange={handleItemInput}
                handleBlur={handleCalculationOnBlur}
                style={{ flex: 1, marginRight: "10px" }}
              />
              {priceError && <div style={{ color: "red" }}>{priceError}</div>}
            </div>
            <FormRowSelect
              name="itemType"
              value={itemType}
              handleChange={handleItemInput}
              list={itemTypeOptions}
              labelText={t("Item Type")}
              tooltipContent={t(
                "The container in which the item comes in. Ex: Box or Case or Package"
              )}
            />
          </div>
          {!isShowingProportions && (
            <div style={{ display: "flex", alignSelf: "flex-end" }}>
              <button
                type="button"
                className="btn edit-btn"
                onClick={toggleSubItemDetails}
                style={{ flexGrow: 1 }}
              >
                {itemProportions
                  ? t("Edit SubItem Details")
                  : t("Add SubItem Details")}
              </button>
              <Tippy content={t("subItem-info")}>
                <div style={{ height: "1rem" }}>
                  <BsQuestionCircle className="info-icon" />
                </div>
              </Tippy>
            </div>
          )}
          {isShowingProportions && (
            <div className="sub-item-details">
              <div className="addItem-group">
                <FormRow
                  type="number"
                  name="itemProportions"
                  labelText={t("SubItem Quantity")}
                  value={itemProportions}
                  handleChange={handleItemInput}
                  tooltipContent={t(
                    "How many subItems are there in the container. Ex: a case of 12x500ml bottles has 12. If the Item does not have any sub-item, leave this entry empty"
                  )}
                  style={{ marginRight: "10px", width: "12rem" }}
                />
                <FormRowSelect
                  name="itemProportionsType"
                  labelText={t("Sub-item Type")}
                  value={itemProportionsType}
                  handleChange={handleItemInput}
                  list={itemTypeOptions}
                  tooltipContent={t(
                    "What type is the subItem. Ex: a case of 12x500ml is bottles or packages"
                  )}
                />
              </div>
              <div className="addItem-group">
                <FormRow
                  type="number"
                  name="itemProportionsAmount"
                  labelText={t("Sub-item Amount")}
                  value={itemProportionsAmount}
                  handleChange={handleItemInput}
                  tooltipContent={t(
                    "Individual subItem amount. Ex: 12x500ml, amount is 500ml"
                  )}
                />
                <FormRowSelect
                  name="itemProportionsAmountType"
                  labelText={t("Sub-item Amount Unit")}
                  value={itemProportionsAmountType}
                  handleChange={handleItemInput}
                  list={itemAmountTypeOptions}
                />
              </div>
            </div>
          )}
          <div className="addItem-group">
            <div>
              <div style={{ display: "flex" }}>
                <FormRow
                  type="text"
                  name="itemAmount"
                  labelText={t("Total Amount")}
                  value={itemAmount}
                  handleChange={handleItemInput}
                  handleBlur={handleCalculationOnBlur}
                  tooltipContent={t(
                    "The total quantity amount an item contains. Ex: a case of 20x500ml would contain 10L. This Amount is used for calculating prices for menu Items. If you input the subItem Quantity, subItemAmount and AmountUnit, you can calculate the TotalAmount automatically"
                  )}
                  style={{ marginRight: "10px", width: "12rem" }}
                />
                {itemProportions && itemProportionsAmount && (
                  <button
                    type="button"
                    className="calc-button"
                    onClick={handleCalculateAndConvert}
                  >
                    <MdOutlineCalculate
                      style={{ width: "1.75rem", height: "1.75rem" }}
                    />
                  </button>
                )}
              </div>
              {itemAmountError && (
                <div style={{ color: "red" }}>{itemAmountError}</div>
              )}
            </div>
            <FormRowSelect
              name="itemAmountType"
              labelText={t("Total Amount Unit")}
              value={itemAmountType}
              handleChange={handleItemInput}
              list={itemAmountTypeOptions}
            />
          </div>
          <div className="addItem-group">
            <div>
              <FormRow
                type="text"
                name="itemNeeded"
                value={itemNeeded}
                labelText={t("Item Needed")}
                handleChange={handleItemInput}
                handleBlur={handleCalculationOnBlur}
                tooltipContent={t(
                  "How much of the item you need weekly or daily, this number is used with the itemNeededType and is used for automatic ordering based on inventory levels"
                )}
                style={{ marginRight: "10px" }}
              />
              {itemNeededError && (
                <div style={{ color: "red" }}>{itemNeededError}</div>
              )}
            </div>
            <FormRowSelect
              name="itemNeededType"
              value={itemNeededType}
              labelText={t("Item Needed Type")}
              handleChange={handleItemInput}
              list={itemTypeOptions}
              tooltipContent={t(
                "The itemType of itemNeeded, this type has to be the same type as either the itemType or the subItemType for automatic ordering"
              )}
            />
          </div>
          <div className="addItem-group">
            <div>
              <FormRow
                type="text"
                name="itemLeftToOrder"
                labelText={t("Item Left To Order")}
                value={itemLeftToOrder}
                handleChange={handleItemInput}
                handleBlur={handleCalculationOnBlur}
                tooltipContent={t(
                  "The minimum quantity of an item needed before Reordering. Ex: if you set the itemNeeded to 2 but itemLeftToOrder to 0.5, it will only order if the quantity is below 0.5. This Number is not necessary but is used conjointly with itemLeftToOrderType for automatic ordering"
                )}
                style={{ marginRight: "10px" }}
              />
              {itemLeftToOrderError && (
                <div style={{ color: "red" }}>{itemLeftToOrderError}</div>
              )}
            </div>
            <FormRowSelect
              name="itemLeftToOrderType"
              value={itemLeftToOrderType}
              labelText={t("Item Left To Order Type")}
              handleChange={handleItemInput}
              list={itemTypeOptions}
              tooltipContent={t(
                "The itemType of itemLeftToOrder, this type has to be the same type as either the itemType or the subItemType for automatic ordering"
              )}
            />
          </div>
          <FormRow
            type="text"
            name="itemCode"
            labelText={t("Item Code")}
            value={itemCode}
            handleChange={handleItemInput}
            tooltipContent={t(
              "The digital code of the item. This can help finding items on the provider's online service. Ex: IDM025CS or 1875329"
            )}
          />
          <div className="addItem-group">
            <div style={{ width: "100%" }}>
              <FormRowSelect
                name="itemLocation"
                labelText={t("Location")}
                value={itemLocation}
                handleChange={handleItemInput}
                list={itemLocationOptions}
                tooltipContent={t(
                  "The physical location of the item in the Kitchen Ex: Fridge, Spice Shelf, Freezer. These values should be set based on your restaurant setup and help you filter and search through specific items"
                )}
                style={{ marginRight: "10px" }}
              />
            </div>
            <div style={{ width: "100%" }}>
              <FormRowSelect
                name="itemCategory"
                labelText={t("Category")}
                value={itemCategory}
                handleChange={handleItemInput}
                list={itemCategoryOptions}
                tooltipContent={t(
                  "What the item categorizes as, Ex: Meat, Vegetables, Bar, Cleaning products. These values should be set based on your restaurant setup and help you filter and search through specific items"
                )}
              />
            </div>
          </div>
          <div className="addItem-group">
            <div style={{ width: "100%" }}>
              <FormRowSelect
                name="itemKitchen"
                value={itemKitchen}
                labelText={t("Kitchen")}
                handleChange={handleItemInput}
                list={itemKitchenOptions}
                tooltipContent={t(
                  "Separate your items through different kitchens if you have more than 1. This can be useful when ordering for more than 1 kitchen"
                )}
              />
            </div>
          </div>
        </div>
        <div>
          {itemCustomProportions.map((proportion, index) => (
            <div key={index} className="custom-proportions-container">
              <div className="addItem-group-custom">
                <FormRow
                  style={{ width: "6rem" }}
                  type="number"
                  name="price"
                  labelText={t("Price")}
                  value={proportion.price}
                  handleChange={(e) =>
                    handleCustomProportionInput(e, index, "price")
                  }
                />
                <FormRowSelect
                  name="itemType"
                  labelText={t("Item Type")}
                  value={proportion.itemType}
                  handleChange={(e) =>
                    handleCustomProportionInput(e, index, "itemType")
                  }
                  list={itemTypeOptions}
                />
              </div>
              <div className="addItem-group-custom">
                <FormRow
                  style={{ width: "8rem" }}
                  type="number"
                  name="itemProportions"
                  labelText={t("SubItem Quantity")}
                  value={proportion.itemProportions}
                  handleChange={(e) =>
                    handleCustomProportionInput(e, index, "itemProportions")
                  }
                  tooltipContent={t(
                    "How many subItems are there in the container. Ex: a case of 12x500ml bottles has 12. If the Item does not have any sub-item, leave this entry empty"
                  )}
                />
                <FormRowSelect
                  name="itemProportionsType"
                  labelText={t("Item Type")}
                  value={proportion.itemProportionsType}
                  handleChange={(e) =>
                    handleCustomProportionInput(e, index, "itemProportionsType")
                  }
                  list={itemTypeOptions}
                  tooltipContent={t(
                    "What type is the subItem. Ex: a case of 12x500ml is bottles or packages"
                  )}
                />
              </div>
              <div className="addItem-group-custom">
                <FormRow
                  style={{ width: "8rem" }}
                  type="number"
                  name="itemProportionsAmount"
                  labelText={t("Sub-item Amount")}
                  value={proportion.itemProportionsAmount}
                  handleChange={(e) =>
                    handleCustomProportionInput(
                      e,
                      index,
                      "itemProportionsAmount"
                    )
                  }
                  tooltipContent={t(
                    "Individual subItem amount. Ex: 12x500ml, amount is 500ml"
                  )}
                />
                <FormRowSelect
                  name="itemProportionsAmountType"
                  labelText={t("Amount Unit")}
                  value={proportion.itemProportionsAmountType}
                  handleChange={(e) =>
                    handleCustomProportionInput(
                      e,
                      index,
                      "itemProportionsAmountType"
                    )
                  }
                  list={itemAmountTypeOptions}
                />
              </div>

              <div className="addItem-group-custom" style={{ width: "14rem" }}>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <FormRow
                    style={{ width: "5rem" }}
                    type="number"
                    name="itemAmount"
                    labelText={t("Total Amount")}
                    value={proportion.itemAmount}
                    handleChange={(e) =>
                      handleCustomProportionInput(e, index, "itemAmount")
                    }
                  />
                  {proportion.itemProportions &&
                    proportion.itemProportionsAmount &&
                    proportion.itemProportionsAmountType !== "Other" && (
                      <button
                        type="button"
                        className="calc-button"
                        onClick={() => handleCustomCalculateAndConvert(index)}
                      >
                        <MdOutlineCalculate
                          style={{
                            width: "1.75rem",
                            height: "1.75rem",
                            marginLeft: "0.5rem",
                            paddingBottom: "0.25rem",
                          }}
                        />
                      </button>
                    )}
                </div>
                <FormRowSelect
                  name="itemAmountType"
                  labelText={t("Amount Type")}
                  value={proportion.itemAmountType}
                  handleChange={(e) =>
                    handleCustomProportionInput(e, index, "itemAmountType")
                  }
                  list={itemAmountTypeOptions}
                />
              </div>
              <button
                className="btn btn-danger remove-proportion"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  removeCustomProportion(index);
                }}
              >
                <FaTimes />
              </button>
            </div>
          ))}

          {itemCustomProportions.length < 3 && (
            <div style={{ display: "flex" }}>
              <button
                type="button"
                className="btn edit-btn"
                style={{ marginTop: "1rem", height: "auto" }}
                onClick={handleAddCustomProportion}
              >
                {t("Add Custom Proportion")}
              </button>
              <Tippy
                content={t(
                  "If you want to order your item in different proportions and have specific prices for each proportion Ex: Main Proportion is a case of 12 bottles for 10$, but you want to be able to order a single bottle for 2$. You can rotate through your different proportions in the UI and it will update the prices for ordering as well"
                )}
              >
                <div>
                  <BsQuestionCircle
                    style={{ marginTop: "1.25rem", marginLeft: "0.5rem" }}
                    className="info-icon"
                  />
                </div>
              </Tippy>
            </div>
          )}
        </div>
        <div className="edit-item-alternatives">
          {mainItemOfCurrent && (
            <div>
              <p>{t("This item is an alternative to:")}</p>
              <Alternative item={mainItemOfCurrent} />
            </div>
          )}
          {selectedAlternatives?.length > 0 && (
            <div>
              <p>{t("This item has the following alternatives:")}</p>
              {selectedAlternatives.map((alt) => (
                <Alternative key={alt._id} item={alt} />
              ))}
            </div>
          )}
        </div>

        {!isLoading && !isCurrentItemAnAlternative && <AlternativeContainer />}
        {showWarning && (
          <div style={{ color: "red", marginBottom: "10px" }}>
            {t("Please select a Specific value for:") +
              " " +
              missingFields.join(", ")}
          </div>
        )}
        <div className="btn-container">
          {isEditing && (
            <>
              <button
                type="button"
                className="btn btn-block submit-btn"
                onClick={handleToggleAlternatives}
                disabled={isLoading || isCurrentItemAnAlternative}
              >
                {t("Alternatives")}
              </button>
              <button
                type="button"
                className="btn delete-btn"
                onClick={handleDelete}
                // onClick={() => softDeleteItem("item", editItemId)}
              >
                {t("Delete")}
              </button>
            </>
          )}
          <button
            type="submit"
            className="btn btn-block submit-btn"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {showWarning ? t("Submit Anyways") : t("Submit")}
          </button>
        </div>

        <div className="btn-container">
          {/* <button
                className="btn btn-block clear-btn"
                onClick={(e) => {
                  e.preventDefault();
                  clearValues();
                }}
              >
                clear
              </button> */}
        </div>
      </form>
    </Wrapper>
  );
};

export default AddItem;
