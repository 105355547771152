import Wrapper from "../assets/wrappers/BigSidebar";
import { useAppContext } from "../context/appContext";
import Logo from "./Logo";
import NavLinks from "./NavLinks";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useTranslation } from "react-i18next";

const BigSidebar = () => {
  const { showSidebar, toggleSidebar, subscription } = useAppContext();
  const { t } = useTranslation();

  const subscriptionStatus = subscription === "active";
  const statusColor = subscriptionStatus ? "green" : "red";
  const statusText = t(
    subscriptionStatus ? "Subscription Active" : "Subscription Inactive"
  );
  return (
    <Wrapper>
      <div
        className={
          showSidebar ? "sidebar-container show-sidebar" : "sidebar-container"
        }
      >
        <div className="content-sidebar">
          <header>
            <Logo />
          </header>
          <NavLinks toggleSidebar={toggleSidebar} />
          <div className="subscription-status" style={{ color: statusColor }}>
            <IoMdCheckmarkCircleOutline style={{ verticalAlign: "middle" }} />{" "}
            {statusText}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default BigSidebar;
