import Wrapper from "../assets/wrappers/SmallSidebar";
import { FaTimes } from "react-icons/fa";
import { useAppContext } from "../context/appContext";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

import NavLinks from "./NavLinks";
import { useTranslation } from "react-i18next";

const SmallSidebar = () => {
  const { showSidebar, toggleSidebar, subscription } = useAppContext();
  const { t } = useTranslation();

  const subscriptionStatus = subscription === "active";
  const statusColor = subscriptionStatus ? "green" : "red";
  const statusText = t(
    subscriptionStatus ? "Subscription Active" : "Subscription Inactive"
  );
  return (
    <Wrapper>
      <div
        className={
          showSidebar ? "sidebar-container show-sidebar" : "sidebar-container"
        }
      >
        <div className="content">
          <button type="button" className="close-btn" onClick={toggleSidebar}>
            <FaTimes />
          </button>
          <NavLinks toggleSidebar={toggleSidebar} />
          <div className="subscription-status" style={{ color: statusColor }}>
            <IoMdCheckmarkCircleOutline style={{ verticalAlign: "middle" }} />{" "}
            {statusText}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SmallSidebar;
