import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/ItemsContainer";
import ItemCategory from "./ItemCategory";

const CategoryContainer = () => {
  const { itemCategories } = useAppContext();

  return (
    <Wrapper>
      <div className="locations">
        {itemCategories.map((itemCategory) => {
          return (
            <ItemCategory key={itemCategory._id} itemCategory={itemCategory} />
          );
        })}
      </div>
    </Wrapper>
  );
};

export default CategoryContainer;
