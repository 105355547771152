import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Landing, Error, Register, ProtectedRoute } from "./pages";
import {
  Order,
  Provider,
  //Inventory,
  Menu,
  Dashboard,
  SharedLayout,
  Profile,
  MenuItems,
  Trash,
  Orders,
  Sales,
  MissingInfo,
  Support,
} from "./pages/dashboard";
import { OAuthCallback } from "./components";
import "./i18n";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <SharedLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="orders" element={<Orders />} />
          <Route path="missing-info" element={<MissingInfo />} />
          <Route path="sales" element={<Sales />} />
          {/* <Route path="inventory" element={<Inventory />} /> */}
          <Route path="stock" element={<Order />} />
          <Route path="provider" element={<Provider />} />
          <Route path="menu" element={<Menu />} />
          <Route path="menu/:id" element={<MenuItems />} />
          <Route path="trash" element={<Trash />} />
          <Route path="profile" element={<Profile />} />
          <Route path="support" element={<Support />} />
          <Route path="/oauth-callback" element={<OAuthCallback />} />
        </Route>
        <Route path="/login" element={<Register />}></Route>
        <Route path="/landing" element={<Landing />}></Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

//git status

//git add .
//git commit -m "second commit or whatever"
//git push -u origin main
//might be able to use "git push" ONLY

//pushing p from p

export default App;
