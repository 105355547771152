import styled from "styled-components";

const Wrapper = styled.section`
  margin-top: 0;

  .form {
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
  }
  .form-input,
  .form-select,
  .btn-block {
    height: 35px;
  }
  .btn-filters {
    min-height: 3rem;
    @media (min-width: 416px) {
      min-height: 1rem;
    }
  }
  .form-row {
    margin-bottom: 0;
    font-size: 0.9rem;
    @media (min-width: 396px) {
      font-size: 1rem;
    }
  }
  .form-center {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 0.5rem;
  }

  .form-search {
    margin-top: auto;
  }
  h5 {
    font-weight: 700;
  }
  .btn-block {
    margin-top: auto;
  }
  @media (min-width: 768px) {
    .form-center {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media (min-width: 992px) {
    .form-center {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }
`;

export default Wrapper;
