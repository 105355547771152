import styled from "styled-components";

const Wrapper = styled.section`
  margin-top: 1rem;

  .missing-item-container {
    display: flex;
    flex-direction: column;

    padding: 0.5rem;
    background-color: #f9f9f9;
    margin-bottom: 0.5rem;

    background: var(--white);
    border-radius: 10px;
    display: flex;

    box-shadow: var(--shadow-2);
    margin: 0.5rem 0rem;
  }
  .category-buttons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }

  .category-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 6px;
    border: 1px solid black;
    background-color: var(--white);
    color: #333;
    cursor: pointer;
  }

  .category-button.selected {
    background-color: var(--primary-500);
    color: #fff;
  }

  h5 {
    margin: 0;
    margin-top: 0.5rem;
  }

  .missing-info-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    h4,
    h6,
    p {
      margin: 0;
      margin-right: 0.5rem;
    }

    h4 {
      font-weight: 600;
      font-size: 1.5rem;
    }

    p {
      font-weight: 900;
      color: var(--green-light);
      margin-top: 0.25rem;
    }
    h6 {
      font-size: 1rem;
      color: var(--red-light);
      margin-right: 0.75rem;
    }

    @media (min-width: 576px) {
      flex-wrap: nowrap;
    }
  }
  .item-info {
    display: flex;
    flex-wrap: wrap;
  }
  .missing-edit-container {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
  }
  .edit-btn {
    color: var(--white);
    background: var(--green-light);

    :hover {
      background: var(--green-dark);
    }
  }
  .edit-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0 1rem;
  }

  .save-btn {
    height: 2.25rem;
    margin-bottom: 0.75rem;
    align-self: flex-end;
  }
`;
export default Wrapper;
