import Wrapper from "../../assets/wrappers/AddItemContainer";
import { useAppContext } from "../../context/appContext";
import { AiFillEdit } from "react-icons/ai";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const AddMenuItemContainer = () => {
  const { t } = useTranslation();
  const {
    isEditingMenuItem,
    clearValues,
    isAddingMenuItem,
    setAddMenuItem,
    toggleEditErrorPercentage,
    getUserSettings,
    userSettings,
    errorPercentage,
  } = useAppContext();

  useEffect(() => {
    if (!userSettings || Object.keys(userSettings).length === 0) {
      getUserSettings();
    }
  }, []);

  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          style={{ width: "12rem", marginLeft: "0.25rem" }}
          className={
            isEditingMenuItem || isAddingMenuItem ? `btn btn-danger ` : `btn`
          }
          onClick={
            !isAddingMenuItem ? () => setAddMenuItem() : () => clearValues()
          }
        >
          {isEditingMenuItem || isAddingMenuItem
            ? t("Close")
            : t("Add Menu Item")}
        </button>
        <div className="error-percentage-container">
          <b>
            {t("Error")}:{" "}
            <span style={{ color: "var(--red-light)" }}>
              {userSettings.errorPercentage}%
            </span>
          </b>

          <button
            className="error-edit-btn"
            onClick={toggleEditErrorPercentage}
          >
            <AiFillEdit />
          </button>
        </div>
      </div>
    </Wrapper>
  );
};
export default AddMenuItemContainer;
