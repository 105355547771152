import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const OrderCostsOverTimeChart = ({ data }) => {
  const { t } = useTranslation();
  if (!data || !data.datasets || data.datasets.length === 0) {
    console.error("Data is not properly formatted.");
    return null;
  }
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: t("OrderCostsOverTime"),
      },
    },
    scales: {
      y: {
        beginAtZero: false, // Starting at zero can obscure smaller variations when all values are high
        ticks: {
          autoSkip: false, // Prevent Chart.js from skipping ticks to reduce clutter
          maxTicksLimit: 10, // Limit the maximum number of ticks displayed
          precision: 0, // Remove decimal places from ticks, adjust as necessary
          callback: function (value) {
            return `$${value.toLocaleString()}`; // Format ticks as currency
          },
        },
      },
    },
  };

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "90vw",
        margin: "0 auto",
        height: "20rem",
      }}
    >
      <Line data={data} options={options} />
    </div>
  );
};

export default OrderCostsOverTimeChart;
