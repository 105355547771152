import { useState, useEffect } from "react";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/Item";
import { useTranslation } from "react-i18next";

const DeletedItem = ({ item }) => {
  const { t } = useTranslation();
  const { restoreItem, deleteItem, providers } = useAppContext();
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  const selectedProvider = providers.find(
    (provider) => provider._id === item.provider
  );
  const providerName = selectedProvider ? selectedProvider.providerName : "";
  useEffect(() => {
    let timeout;
    if (isConfirmingDelete) {
      timeout = setTimeout(() => {
        setIsConfirmingDelete(false);
      }, 3000);
    }
    return () => clearTimeout(timeout);
  }, [isConfirmingDelete]);

  const handleDeleteClick = () => {
    if (isConfirmingDelete) {
      deleteItem(item._id);
    } else {
      setIsConfirmingDelete(true);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  return (
    <Wrapper>
      <div className="deleted-item">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            width: "auto",
          }}
        >
          <h4>{item.itemName}</h4>
          <div style={{ display: "flex", marginTop: "0.25rem" }}>
            <h5>{item.itemSupplier}</h5>
            <p>{providerName}</p>
            <h6>
              {t("Deleted")}: {formatDate(item.deletedAt)}
            </h6>
          </div>
        </div>
        <div className="deleted-actions">
          <button
            className="btn edit-btn"
            onClick={() => restoreItem("item", item._id)}
          >
            {t("Restore")}
          </button>
          <button
            style={{ width: "7rem" }}
            className="btn delete-btn"
            onClick={handleDeleteClick}
          >
            {isConfirmingDelete ? t("Confirm Delete") : t("Delete Permanently")}
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default DeletedItem;
